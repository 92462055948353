// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.help-centered {
    display: flex;
    justify-content: center;
}

.overflow {
    overflow: auto;
}

.spread {
    display: flex;
    flex-flow: column;
    height: 100%;
}

.full-height {
    height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/routes/growthMonitoring/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".help-centered {\n    display: flex;\n    justify-content: center;\n}\n\n.overflow {\n    overflow: auto;\n}\n\n.spread {\n    display: flex;\n    flex-flow: column;\n    height: 100%;\n}\n\n.full-height {\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
