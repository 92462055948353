// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    display: flex;
    justify-content: space-between;
    background: #344966;
    align-items: center;
    padding: 1%;
    color: #B4CDED;
}

.avatar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5%;
}

h3 {
    margin: 0;
    padding-top: 5px;
    margin-left: -100px;
}

p {
    margin: 0;
    padding-top: 5px;
}

.list-container {
    margin: 10%;
}

.list {
    padding: 0 !important;
}

.list-item {
    padding: 0 !important;
    margin-top: 1em;
    margin-bottom: 1em;
    color: #B4CDED;
    border-radius: 2em;
}

.list-item-selected {
    padding: 0 !important;
    margin-top: 1em;
    margin-bottom: 1em;
    color: #344966;
    background: #B4CDED;
    border-radius: 2em;
}

label {
    padding-right: 2%;
    font-weight: lighter;
}`, "",{"version":3,"sources":["webpack://./src/components/header/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;IACnB,WAAW;IACX,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,SAAS;IACT,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;IACrB,eAAe;IACf,kBAAkB;IAClB,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,eAAe;IACf,kBAAkB;IAClB,cAAc;IACd,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,oBAAoB;AACxB","sourcesContent":[".header {\n    display: flex;\n    justify-content: space-between;\n    background: #344966;\n    align-items: center;\n    padding: 1%;\n    color: #B4CDED;\n}\n\n.avatar-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 5%;\n}\n\nh3 {\n    margin: 0;\n    padding-top: 5px;\n    margin-left: -100px;\n}\n\np {\n    margin: 0;\n    padding-top: 5px;\n}\n\n.list-container {\n    margin: 10%;\n}\n\n.list {\n    padding: 0 !important;\n}\n\n.list-item {\n    padding: 0 !important;\n    margin-top: 1em;\n    margin-bottom: 1em;\n    color: #B4CDED;\n    border-radius: 2em;\n}\n\n.list-item-selected {\n    padding: 0 !important;\n    margin-top: 1em;\n    margin-bottom: 1em;\n    color: #344966;\n    background: #B4CDED;\n    border-radius: 2em;\n}\n\nlabel {\n    padding-right: 2%;\n    font-weight: lighter;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
