export const lhfa_b_0_to_2_years_zscores = [
    {
        "Month": 0,
        "SD0": 49.9,
        "SD1": 51.8,
        "SD2": 53.7,
        "SD3": 55.6,
        "SD1neg": 48.0,
        "SD2neg": 46.1,
        "SD3neg": 44.2
    },
    {
        "Month": 1,
        "SD0": 54.7,
        "SD1": 56.7,
        "SD2": 58.6,
        "SD3": 60.6,
        "SD1neg": 52.8,
        "SD2neg": 50.8,
        "SD3neg": 48.9
    },
    {
        "Month": 2,
        "SD0": 58.4,
        "SD1": 60.4,
        "SD2": 62.4,
        "SD3": 64.4,
        "SD1neg": 56.4,
        "SD2neg": 54.4,
        "SD3neg": 52.4
    },
    {
        "Month": 3,
        "SD0": 61.4,
        "SD1": 63.5,
        "SD2": 65.5,
        "SD3": 67.6,
        "SD1neg": 59.4,
        "SD2neg": 57.3,
        "SD3neg": 55.3
    },
    {
        "Month": 4,
        "SD0": 63.9,
        "SD1": 66.0,
        "SD2": 68.0,
        "SD3": 70.1,
        "SD1neg": 61.8,
        "SD2neg": 59.7,
        "SD3neg": 57.6
    },
    {
        "Month": 5,
        "SD0": 65.9,
        "SD1": 68.0,
        "SD2": 70.1,
        "SD3": 72.2,
        "SD1neg": 63.8,
        "SD2neg": 61.7,
        "SD3neg": 59.6
    },
    {
        "Month": 6,
        "SD0": 67.6,
        "SD1": 69.8,
        "SD2": 71.9,
        "SD3": 74.0,
        "SD1neg": 65.5,
        "SD2neg": 63.3,
        "SD3neg": 61.2
    },
    {
        "Month": 7,
        "SD0": 69.2,
        "SD1": 71.3,
        "SD2": 73.5,
        "SD3": 75.7,
        "SD1neg": 67.0,
        "SD2neg": 64.8,
        "SD3neg": 62.7
    },
    {
        "Month": 8,
        "SD0": 70.6,
        "SD1": 72.8,
        "SD2": 75.0,
        "SD3": 77.2,
        "SD1neg": 68.4,
        "SD2neg": 66.2,
        "SD3neg": 64.0
    },
    {
        "Month": 9,
        "SD0": 72.0,
        "SD1": 74.2,
        "SD2": 76.5,
        "SD3": 78.7,
        "SD1neg": 69.7,
        "SD2neg": 67.5,
        "SD3neg": 65.2
    },
    {
        "Month": 10,
        "SD0": 73.3,
        "SD1": 75.6,
        "SD2": 77.9,
        "SD3": 80.1,
        "SD1neg": 71.0,
        "SD2neg": 68.7,
        "SD3neg": 66.4
    },
    {
        "Month": 11,
        "SD0": 74.5,
        "SD1": 76.9,
        "SD2": 79.2,
        "SD3": 81.5,
        "SD1neg": 72.2,
        "SD2neg": 69.9,
        "SD3neg": 67.6
    },
    {
        "Month": 12,
        "SD0": 75.7,
        "SD1": 78.1,
        "SD2": 80.5,
        "SD3": 82.9,
        "SD1neg": 73.4,
        "SD2neg": 71.0,
        "SD3neg": 68.6
    },
    {
        "Month": 13,
        "SD0": 76.9,
        "SD1": 79.3,
        "SD2": 81.8,
        "SD3": 84.2,
        "SD1neg": 74.5,
        "SD2neg": 72.1,
        "SD3neg": 69.6
    },
    {
        "Month": 14,
        "SD0": 78.0,
        "SD1": 80.5,
        "SD2": 83.0,
        "SD3": 85.5,
        "SD1neg": 75.6,
        "SD2neg": 73.1,
        "SD3neg": 70.6
    },
    {
        "Month": 15,
        "SD0": 79.1,
        "SD1": 81.7,
        "SD2": 84.2,
        "SD3": 86.7,
        "SD1neg": 76.6,
        "SD2neg": 74.1,
        "SD3neg": 71.6
    },
    {
        "Month": 16,
        "SD0": 80.2,
        "SD1": 82.8,
        "SD2": 85.4,
        "SD3": 88.0,
        "SD1neg": 77.6,
        "SD2neg": 75.0,
        "SD3neg": 72.5
    },
    {
        "Month": 17,
        "SD0": 81.2,
        "SD1": 83.9,
        "SD2": 86.5,
        "SD3": 89.2,
        "SD1neg": 78.6,
        "SD2neg": 76.0,
        "SD3neg": 73.3
    },
    {
        "Month": 18,
        "SD0": 82.3,
        "SD1": 85.0,
        "SD2": 87.7,
        "SD3": 90.4,
        "SD1neg": 79.6,
        "SD2neg": 76.9,
        "SD3neg": 74.2
    },
    {
        "Month": 19,
        "SD0": 83.2,
        "SD1": 86.0,
        "SD2": 88.8,
        "SD3": 91.5,
        "SD1neg": 80.5,
        "SD2neg": 77.7,
        "SD3neg": 75.0
    },
    {
        "Month": 20,
        "SD0": 84.2,
        "SD1": 87.0,
        "SD2": 89.8,
        "SD3": 92.6,
        "SD1neg": 81.4,
        "SD2neg": 78.6,
        "SD3neg": 75.8
    },
    {
        "Month": 21,
        "SD0": 85.1,
        "SD1": 88.0,
        "SD2": 90.9,
        "SD3": 93.8,
        "SD1neg": 82.3,
        "SD2neg": 79.4,
        "SD3neg": 76.5
    },
    {
        "Month": 22,
        "SD0": 86.0,
        "SD1": 89.0,
        "SD2": 91.9,
        "SD3": 94.9,
        "SD1neg": 83.1,
        "SD2neg": 80.2,
        "SD3neg": 77.2
    },
    {
        "Month": 23,
        "SD0": 86.9,
        "SD1": 89.9,
        "SD2": 92.9,
        "SD3": 95.9,
        "SD1neg": 83.9,
        "SD2neg": 81.0,
        "SD3neg": 78.0
    },
    {
        "Month": 24,
        "SD0": 87.8,
        "SD1": 90.9,
        "SD2": 93.9,
        "SD3": 97.0,
        "SD1neg": 84.8,
        "SD2neg": 81.7,
        "SD3neg": 78.7
    }
]