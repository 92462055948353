// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-container {
    height: 50%;
    overflow: hidden;
}

.dashboard-wrapper {
    height: 50%;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.detail-row {
    display: flex;
    justify-content: space-between;
}

.detail {
    display: flex;
    width: -webkit-fill-available;
    align-items: center;
}

.detail h4 {
    padding-right: 10px;
}

.content-section {
    flex-grow: 100;
    overflow: auto;
}

.table-header {
    background: #7A918D;
    color: white;
    font-weight: bold;
    border: none;
}

.table-row {
    background: #F2F2F2;
}

.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
    font-weight: bold !important;
}

.MuiDataGrid-cellContent {
    font-size: 0.8em !important;
    font-weight: bold !important;
}
.diagnosis-item {
    padding: 10px;
}

.phc-table-header {
    background-color: #344966;
}

.phc-table-header-cell {
    color: white !important;
    font-weight: bold !important;
    font-size: 120% !important;
}

.diagnosis-row {
    padding-left: 1% !important;
}`, "",{"version":3,"sources":["webpack://./src/routes/publicHealthRegistry/styles.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,cAAc;AAClB;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,2BAA2B;IAC3B,4BAA4B;AAChC;AACA;IACI,aAAa;AACjB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,uBAAuB;IACvB,4BAA4B;IAC5B,0BAA0B;AAC9B;;AAEA;IACI,2BAA2B;AAC/B","sourcesContent":[".dashboard-container {\n    height: 50%;\n    overflow: hidden;\n}\n\n.dashboard-wrapper {\n    height: 50%;\n    display: flex;\n    flex-direction: column;\n    overflow: auto;\n}\n\n.detail-row {\n    display: flex;\n    justify-content: space-between;\n}\n\n.detail {\n    display: flex;\n    width: -webkit-fill-available;\n    align-items: center;\n}\n\n.detail h4 {\n    padding-right: 10px;\n}\n\n.content-section {\n    flex-grow: 100;\n    overflow: auto;\n}\n\n.table-header {\n    background: #7A918D;\n    color: white;\n    font-weight: bold;\n    border: none;\n}\n\n.table-row {\n    background: #F2F2F2;\n}\n\n.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {\n    font-weight: bold !important;\n}\n\n.MuiDataGrid-cellContent {\n    font-size: 0.8em !important;\n    font-weight: bold !important;\n}\n.diagnosis-item {\n    padding: 10px;\n}\n\n.phc-table-header {\n    background-color: #344966;\n}\n\n.phc-table-header-cell {\n    color: white !important;\n    font-weight: bold !important;\n    font-size: 120% !important;\n}\n\n.diagnosis-row {\n    padding-left: 1% !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
