export const hcfa_b_0_to_5_years_zscores = [
    {
        "Month": 0,
        "SD0": 33.9,
        "SD1": 35.1,
        "SD2": 36.2,
        "SD3": 37.4,
        "SD1neg": 32.7,
        "SD2neg": 31.5,
        "SD3neg": 30.3
    },
    {
        "Month": 1,
        "SD0": 36.5,
        "SD1": 37.7,
        "SD2": 38.9,
        "SD3": 40.1,
        "SD1neg": 35.4,
        "SD2neg": 34.2,
        "SD3neg": 33.0
    },
    {
        "Month": 2,
        "SD0": 38.3,
        "SD1": 39.5,
        "SD2": 40.7,
        "SD3": 41.9,
        "SD1neg": 37.0,
        "SD2neg": 35.8,
        "SD3neg": 34.6
    },
    {
        "Month": 3,
        "SD0": 39.5,
        "SD1": 40.8,
        "SD2": 42.0,
        "SD3": 43.3,
        "SD1neg": 38.3,
        "SD2neg": 37.1,
        "SD3neg": 35.8
    },
    {
        "Month": 4,
        "SD0": 40.6,
        "SD1": 41.8,
        "SD2": 43.1,
        "SD3": 44.4,
        "SD1neg": 39.3,
        "SD2neg": 38.1,
        "SD3neg": 36.8
    },
    {
        "Month": 5,
        "SD0": 41.5,
        "SD1": 42.7,
        "SD2": 44.0,
        "SD3": 45.3,
        "SD1neg": 40.2,
        "SD2neg": 38.9,
        "SD3neg": 37.6
    },
    {
        "Month": 6,
        "SD0": 42.2,
        "SD1": 43.5,
        "SD2": 44.8,
        "SD3": 46.1,
        "SD1neg": 40.9,
        "SD2neg": 39.6,
        "SD3neg": 38.3
    },
    {
        "Month": 7,
        "SD0": 42.8,
        "SD1": 44.1,
        "SD2": 45.5,
        "SD3": 46.8,
        "SD1neg": 41.5,
        "SD2neg": 40.2,
        "SD3neg": 38.9
    },
    {
        "Month": 8,
        "SD0": 43.4,
        "SD1": 44.7,
        "SD2": 46.0,
        "SD3": 47.4,
        "SD1neg": 42.0,
        "SD2neg": 40.7,
        "SD3neg": 39.4
    },
    {
        "Month": 9,
        "SD0": 43.8,
        "SD1": 45.2,
        "SD2": 46.5,
        "SD3": 47.8,
        "SD1neg": 42.5,
        "SD2neg": 41.2,
        "SD3neg": 39.8
    },
    {
        "Month": 10,
        "SD0": 44.2,
        "SD1": 45.6,
        "SD2": 46.9,
        "SD3": 48.3,
        "SD1neg": 42.9,
        "SD2neg": 41.5,
        "SD3neg": 40.2
    },
    {
        "Month": 11,
        "SD0": 44.6,
        "SD1": 45.9,
        "SD2": 47.3,
        "SD3": 48.6,
        "SD1neg": 43.2,
        "SD2neg": 41.9,
        "SD3neg": 40.5
    },
    {
        "Month": 12,
        "SD0": 44.9,
        "SD1": 46.3,
        "SD2": 47.6,
        "SD3": 49.0,
        "SD1neg": 43.5,
        "SD2neg": 42.2,
        "SD3neg": 40.8
    },
    {
        "Month": 13,
        "SD0": 45.2,
        "SD1": 46.5,
        "SD2": 47.9,
        "SD3": 49.3,
        "SD1neg": 43.8,
        "SD2neg": 42.4,
        "SD3neg": 41.1
    },
    {
        "Month": 14,
        "SD0": 45.4,
        "SD1": 46.8,
        "SD2": 48.2,
        "SD3": 49.5,
        "SD1neg": 44.1,
        "SD2neg": 42.7,
        "SD3neg": 41.3
    },
    {
        "Month": 15,
        "SD0": 45.7,
        "SD1": 47.0,
        "SD2": 48.4,
        "SD3": 49.8,
        "SD1neg": 44.3,
        "SD2neg": 42.9,
        "SD3neg": 41.5
    },
    {
        "Month": 16,
        "SD0": 45.9,
        "SD1": 47.2,
        "SD2": 48.6,
        "SD3": 50.0,
        "SD1neg": 44.5,
        "SD2neg": 43.1,
        "SD3neg": 41.7
    },
    {
        "Month": 17,
        "SD0": 46.1,
        "SD1": 47.4,
        "SD2": 48.8,
        "SD3": 50.2,
        "SD1neg": 44.7,
        "SD2neg": 43.3,
        "SD3neg": 41.9
    },
    {
        "Month": 18,
        "SD0": 46.2,
        "SD1": 47.6,
        "SD2": 49.0,
        "SD3": 50.4,
        "SD1neg": 44.9,
        "SD2neg": 43.5,
        "SD3neg": 42.1
    },
    {
        "Month": 19,
        "SD0": 46.4,
        "SD1": 47.8,
        "SD2": 49.2,
        "SD3": 50.6,
        "SD1neg": 45.0,
        "SD2neg": 43.6,
        "SD3neg": 42.3
    },
    {
        "Month": 20,
        "SD0": 46.6,
        "SD1": 48.0,
        "SD2": 49.4,
        "SD3": 50.7,
        "SD1neg": 45.2,
        "SD2neg": 43.8,
        "SD3neg": 42.4
    },
    {
        "Month": 21,
        "SD0": 46.7,
        "SD1": 48.1,
        "SD2": 49.5,
        "SD3": 50.9,
        "SD1neg": 45.3,
        "SD2neg": 44.0,
        "SD3neg": 42.6
    },
    {
        "Month": 22,
        "SD0": 46.9,
        "SD1": 48.3,
        "SD2": 49.7,
        "SD3": 51.1,
        "SD1neg": 45.5,
        "SD2neg": 44.1,
        "SD3neg": 42.7
    },
    {
        "Month": 23,
        "SD0": 47.0,
        "SD1": 48.4,
        "SD2": 49.8,
        "SD3": 51.2,
        "SD1neg": 45.6,
        "SD2neg": 44.3,
        "SD3neg": 42.9
    },
    {
        "Month": 24,
        "SD0": 47.2,
        "SD1": 48.6,
        "SD2": 50.0,
        "SD3": 51.4,
        "SD1neg": 45.8,
        "SD2neg": 44.4,
        "SD3neg": 43.0
    },
    {
        "Month": 25,
        "SD0": 47.3,
        "SD1": 48.7,
        "SD2": 50.1,
        "SD3": 51.5,
        "SD1neg": 45.9,
        "SD2neg": 44.5,
        "SD3neg": 43.1
    },
    {
        "Month": 26,
        "SD0": 47.5,
        "SD1": 48.9,
        "SD2": 50.3,
        "SD3": 51.7,
        "SD1neg": 46.1,
        "SD2neg": 44.7,
        "SD3neg": 43.3
    },
    {
        "Month": 27,
        "SD0": 47.6,
        "SD1": 49.0,
        "SD2": 50.4,
        "SD3": 51.8,
        "SD1neg": 46.2,
        "SD2neg": 44.8,
        "SD3neg": 43.4
    },
    {
        "Month": 28,
        "SD0": 47.7,
        "SD1": 49.1,
        "SD2": 50.5,
        "SD3": 51.9,
        "SD1neg": 46.3,
        "SD2neg": 44.9,
        "SD3neg": 43.5
    },
    {
        "Month": 29,
        "SD0": 47.8,
        "SD1": 49.2,
        "SD2": 50.6,
        "SD3": 52.0,
        "SD1neg": 46.4,
        "SD2neg": 45.0,
        "SD3neg": 43.6
    },
    {
        "Month": 30,
        "SD0": 47.9,
        "SD1": 49.3,
        "SD2": 50.7,
        "SD3": 52.2,
        "SD1neg": 46.5,
        "SD2neg": 45.1,
        "SD3neg": 43.7
    },
    {
        "Month": 31,
        "SD0": 48.0,
        "SD1": 49.4,
        "SD2": 50.9,
        "SD3": 52.3,
        "SD1neg": 46.6,
        "SD2neg": 45.2,
        "SD3neg": 43.8
    },
    {
        "Month": 32,
        "SD0": 48.1,
        "SD1": 49.6,
        "SD2": 51.0,
        "SD3": 52.4,
        "SD1neg": 46.7,
        "SD2neg": 45.3,
        "SD3neg": 43.9
    },
    {
        "Month": 33,
        "SD0": 48.2,
        "SD1": 49.7,
        "SD2": 51.1,
        "SD3": 52.5,
        "SD1neg": 46.8,
        "SD2neg": 45.4,
        "SD3neg": 44.0
    },
    {
        "Month": 34,
        "SD0": 48.3,
        "SD1": 49.7,
        "SD2": 51.2,
        "SD3": 52.6,
        "SD1neg": 46.9,
        "SD2neg": 45.5,
        "SD3neg": 44.1
    },
    {
        "Month": 35,
        "SD0": 48.4,
        "SD1": 49.8,
        "SD2": 51.2,
        "SD3": 52.7,
        "SD1neg": 47.0,
        "SD2neg": 45.6,
        "SD3neg": 44.2
    },
    {
        "Month": 36,
        "SD0": 48.5,
        "SD1": 49.9,
        "SD2": 51.3,
        "SD3": 52.7,
        "SD1neg": 47.1,
        "SD2neg": 45.7,
        "SD3neg": 44.3
    },
    {
        "Month": 37,
        "SD0": 48.6,
        "SD1": 50.0,
        "SD2": 51.4,
        "SD3": 52.8,
        "SD1neg": 47.2,
        "SD2neg": 45.8,
        "SD3neg": 44.4
    },
    {
        "Month": 38,
        "SD0": 48.7,
        "SD1": 50.1,
        "SD2": 51.5,
        "SD3": 52.9,
        "SD1neg": 47.3,
        "SD2neg": 45.8,
        "SD3neg": 44.4
    },
    {
        "Month": 39,
        "SD0": 48.7,
        "SD1": 50.2,
        "SD2": 51.6,
        "SD3": 53.0,
        "SD1neg": 47.3,
        "SD2neg": 45.9,
        "SD3neg": 44.5
    },
    {
        "Month": 40,
        "SD0": 48.8,
        "SD1": 50.2,
        "SD2": 51.7,
        "SD3": 53.1,
        "SD1neg": 47.4,
        "SD2neg": 46.0,
        "SD3neg": 44.6
    },
    {
        "Month": 41,
        "SD0": 48.9,
        "SD1": 50.3,
        "SD2": 51.7,
        "SD3": 53.1,
        "SD1neg": 47.5,
        "SD2neg": 46.1,
        "SD3neg": 44.6
    },
    {
        "Month": 42,
        "SD0": 49.0,
        "SD1": 50.4,
        "SD2": 51.8,
        "SD3": 53.2,
        "SD1neg": 47.5,
        "SD2neg": 46.1,
        "SD3neg": 44.7
    },
    {
        "Month": 43,
        "SD0": 49.0,
        "SD1": 50.4,
        "SD2": 51.9,
        "SD3": 53.3,
        "SD1neg": 47.6,
        "SD2neg": 46.2,
        "SD3neg": 44.8
    },
    {
        "Month": 44,
        "SD0": 49.1,
        "SD1": 50.5,
        "SD2": 51.9,
        "SD3": 53.3,
        "SD1neg": 47.7,
        "SD2neg": 46.3,
        "SD3neg": 44.8
    },
    {
        "Month": 45,
        "SD0": 49.2,
        "SD1": 50.6,
        "SD2": 52.0,
        "SD3": 53.4,
        "SD1neg": 47.7,
        "SD2neg": 46.3,
        "SD3neg": 44.9
    },
    {
        "Month": 46,
        "SD0": 49.2,
        "SD1": 50.6,
        "SD2": 52.1,
        "SD3": 53.5,
        "SD1neg": 47.8,
        "SD2neg": 46.4,
        "SD3neg": 45.0
    },
    {
        "Month": 47,
        "SD0": 49.3,
        "SD1": 50.7,
        "SD2": 52.1,
        "SD3": 53.5,
        "SD1neg": 47.9,
        "SD2neg": 46.4,
        "SD3neg": 45.0
    },
    {
        "Month": 48,
        "SD0": 49.3,
        "SD1": 50.8,
        "SD2": 52.2,
        "SD3": 53.6,
        "SD1neg": 47.9,
        "SD2neg": 46.5,
        "SD3neg": 45.1
    },
    {
        "Month": 49,
        "SD0": 49.4,
        "SD1": 50.8,
        "SD2": 52.2,
        "SD3": 53.6,
        "SD1neg": 48.0,
        "SD2neg": 46.5,
        "SD3neg": 45.1
    },
    {
        "Month": 50,
        "SD0": 49.4,
        "SD1": 50.9,
        "SD2": 52.3,
        "SD3": 53.7,
        "SD1neg": 48.0,
        "SD2neg": 46.6,
        "SD3neg": 45.2
    },
    {
        "Month": 51,
        "SD0": 49.5,
        "SD1": 50.9,
        "SD2": 52.3,
        "SD3": 53.8,
        "SD1neg": 48.1,
        "SD2neg": 46.7,
        "SD3neg": 45.2
    },
    {
        "Month": 52,
        "SD0": 49.5,
        "SD1": 51.0,
        "SD2": 52.4,
        "SD3": 53.8,
        "SD1neg": 48.1,
        "SD2neg": 46.7,
        "SD3neg": 45.3
    },
    {
        "Month": 53,
        "SD0": 49.6,
        "SD1": 51.0,
        "SD2": 52.4,
        "SD3": 53.9,
        "SD1neg": 48.2,
        "SD2neg": 46.8,
        "SD3neg": 45.3
    },
    {
        "Month": 54,
        "SD0": 49.6,
        "SD1": 51.1,
        "SD2": 52.5,
        "SD3": 53.9,
        "SD1neg": 48.2,
        "SD2neg": 46.8,
        "SD3neg": 45.4
    },
    {
        "Month": 55,
        "SD0": 49.7,
        "SD1": 51.1,
        "SD2": 52.5,
        "SD3": 54.0,
        "SD1neg": 48.3,
        "SD2neg": 46.9,
        "SD3neg": 45.4
    },
    {
        "Month": 56,
        "SD0": 49.7,
        "SD1": 51.2,
        "SD2": 52.6,
        "SD3": 54.0,
        "SD1neg": 48.3,
        "SD2neg": 46.9,
        "SD3neg": 45.5
    },
    {
        "Month": 57,
        "SD0": 49.8,
        "SD1": 51.2,
        "SD2": 52.6,
        "SD3": 54.1,
        "SD1neg": 48.4,
        "SD2neg": 46.9,
        "SD3neg": 45.5
    },
    {
        "Month": 58,
        "SD0": 49.8,
        "SD1": 51.3,
        "SD2": 52.7,
        "SD3": 54.1,
        "SD1neg": 48.4,
        "SD2neg": 47.0,
        "SD3neg": 45.6
    },
    {
        "Month": 59,
        "SD0": 49.9,
        "SD1": 51.3,
        "SD2": 52.7,
        "SD3": 54.1,
        "SD1neg": 48.5,
        "SD2neg": 47.0,
        "SD3neg": 45.6
    },
    {
        "Month": 60,
        "SD0": 49.9,
        "SD1": 51.3,
        "SD2": 52.8,
        "SD3": 54.2,
        "SD1neg": 48.5,
        "SD2neg": 47.1,
        "SD3neg": 45.7
    }
]