export const lhfa_g_2_to_5_years_zscores = [
    {
        "Month": 0,
        "SD0": null,
        "SD1": null,
        "SD2": null,
        "SD3": null,
        "SD1neg": null,
        "SD2neg": null,
        "SD3neg": null
    },
    {
        "Month": 1,
        "SD0": null,
        "SD1": null,
        "SD2": null,
        "SD3": null,
        "SD1neg": null,
        "SD2neg": null,
        "SD3neg": null
    },
    {
        "Month": 2,
        "SD0": null,
        "SD1": null,
        "SD2": null,
        "SD3": null,
        "SD1neg": null,
        "SD2neg": null,
        "SD3neg": null
    },
    {
        "Month": 3,
        "SD0": null,
        "SD1": null,
        "SD2": null,
        "SD3": null,
        "SD1neg": null,
        "SD2neg": null,
        "SD3neg": null
    },
    {
        "Month": 4,
        "SD0": null,
        "SD1": null,
        "SD2": null,
        "SD3": null,
        "SD1neg": null,
        "SD2neg": null,
        "SD3neg": null
    },
    {
        "Month": 5,
        "SD0": null,
        "SD1": null,
        "SD2": null,
        "SD3": null,
        "SD1neg": null,
        "SD2neg": null,
        "SD3neg": null
    },
    {
        "Month": 6,
        "SD0": null,
        "SD1": null,
        "SD2": null,
        "SD3": null,
        "SD1neg": null,
        "SD2neg": null,
        "SD3neg": null
    },
    {
        "Month": 7,
        "SD0": null,
        "SD1": null,
        "SD2": null,
        "SD3": null,
        "SD1neg": null,
        "SD2neg": null,
        "SD3neg": null
    },
    {
        "Month": 8,
        "SD0": null,
        "SD1": null,
        "SD2": null,
        "SD3": null,
        "SD1neg": null,
        "SD2neg": null,
        "SD3neg": null
    },
    {
        "Month": 9,
        "SD0": null,
        "SD1": null,
        "SD2": null,
        "SD3": null,
        "SD1neg": null,
        "SD2neg": null,
        "SD3neg": null
    },
    {
        "Month": 10,
        "SD0": null,
        "SD1": null,
        "SD2": null,
        "SD3": null,
        "SD1neg": null,
        "SD2neg": null,
        "SD3neg": null
    },
    {
        "Month": 11,
        "SD0": null,
        "SD1": null,
        "SD2": null,
        "SD3": null,
        "SD1neg": null,
        "SD2neg": null,
        "SD3neg": null
    },
    {
        "Month": 12,
        "SD0": null,
        "SD1": null,
        "SD2": null,
        "SD3": null,
        "SD1neg": null,
        "SD2neg": null,
        "SD3neg": null
    },
    {
        "Month": 13,
        "SD0": null,
        "SD1": null,
        "SD2": null,
        "SD3": null,
        "SD1neg": null,
        "SD2neg": null,
        "SD3neg": null
    },
    {
        "Month": 14,
        "SD0": null,
        "SD1": null,
        "SD2": null,
        "SD3": null,
        "SD1neg": null,
        "SD2neg": null,
        "SD3neg": null
    },
    {
        "Month": 15,
        "SD0": null,
        "SD1": null,
        "SD2": null,
        "SD3": null,
        "SD1neg": null,
        "SD2neg": null,
        "SD3neg": null
    },
    {
        "Month": 16,
        "SD0": null,
        "SD1": null,
        "SD2": null,
        "SD3": null,
        "SD1neg": null,
        "SD2neg": null,
        "SD3neg": null
    },
    {
        "Month": 17,
        "SD0": null,
        "SD1": null,
        "SD2": null,
        "SD3": null,
        "SD1neg": null,
        "SD2neg": null,
        "SD3neg": null
    },
    {
        "Month": 18,
        "SD0": null,
        "SD1": null,
        "SD2": null,
        "SD3": null,
        "SD1neg": null,
        "SD2neg": null,
        "SD3neg": null
    },
    {
        "Month": 19,
        "SD0": null,
        "SD1": null,
        "SD2": null,
        "SD3": null,
        "SD1neg": null,
        "SD2neg": null,
        "SD3neg": null
    },
    {
        "Month": 20,
        "SD0": null,
        "SD1": null,
        "SD2": null,
        "SD3": null,
        "SD1neg": null,
        "SD2neg": null,
        "SD3neg": null
    },
    {
        "Month": 21,
        "SD0": null,
        "SD1": null,
        "SD2": null,
        "SD3": null,
        "SD1neg": null,
        "SD2neg": null,
        "SD3neg": null
    },
    {
        "Month": 22,
        "SD0": null,
        "SD1": null,
        "SD2": null,
        "SD3": null,
        "SD1neg": null,
        "SD2neg": null,
        "SD3neg": null
    },
    {
        "Month": 23,
        "SD0": null,
        "SD1": null,
        "SD2": null,
        "SD3": null,
        "SD1neg": null,
        "SD2neg": null,
        "SD3neg": null
    },
    {
        "Month": 24,
        "SD0": 85.7,
        "SD1": 88.9,
        "SD2": 92.2,
        "SD3": 95.4,
        "SD1neg": 82.5,
        "SD2neg": 79.3,
        "SD3neg": 76.0
    },
    {
        "Month": 25,
        "SD0": 86.6,
        "SD1": 89.9,
        "SD2": 93.1,
        "SD3": 96.4,
        "SD1neg": 83.3,
        "SD2neg": 80.0,
        "SD3neg": 76.8
    },
    {
        "Month": 26,
        "SD0": 87.4,
        "SD1": 90.8,
        "SD2": 94.1,
        "SD3": 97.4,
        "SD1neg": 84.1,
        "SD2neg": 80.8,
        "SD3neg": 77.5
    },
    {
        "Month": 27,
        "SD0": 88.3,
        "SD1": 91.7,
        "SD2": 95.0,
        "SD3": 98.4,
        "SD1neg": 84.9,
        "SD2neg": 81.5,
        "SD3neg": 78.1
    },
    {
        "Month": 28,
        "SD0": 89.1,
        "SD1": 92.5,
        "SD2": 96.0,
        "SD3": 99.4,
        "SD1neg": 85.7,
        "SD2neg": 82.2,
        "SD3neg": 78.8
    },
    {
        "Month": 29,
        "SD0": 89.9,
        "SD1": 93.4,
        "SD2": 96.9,
        "SD3": 100.3,
        "SD1neg": 86.4,
        "SD2neg": 82.9,
        "SD3neg": 79.5
    },
    {
        "Month": 30,
        "SD0": 90.7,
        "SD1": 94.2,
        "SD2": 97.7,
        "SD3": 101.3,
        "SD1neg": 87.1,
        "SD2neg": 83.6,
        "SD3neg": 80.1
    },
    {
        "Month": 31,
        "SD0": 91.4,
        "SD1": 95.0,
        "SD2": 98.6,
        "SD3": 102.2,
        "SD1neg": 87.9,
        "SD2neg": 84.3,
        "SD3neg": 80.7
    },
    {
        "Month": 32,
        "SD0": 92.2,
        "SD1": 95.8,
        "SD2": 99.4,
        "SD3": 103.1,
        "SD1neg": 88.6,
        "SD2neg": 84.9,
        "SD3neg": 81.3
    },
    {
        "Month": 33,
        "SD0": 92.9,
        "SD1": 96.6,
        "SD2": 100.3,
        "SD3": 103.9,
        "SD1neg": 89.3,
        "SD2neg": 85.6,
        "SD3neg": 81.9
    },
    {
        "Month": 34,
        "SD0": 93.6,
        "SD1": 97.4,
        "SD2": 101.1,
        "SD3": 104.8,
        "SD1neg": 89.9,
        "SD2neg": 86.2,
        "SD3neg": 82.5
    },
    {
        "Month": 35,
        "SD0": 94.4,
        "SD1": 98.1,
        "SD2": 101.9,
        "SD3": 105.6,
        "SD1neg": 90.6,
        "SD2neg": 86.8,
        "SD3neg": 83.1
    },
    {
        "Month": 36,
        "SD0": 95.1,
        "SD1": 98.9,
        "SD2": 102.7,
        "SD3": 106.5,
        "SD1neg": 91.2,
        "SD2neg": 87.4,
        "SD3neg": 83.6
    },
    {
        "Month": 37,
        "SD0": 95.7,
        "SD1": 99.6,
        "SD2": 103.4,
        "SD3": 107.3,
        "SD1neg": 91.9,
        "SD2neg": 88.0,
        "SD3neg": 84.2
    },
    {
        "Month": 38,
        "SD0": 96.4,
        "SD1": 100.3,
        "SD2": 104.2,
        "SD3": 108.1,
        "SD1neg": 92.5,
        "SD2neg": 88.6,
        "SD3neg": 84.7
    },
    {
        "Month": 39,
        "SD0": 97.1,
        "SD1": 101.0,
        "SD2": 105.0,
        "SD3": 108.9,
        "SD1neg": 93.1,
        "SD2neg": 89.2,
        "SD3neg": 85.3
    },
    {
        "Month": 40,
        "SD0": 97.7,
        "SD1": 101.7,
        "SD2": 105.7,
        "SD3": 109.7,
        "SD1neg": 93.8,
        "SD2neg": 89.8,
        "SD3neg": 85.8
    },
    {
        "Month": 41,
        "SD0": 98.4,
        "SD1": 102.4,
        "SD2": 106.4,
        "SD3": 110.5,
        "SD1neg": 94.4,
        "SD2neg": 90.4,
        "SD3neg": 86.3
    },
    {
        "Month": 42,
        "SD0": 99.0,
        "SD1": 103.1,
        "SD2": 107.2,
        "SD3": 111.2,
        "SD1neg": 95.0,
        "SD2neg": 90.9,
        "SD3neg": 86.8
    },
    {
        "Month": 43,
        "SD0": 99.7,
        "SD1": 103.8,
        "SD2": 107.9,
        "SD3": 112.0,
        "SD1neg": 95.6,
        "SD2neg": 91.5,
        "SD3neg": 87.4
    },
    {
        "Month": 44,
        "SD0": 100.3,
        "SD1": 104.5,
        "SD2": 108.6,
        "SD3": 112.7,
        "SD1neg": 96.2,
        "SD2neg": 92.0,
        "SD3neg": 87.9
    },
    {
        "Month": 45,
        "SD0": 100.9,
        "SD1": 105.1,
        "SD2": 109.3,
        "SD3": 113.5,
        "SD1neg": 96.7,
        "SD2neg": 92.5,
        "SD3neg": 88.4
    },
    {
        "Month": 46,
        "SD0": 101.5,
        "SD1": 105.8,
        "SD2": 110.0,
        "SD3": 114.2,
        "SD1neg": 97.3,
        "SD2neg": 93.1,
        "SD3neg": 88.9
    },
    {
        "Month": 47,
        "SD0": 102.1,
        "SD1": 106.4,
        "SD2": 110.7,
        "SD3": 114.9,
        "SD1neg": 97.9,
        "SD2neg": 93.6,
        "SD3neg": 89.3
    },
    {
        "Month": 48,
        "SD0": 102.7,
        "SD1": 107.0,
        "SD2": 111.3,
        "SD3": 115.7,
        "SD1neg": 98.4,
        "SD2neg": 94.1,
        "SD3neg": 89.8
    },
    {
        "Month": 49,
        "SD0": 103.3,
        "SD1": 107.7,
        "SD2": 112.0,
        "SD3": 116.4,
        "SD1neg": 99.0,
        "SD2neg": 94.6,
        "SD3neg": 90.3
    },
    {
        "Month": 50,
        "SD0": 103.9,
        "SD1": 108.3,
        "SD2": 112.7,
        "SD3": 117.1,
        "SD1neg": 99.5,
        "SD2neg": 95.1,
        "SD3neg": 90.7
    },
    {
        "Month": 51,
        "SD0": 104.5,
        "SD1": 108.9,
        "SD2": 113.3,
        "SD3": 117.7,
        "SD1neg": 100.1,
        "SD2neg": 95.6,
        "SD3neg": 91.2
    },
    {
        "Month": 52,
        "SD0": 105.0,
        "SD1": 109.5,
        "SD2": 114.0,
        "SD3": 118.4,
        "SD1neg": 100.6,
        "SD2neg": 96.1,
        "SD3neg": 91.7
    },
    {
        "Month": 53,
        "SD0": 105.6,
        "SD1": 110.1,
        "SD2": 114.6,
        "SD3": 119.1,
        "SD1neg": 101.1,
        "SD2neg": 96.6,
        "SD3neg": 92.1
    },
    {
        "Month": 54,
        "SD0": 106.2,
        "SD1": 110.7,
        "SD2": 115.2,
        "SD3": 119.8,
        "SD1neg": 101.6,
        "SD2neg": 97.1,
        "SD3neg": 92.6
    },
    {
        "Month": 55,
        "SD0": 106.7,
        "SD1": 111.3,
        "SD2": 115.9,
        "SD3": 120.4,
        "SD1neg": 102.2,
        "SD2neg": 97.6,
        "SD3neg": 93.0
    },
    {
        "Month": 56,
        "SD0": 107.3,
        "SD1": 111.9,
        "SD2": 116.5,
        "SD3": 121.1,
        "SD1neg": 102.7,
        "SD2neg": 98.1,
        "SD3neg": 93.4
    },
    {
        "Month": 57,
        "SD0": 107.8,
        "SD1": 112.5,
        "SD2": 117.1,
        "SD3": 121.8,
        "SD1neg": 103.2,
        "SD2neg": 98.5,
        "SD3neg": 93.9
    },
    {
        "Month": 58,
        "SD0": 108.4,
        "SD1": 113.0,
        "SD2": 117.7,
        "SD3": 122.4,
        "SD1neg": 103.7,
        "SD2neg": 99.0,
        "SD3neg": 94.3
    },
    {
        "Month": 59,
        "SD0": 108.9,
        "SD1": 113.6,
        "SD2": 118.3,
        "SD3": 123.1,
        "SD1neg": 104.2,
        "SD2neg": 99.5,
        "SD3neg": 94.7
    },
    {
        "Month": 60,
        "SD0": 109.4,
        "SD1": 114.2,
        "SD2": 118.9,
        "SD3": 123.7,
        "SD1neg": 104.7,
        "SD2neg": 99.9,
        "SD3neg": 95.2
    }
]