// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.login-img {
    width: 100%;
    /* height: auto; */
}

.login-form {
    width: 50%;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
}

.login-btn {
    text-align: center;
    padding-top: 1em;
    padding-bottom: 1em;
}

@media only screen and (max-width: 700px) {
    .login-form {
        width: 70% !important;
    }

    .login-img {
        width: 80% !important;
        margin-bottom: 10px;
    }

    .input-row .MuiFormControl-root {
        width: 100% !important;
    }

    .input-row label {
        width: 100% !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/routes/login/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI;QACI,qBAAqB;IACzB;;IAEA;QACI,qBAAqB;QACrB,mBAAmB;IACvB;;IAEA;QACI,sBAAsB;IAC1B;;IAEA;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":[".login-container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    height: 100%;\n}\n\n.login-img {\n    width: 100%;\n    /* height: auto; */\n}\n\n.login-form {\n    width: 50%;\n    margin-top: 1.5em;\n    margin-bottom: 1.5em;\n}\n\n.login-btn {\n    text-align: center;\n    padding-top: 1em;\n    padding-bottom: 1em;\n}\n\n@media only screen and (max-width: 700px) {\n    .login-form {\n        width: 70% !important;\n    }\n\n    .login-img {\n        width: 80% !important;\n        margin-bottom: 10px;\n    }\n\n    .input-row .MuiFormControl-root {\n        width: 100% !important;\n    }\n\n    .input-row label {\n        width: 100% !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
