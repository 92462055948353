export const lhfa_months = [
    {
        "Month": 0
    },
    {
        "Month": 1
    },
    {
        "Month": 2
    },
    {
        "Month": 3
    },
    {
        "Month": 4
    },
    {
        "Month": 5
    },
    {
        "Month": 6,
        "SD0": 67.6,
        "SD1": 69.8,
        "SD2": 71.9,
        "SD3": 74.0,
        "SD1neg": 65.5,
        "SD2neg": 63.3,
        "SD3neg": 61.2
    },
    {
        "Month": 7,
        "SD0": 69.2,
        "SD1": 71.3,
        "SD2": 73.5,
        "SD3": 75.7,
        "SD1neg": 67.0,
        "SD2neg": 64.8,
        "SD3neg": 62.7
    },
    {
        "Month": 8,
        "SD0": 70.6,
        "SD1": 72.8,
        "SD2": 75.0,
        "SD3": 77.2,
        "SD1neg": 68.4,
        "SD2neg": 66.2,
        "SD3neg": 64.0
    },
    {
        "Month": 9,
        "SD0": 72.0,
        "SD1": 74.2,
        "SD2": 76.5,
        "SD3": 78.7,
        "SD1neg": 69.7,
        "SD2neg": 67.5,
        "SD3neg": 65.2
    },
    {
        "Month": 10,
        "SD0": 73.3,
        "SD1": 75.6,
        "SD2": 77.9,
        "SD3": 80.1,
        "SD1neg": 71.0,
        "SD2neg": 68.7,
        "SD3neg": 66.4
    },
    {
        "Month": 11,
        "SD0": 74.5,
        "SD1": 76.9,
        "SD2": 79.2,
        "SD3": 81.5,
        "SD1neg": 72.2,
        "SD2neg": 69.9,
        "SD3neg": 67.6
    },
    {
        "Month": 12,
        "SD0": 75.7,
        "SD1": 78.1,
        "SD2": 80.5,
        "SD3": 82.9,
        "SD1neg": 73.4,
        "SD2neg": 71.0,
        "SD3neg": 68.6
    },
    {
        "Month": 13,
        "SD0": 76.9,
        "SD1": 79.3,
        "SD2": 81.8,
        "SD3": 84.2,
        "SD1neg": 74.5,
        "SD2neg": 72.1,
        "SD3neg": 69.6
    },
    {
        "Month": 14,
        "SD0": 78.0,
        "SD1": 80.5,
        "SD2": 83.0,
        "SD3": 85.5,
        "SD1neg": 75.6,
        "SD2neg": 73.1,
        "SD3neg": 70.6
    },
    {
        "Month": 15,
        "SD0": 79.1,
        "SD1": 81.7,
        "SD2": 84.2,
        "SD3": 86.7,
        "SD1neg": 76.6,
        "SD2neg": 74.1,
        "SD3neg": 71.6
    },
    {
        "Month": 16,
        "SD0": 80.2,
        "SD1": 82.8,
        "SD2": 85.4,
        "SD3": 88.0,
        "SD1neg": 77.6,
        "SD2neg": 75.0,
        "SD3neg": 72.5
    },
    {
        "Month": 17,
        "SD0": 81.2,
        "SD1": 83.9,
        "SD2": 86.5,
        "SD3": 89.2,
        "SD1neg": 78.6,
        "SD2neg": 76.0,
        "SD3neg": 73.3
    },
    {
        "Month": 18,
        "SD0": 82.3,
        "SD1": 85.0,
        "SD2": 87.7,
        "SD3": 90.4,
        "SD1neg": 79.6,
        "SD2neg": 76.9,
        "SD3neg": 74.2
    },
    {
        "Month": 19,
        "SD0": 83.2,
        "SD1": 86.0,
        "SD2": 88.8,
        "SD3": 91.5,
        "SD1neg": 80.5,
        "SD2neg": 77.7,
        "SD3neg": 75.0
    },
    {
        "Month": 20,
        "SD0": 84.2,
        "SD1": 87.0,
        "SD2": 89.8,
        "SD3": 92.6,
        "SD1neg": 81.4,
        "SD2neg": 78.6,
        "SD3neg": 75.8
    },
    {
        "Month": 21,
        "SD0": 85.1,
        "SD1": 88.0,
        "SD2": 90.9,
        "SD3": 93.8,
        "SD1neg": 82.3,
        "SD2neg": 79.4,
        "SD3neg": 76.5
    },
    {
        "Month": 22,
        "SD0": 86.0,
        "SD1": 89.0,
        "SD2": 91.9,
        "SD3": 94.9,
        "SD1neg": 83.1,
        "SD2neg": 80.2,
        "SD3neg": 77.2
    },
    {
        "Month": 23,
        "SD0": 86.9,
        "SD1": 89.9,
        "SD2": 92.9,
        "SD3": 95.9,
        "SD1neg": 83.9,
        "SD2neg": 81.0,
        "SD3neg": 78.0
    },
    {
        "Month": 24,
        "SD0": 87.8,
        "SD1": 90.9,
        "SD2": 93.9,
        "SD3": 97.0,
        "SD1neg": 84.8,
        "SD2neg": 81.7,
        "SD3neg": 78.7
    },
    {
        "Month": 25,
        "SD0": 88.0,
        "SD1": 91.1,
        "SD2": 94.2,
        "SD3": 97.3,
        "SD1neg": 84.9,
        "SD2neg": 81.7,
        "SD3neg": 78.6
    },
    {
        "Month": 26,
        "SD0": 88.8,
        "SD1": 92.0,
        "SD2": 95.2,
        "SD3": 98.3,
        "SD1neg": 85.6,
        "SD2neg": 82.5,
        "SD3neg": 79.3
    },
    {
        "Month": 27,
        "SD0": 89.6,
        "SD1": 92.9,
        "SD2": 96.1,
        "SD3": 99.3,
        "SD1neg": 86.4,
        "SD2neg": 83.1,
        "SD3neg": 79.9
    },
    {
        "Month": 28,
        "SD0": 90.4,
        "SD1": 93.7,
        "SD2": 97.0,
        "SD3": 100.3,
        "SD1neg": 87.1,
        "SD2neg": 83.8,
        "SD3neg": 80.5
    },
    {
        "Month": 29,
        "SD0": 91.2,
        "SD1": 94.5,
        "SD2": 97.9,
        "SD3": 101.2,
        "SD1neg": 87.8,
        "SD2neg": 84.5,
        "SD3neg": 81.1
    },
    {
        "Month": 30,
        "SD0": 91.9,
        "SD1": 95.3,
        "SD2": 98.7,
        "SD3": 102.1,
        "SD1neg": 88.5,
        "SD2neg": 85.1,
        "SD3neg": 81.7
    },
    {
        "Month": 31,
        "SD0": 92.7,
        "SD1": 96.1,
        "SD2": 99.6,
        "SD3": 103.0,
        "SD1neg": 89.2,
        "SD2neg": 85.7,
        "SD3neg": 82.3
    },
    {
        "Month": 32,
        "SD0": 93.4,
        "SD1": 96.9,
        "SD2": 100.4,
        "SD3": 103.9,
        "SD1neg": 89.9,
        "SD2neg": 86.4,
        "SD3neg": 82.8
    },
    {
        "Month": 33,
        "SD0": 94.1,
        "SD1": 97.6,
        "SD2": 101.2,
        "SD3": 104.8,
        "SD1neg": 90.5,
        "SD2neg": 86.9,
        "SD3neg": 83.4
    },
    {
        "Month": 34,
        "SD0": 94.8,
        "SD1": 98.4,
        "SD2": 102.0,
        "SD3": 105.6,
        "SD1neg": 91.1,
        "SD2neg": 87.5,
        "SD3neg": 83.9
    },
    {
        "Month": 35,
        "SD0": 95.4,
        "SD1": 99.1,
        "SD2": 102.7,
        "SD3": 106.4,
        "SD1neg": 91.8,
        "SD2neg": 88.1,
        "SD3neg": 84.4
    },
    {
        "Month": 36,
        "SD0": 96.1,
        "SD1": 99.8,
        "SD2": 103.5,
        "SD3": 107.2,
        "SD1neg": 92.4,
        "SD2neg": 88.7,
        "SD3neg": 85.0
    },
    {
        "Month": 37,
        "SD0": 96.7,
        "SD1": 100.5,
        "SD2": 104.2,
        "SD3": 108.0,
        "SD1neg": 93.0,
        "SD2neg": 89.2,
        "SD3neg": 85.5
    },
    {
        "Month": 38,
        "SD0": 97.4,
        "SD1": 101.2,
        "SD2": 105.0,
        "SD3": 108.8,
        "SD1neg": 93.6,
        "SD2neg": 89.8,
        "SD3neg": 86.0
    },
    {
        "Month": 39,
        "SD0": 98.0,
        "SD1": 101.8,
        "SD2": 105.7,
        "SD3": 109.5,
        "SD1neg": 94.2,
        "SD2neg": 90.3,
        "SD3neg": 86.5
    },
    {
        "Month": 40,
        "SD0": 98.6,
        "SD1": 102.5,
        "SD2": 106.4,
        "SD3": 110.3,
        "SD1neg": 94.7,
        "SD2neg": 90.9,
        "SD3neg": 87.0
    },
    {
        "Month": 41,
        "SD0": 99.2,
        "SD1": 103.2,
        "SD2": 107.1,
        "SD3": 111.0,
        "SD1neg": 95.3,
        "SD2neg": 91.4,
        "SD3neg": 87.5
    },
    {
        "Month": 42,
        "SD0": 99.9,
        "SD1": 103.8,
        "SD2": 107.8,
        "SD3": 111.7,
        "SD1neg": 95.9,
        "SD2neg": 91.9,
        "SD3neg": 88.0
    },
    {
        "Month": 43,
        "SD0": 100.4,
        "SD1": 104.5,
        "SD2": 108.5,
        "SD3": 112.5,
        "SD1neg": 96.4,
        "SD2neg": 92.4,
        "SD3neg": 88.4
    },
    {
        "Month": 44,
        "SD0": 101.0,
        "SD1": 105.1,
        "SD2": 109.1,
        "SD3": 113.2,
        "SD1neg": 97.0,
        "SD2neg": 93.0,
        "SD3neg": 88.9
    },
    {
        "Month": 45,
        "SD0": 101.6,
        "SD1": 105.7,
        "SD2": 109.8,
        "SD3": 113.9,
        "SD1neg": 97.5,
        "SD2neg": 93.5,
        "SD3neg": 89.4
    },
    {
        "Month": 46,
        "SD0": 102.2,
        "SD1": 106.3,
        "SD2": 110.4,
        "SD3": 114.6,
        "SD1neg": 98.1,
        "SD2neg": 94.0,
        "SD3neg": 89.8
    },
    {
        "Month": 47,
        "SD0": 102.8,
        "SD1": 106.9,
        "SD2": 111.1,
        "SD3": 115.2,
        "SD1neg": 98.6,
        "SD2neg": 94.4,
        "SD3neg": 90.3
    },
    {
        "Month": 48,
        "SD0": 103.3,
        "SD1": 107.5,
        "SD2": 111.7,
        "SD3": 115.9,
        "SD1neg": 99.1,
        "SD2neg": 94.9,
        "SD3neg": 90.7
    },
    {
        "Month": 49,
        "SD0": 103.9,
        "SD1": 108.1,
        "SD2": 112.4,
        "SD3": 116.6,
        "SD1neg": 99.7,
        "SD2neg": 95.4,
        "SD3neg": 91.2
    },
    {
        "Month": 50,
        "SD0": 104.4,
        "SD1": 108.7,
        "SD2": 113.0,
        "SD3": 117.3,
        "SD1neg": 100.2,
        "SD2neg": 95.9,
        "SD3neg": 91.6
    },
    {
        "Month": 51,
        "SD0": 105.0,
        "SD1": 109.3,
        "SD2": 113.6,
        "SD3": 117.9,
        "SD1neg": 100.7,
        "SD2neg": 96.4,
        "SD3neg": 92.1
    },
    {
        "Month": 52,
        "SD0": 105.6,
        "SD1": 109.9,
        "SD2": 114.2,
        "SD3": 118.6,
        "SD1neg": 101.2,
        "SD2neg": 96.9,
        "SD3neg": 92.5
    },
    {
        "Month": 53,
        "SD0": 106.1,
        "SD1": 110.5,
        "SD2": 114.9,
        "SD3": 119.2,
        "SD1neg": 101.7,
        "SD2neg": 97.4,
        "SD3neg": 93.0
    },
    {
        "Month": 54,
        "SD0": 106.7,
        "SD1": 111.1,
        "SD2": 115.5,
        "SD3": 119.9,
        "SD1neg": 102.3,
        "SD2neg": 97.8,
        "SD3neg": 93.4
    },
    {
        "Month": 55,
        "SD0": 107.2,
        "SD1": 111.7,
        "SD2": 116.1,
        "SD3": 120.6,
        "SD1neg": 102.8,
        "SD2neg": 98.3,
        "SD3neg": 93.9
    },
    {
        "Month": 56,
        "SD0": 107.8,
        "SD1": 112.3,
        "SD2": 116.7,
        "SD3": 121.2,
        "SD1neg": 103.3,
        "SD2neg": 98.8,
        "SD3neg": 94.3
    },
    {
        "Month": 57,
        "SD0": 108.3,
        "SD1": 112.8,
        "SD2": 117.4,
        "SD3": 121.9,
        "SD1neg": 103.8,
        "SD2neg": 99.3,
        "SD3neg": 94.7
    },
    {
        "Month": 58,
        "SD0": 108.9,
        "SD1": 113.4,
        "SD2": 118.0,
        "SD3": 122.6,
        "SD1neg": 104.3,
        "SD2neg": 99.7,
        "SD3neg": 95.2
    },
    {
        "Month": 59,
        "SD0": 109.4,
        "SD1": 114.0,
        "SD2": 118.6,
        "SD3": 123.2,
        "SD1neg": 104.8,
        "SD2neg": 100.2,
        "SD3neg": 95.6
    },
    {
        "Month": 60,
        "SD0": 110.0,
        "SD1": 114.6,
        "SD2": 119.2,
        "SD3": 123.9,
        "SD1neg": 105.3,
        "SD2neg": 100.7,
        "SD3neg": 96.1
    }
]