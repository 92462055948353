export const wflh_length_height = [
    {
        "Length_height": 45
      },
      {
        "Length_height": 45.5
      },
      {
        "Length_height": 46
      },
      {
        "Length_height": 46.5
      },
      {
        "Length_height": 47
      },
      {
        "Length_height": 47.5,
        L: -0.3521,
        M: 2.8609,
        S: 0.09036,
        SD3neg: 2.2,
        SD2neg: 2.4,
        SD1neg: 2.6,
        SD0: 2.9,
        SD1: 3.1,
        SD2: 3.4,
        SD3: 3.8,
      },
      {
        "Length_height": 48,
        L: -0.3521,
        M: 2.948,
        S: 0.09007,
        SD3neg: 2.3,
        SD2neg: 2.5,
        SD1neg: 2.7,
        SD0: 2.9,
        SD1: 3.2,
        SD2: 3.6,
        SD3: 3.9,
      },
      {
        "Length_height": 48.5,
        L: -0.3521,
        M: 3.0377,
        S: 0.08977,
        SD3neg: 2.3,
        SD2neg: 2.6,
        SD1neg: 2.8,
        SD0: 3,
        SD1: 3.3,
        SD2: 3.7,
        SD3: 4,
      },
      {
        "Length_height": 49,
        L: -0.3521,
        M: 3.1308,
        S: 0.08948,
        SD3neg: 2.4,
        SD2neg: 2.6,
        SD1neg: 2.9,
        SD0: 3.1,
        SD1: 3.4,
        SD2: 3.8,
        SD3: 4.2,
      },
      {
        "Length_height": 49.5,
        L: -0.3521,
        M: 3.2276,
        S: 0.08919,
        SD3neg: 2.5,
        SD2neg: 2.7,
        SD1neg: 3,
        SD0: 3.2,
        SD1: 3.5,
        SD2: 3.9,
        SD3: 4.3,
      },
      {
        "Length_height": 50,
        L: -0.3521,
        M: 3.3278,
        S: 0.0889,
        SD3neg: 2.6,
        SD2neg: 2.8,
        SD1neg: 3,
        SD0: 3.3,
        SD1: 3.6,
        SD2: 4,
        SD3: 4.4,
      },
      {
        "Length_height": 50.5,
        L: -0.3521,
        M: 3.4311,
        S: 0.08861,
        SD3neg: 2.7,
        SD2neg: 2.9,
        SD1neg: 3.1,
        SD0: 3.4,
        SD1: 3.8,
        SD2: 4.1,
        SD3: 4.5,
      },
      {
        "Length_height": 51,
        L: -0.3521,
        M: 3.5376,
        S: 0.08831,
        SD3neg: 2.7,
        SD2neg: 3,
        SD1neg: 3.2,
        SD0: 3.5,
        SD1: 3.9,
        SD2: 4.2,
        SD3: 4.7,
      },
      {
        "Length_height": 51.5,
        L: -0.3521,
        M: 3.6477,
        S: 0.08801,
        SD3neg: 2.8,
        SD2neg: 3.1,
        SD1neg: 3.3,
        SD0: 3.6,
        SD1: 4,
        SD2: 4.4,
        SD3: 4.8,
      },
      {
        "Length_height": 52,
        L: -0.3521,
        M: 3.762,
        S: 0.08771,
        SD3neg: 2.9,
        SD2neg: 3.2,
        SD1neg: 3.5,
        SD0: 3.8,
        SD1: 4.1,
        SD2: 4.5,
        SD3: 5,
      },
      {
        "Length_height": 52.5,
        L: -0.3521,
        M: 3.8814,
        S: 0.08741,
        SD3neg: 3,
        SD2neg: 3.3,
        SD1neg: 3.6,
        SD0: 3.9,
        SD1: 4.2,
        SD2: 4.6,
        SD3: 5.1,
      },
      {
        "Length_height": 53,
        L: -0.3521,
        M: 4.006,
        S: 0.08711,
        SD3neg: 3.1,
        SD2neg: 3.4,
        SD1neg: 3.7,
        SD0: 4,
        SD1: 4.4,
        SD2: 4.8,
        SD3: 5.3,
      },
      {
        "Length_height": 53.5,
        L: -0.3521,
        M: 4.1354,
        S: 0.08681,
        SD3neg: 3.2,
        SD2neg: 3.5,
        SD1neg: 3.8,
        SD0: 4.1,
        SD1: 4.5,
        SD2: 4.9,
        SD3: 5.4,
      },
      {
        "Length_height": 54,
        L: -0.3521,
        M: 4.2693,
        S: 0.08651,
        SD3neg: 3.3,
        SD2neg: 3.6,
        SD1neg: 3.9,
        SD0: 4.3,
        SD1: 4.7,
        SD2: 5.1,
        SD3: 5.6,
      },
      {
        "Length_height": 54.5,
        L: -0.3521,
        M: 4.4066,
        S: 0.08621,
        SD3neg: 3.4,
        SD2neg: 3.7,
        SD1neg: 4,
        SD0: 4.4,
        SD1: 4.8,
        SD2: 5.3,
        SD3: 5.8,
      },
      {
        "Length_height": 55,
        L: -0.3521,
        M: 4.5467,
        S: 0.08592,
        SD3neg: 3.6,
        SD2neg: 3.8,
        SD1neg: 4.2,
        SD0: 4.5,
        SD1: 5,
        SD2: 5.4,
        SD3: 6,
      },
      {
        "Length_height": 55.5,
        L: -0.3521,
        M: 4.6892,
        S: 0.08563,
        SD3neg: 3.7,
        SD2neg: 4,
        SD1neg: 4.3,
        SD0: 4.7,
        SD1: 5.1,
        SD2: 5.6,
        SD3: 6.1,
      },
      {
        "Length_height": 56,
        L: -0.3521,
        M: 4.8338,
        S: 0.08535,
        SD3neg: 3.8,
        SD2neg: 4.1,
        SD1neg: 4.4,
        SD0: 4.8,
        SD1: 5.3,
        SD2: 5.8,
        SD3: 6.3,
      },
      {
        "Length_height": 56.5,
        L: -0.3521,
        M: 4.9796,
        S: 0.08507,
        SD3neg: 3.9,
        SD2neg: 4.2,
        SD1neg: 4.6,
        SD0: 5,
        SD1: 5.4,
        SD2: 5.9,
        SD3: 6.5,
      },
      {
        "Length_height": 57,
        L: -0.3521,
        M: 5.1259,
        S: 0.08481,
        SD3neg: 4,
        SD2neg: 4.3,
        SD1neg: 4.7,
        SD0: 5.1,
        SD1: 5.6,
        SD2: 6.1,
        SD3: 6.7,
      },
      {
        "Length_height": 57.5,
        L: -0.3521,
        M: 5.2721,
        S: 0.08455,
        SD3neg: 4.1,
        SD2neg: 4.5,
        SD1neg: 4.9,
        SD0: 5.3,
        SD1: 5.7,
        SD2: 6.3,
        SD3: 6.9,
      },
      {
        "Length_height": 58,
        L: -0.3521,
        M: 5.418,
        S: 0.0843,
        SD3neg: 4.3,
        SD2neg: 4.6,
        SD1neg: 5,
        SD0: 5.4,
        SD1: 5.9,
        SD2: 6.4,
        SD3: 7.1,
      },
      {
        "Length_height": 58.5,
        L: -0.3521,
        M: 5.5632,
        S: 0.08406,
        SD3neg: 4.4,
        SD2neg: 4.7,
        SD1neg: 5.1,
        SD0: 5.6,
        SD1: 6.1,
        SD2: 6.6,
        SD3: 7.2,
      },
      {
        "Length_height": 59,
        L: -0.3521,
        M: 5.7074,
        S: 0.08383,
        SD3neg: 4.5,
        SD2neg: 4.8,
        SD1neg: 5.3,
        SD0: 5.7,
        SD1: 6.2,
        SD2: 6.8,
        SD3: 7.4,
      },
      {
        "Length_height": 59.5,
        L: -0.3521,
        M: 5.8501,
        S: 0.08362,
        SD3neg: 4.6,
        SD2neg: 5,
        SD1neg: 5.4,
        SD0: 5.9,
        SD1: 6.4,
        SD2: 7,
        SD3: 7.6,
      },
      {
        "Length_height": 60,
        L: -0.3521,
        M: 5.9907,
        S: 0.08342,
        SD3neg: 4.7,
        SD2neg: 5.1,
        SD1neg: 5.5,
        SD0: 6,
        SD1: 6.5,
        SD2: 7.1,
        SD3: 7.8,
      },
      {
        "Length_height": 60.5,
        L: -0.3521,
        M: 6.1284,
        S: 0.08324,
        SD3neg: 4.8,
        SD2neg: 5.2,
        SD1neg: 5.6,
        SD0: 6.1,
        SD1: 6.7,
        SD2: 7.3,
        SD3: 8,
      },
      {
        "Length_height": 61,
        L: -0.3521,
        M: 6.2632,
        S: 0.08308,
        SD3neg: 4.9,
        SD2neg: 5.3,
        SD1neg: 5.8,
        SD0: 6.3,
        SD1: 6.8,
        SD2: 7.4,
        SD3: 8.1,
      },
      {
        "Length_height": 61.5,
        L: -0.3521,
        M: 6.3954,
        S: 0.08292,
        SD3neg: 5,
        SD2neg: 5.4,
        SD1neg: 5.9,
        SD0: 6.4,
        SD1: 7,
        SD2: 7.6,
        SD3: 8.3,
      },
      {
        "Length_height": 62,
        L: -0.3521,
        M: 6.5251,
        S: 0.08279,
        SD3neg: 5.1,
        SD2neg: 5.6,
        SD1neg: 6,
        SD0: 6.5,
        SD1: 7.1,
        SD2: 7.7,
        SD3: 8.5,
      },
      {
        "Length_height": 62.5,
        L: -0.3521,
        M: 6.6527,
        S: 0.08266,
        SD3neg: 5.2,
        SD2neg: 5.7,
        SD1neg: 6.1,
        SD0: 6.7,
        SD1: 7.2,
        SD2: 7.9,
        SD3: 8.6,
      },
      {
        "Length_height": 63,
        L: -0.3521,
        M: 6.7786,
        S: 0.08255,
        SD3neg: 5.3,
        SD2neg: 5.8,
        SD1neg: 6.2,
        SD0: 6.8,
        SD1: 7.4,
        SD2: 8,
        SD3: 8.8,
      },
      {
        "Length_height": 63.5,
        L: -0.3521,
        M: 6.9028,
        S: 0.08245,
        SD3neg: 5.4,
        SD2neg: 5.9,
        SD1neg: 6.4,
        SD0: 6.9,
        SD1: 7.5,
        SD2: 8.2,
        SD3: 8.9,
      },
      {
        "Length_height": 64,
        L: -0.3521,
        M: 7.0255,
        S: 0.08236,
        SD3neg: 5.5,
        SD2neg: 6,
        SD1neg: 6.5,
        SD0: 7,
        SD1: 7.6,
        SD2: 8.3,
        SD3: 9.1,
      },
      {
        "Length_height": 64.5,
        L: -0.3521,
        M: 7.1467,
        S: 0.08229,
        SD3neg: 5.6,
        SD2neg: 6.1,
        SD1neg: 6.6,
        SD0: 7.1,
        SD1: 7.8,
        SD2: 8.5,
        SD3: 9.3,
      },
      {
        "Length_height": 65,
        L: -0.3521,
        M: 7.2666,
        S: 0.08223,
        SD3neg: 5.7,
        SD2neg: 6.2,
        SD1neg: 6.7,
        SD0: 7.3,
        SD1: 7.9,
        SD2: 8.6,
        SD3: 9.4,
      },
      {
        "Length_height": 65.5,
        L: -0.3521,
        M: 7.3854,
        S: 0.08218,
        SD3neg: 5.8,
        SD2neg: 6.3,
        SD1neg: 6.8,
        SD0: 7.4,
        SD1: 8,
        SD2: 8.7,
        SD3: 9.6,
      },
      {
        "Length_height": 66,
        L: -0.3521,
        M: 7.5034,
        S: 0.08215,
        SD3neg: 5.9,
        SD2neg: 6.4,
        SD1neg: 6.9,
        SD0: 7.5,
        SD1: 8.2,
        SD2: 8.9,
        SD3: 9.7,
      },
      {
        "Length_height": 66.5,
        L: -0.3521,
        M: 7.6206,
        S: 0.08213,
        SD3neg: 6,
        SD2neg: 6.5,
        SD1neg: 7,
        SD0: 7.6,
        SD1: 8.3,
        SD2: 9,
        SD3: 9.9,
      },
      {
        "Length_height": 67,
        L: -0.3521,
        M: 7.737,
        S: 0.08212,
        SD3neg: 6.1,
        SD2neg: 6.6,
        SD1neg: 7.1,
        SD0: 7.7,
        SD1: 8.4,
        SD2: 9.2,
        SD3: 10,
      },
      {
        "Length_height": 67.5,
        L: -0.3521,
        M: 7.8526,
        S: 0.08212,
        SD3neg: 6.2,
        SD2neg: 6.7,
        SD1neg: 7.2,
        SD0: 7.9,
        SD1: 8.5,
        SD2: 9.3,
        SD3: 10.2,
      },
      {
        "Length_height": 68,
        L: -0.3521,
        M: 7.9674,
        S: 0.08214,
        SD3neg: 6.3,
        SD2neg: 6.8,
        SD1neg: 7.3,
        SD0: 8,
        SD1: 8.7,
        SD2: 9.4,
        SD3: 10.3,
      },
      {
        "Length_height": 68.5,
        L: -0.3521,
        M: 8.0816,
        S: 0.08216,
        SD3neg: 6.4,
        SD2neg: 6.9,
        SD1neg: 7.5,
        SD0: 8.1,
        SD1: 8.8,
        SD2: 9.6,
        SD3: 10.5,
      },
      {
        "Length_height": 69,
        L: -0.3521,
        M: 8.1955,
        S: 0.08219,
        SD3neg: 6.5,
        SD2neg: 7,
        SD1neg: 7.6,
        SD0: 8.2,
        SD1: 8.9,
        SD2: 9.7,
        SD3: 10.6,
      },
      {
        "Length_height": 69.5,
        L: -0.3521,
        M: 8.3092,
        S: 0.08224,
        SD3neg: 6.6,
        SD2neg: 7.1,
        SD1neg: 7.7,
        SD0: 8.3,
        SD1: 9,
        SD2: 9.8,
        SD3: 10.8,
      },
      {
        "Length_height": 70,
        L: -0.3521,
        M: 8.4227,
        S: 0.08229,
        SD3neg: 6.6,
        SD2neg: 7.2,
        SD1neg: 7.8,
        SD0: 8.4,
        SD1: 9.2,
        SD2: 10,
        SD3: 10.9,
      },
      {
        "Length_height": 70.5,
        L: -0.3521,
        M: 8.5358,
        S: 0.08235,
        SD3neg: 6.7,
        SD2neg: 7.3,
        SD1neg: 7.9,
        SD0: 8.5,
        SD1: 9.3,
        SD2: 10.1,
        SD3: 11.1,
      },
      {
        "Length_height": 71,
        L: -0.3521,
        M: 8.648,
        S: 0.08241,
        SD3neg: 6.8,
        SD2neg: 7.4,
        SD1neg: 8,
        SD0: 8.6,
        SD1: 9.4,
        SD2: 10.2,
        SD3: 11.2,
      },
      {
        "Length_height": 71.5,
        L: -0.3521,
        M: 8.7594,
        S: 0.08248,
        SD3neg: 6.9,
        SD2neg: 7.5,
        SD1neg: 8.1,
        SD0: 8.8,
        SD1: 9.5,
        SD2: 10.4,
        SD3: 11.3,
      },
      {
        "Length_height": 72,
        L: -0.3521,
        M: 8.8697,
        S: 0.08254,
        SD3neg: 7,
        SD2neg: 7.6,
        SD1neg: 8.2,
        SD0: 8.9,
        SD1: 9.6,
        SD2: 10.5,
        SD3: 11.5,
      },
      {
        "Length_height": 72.5,
        L: -0.3521,
        M: 8.9788,
        S: 0.08262,
        SD3neg: 7.1,
        SD2neg: 7.6,
        SD1neg: 8.3,
        SD0: 9,
        SD1: 9.8,
        SD2: 10.6,
        SD3: 11.6,
      },
      {
        "Length_height": 73,
        L: -0.3521,
        M: 9.0865,
        S: 0.08269,
        SD3neg: 7.2,
        SD2neg: 7.7,
        SD1neg: 8.4,
        SD0: 9.1,
        SD1: 9.9,
        SD2: 10.8,
        SD3: 11.8,
      },
      {
        "Length_height": 73.5,
        L: -0.3521,
        M: 9.1927,
        S: 0.08276,
        SD3neg: 7.2,
        SD2neg: 7.8,
        SD1neg: 8.5,
        SD0: 9.2,
        SD1: 10,
        SD2: 10.9,
        SD3: 11.9,
      },
      {
        "Length_height": 74,
        L: -0.3521,
        M: 9.2974,
        S: 0.08283,
        SD3neg: 7.3,
        SD2neg: 7.9,
        SD1neg: 8.6,
        SD0: 9.3,
        SD1: 10.1,
        SD2: 11,
        SD3: 12.1,
      },
      {
        "Length_height": 74.5,
        L: -0.3521,
        M: 9.401,
        S: 0.08289,
        SD3neg: 7.4,
        SD2neg: 8,
        SD1neg: 8.7,
        SD0: 9.4,
        SD1: 10.2,
        SD2: 11.2,
        SD3: 12.2,
      },
      {
        "Length_height": 75,
        L: -0.3521,
        M: 9.5032,
        S: 0.08295,
        SD3neg: 7.5,
        SD2neg: 8.1,
        SD1neg: 8.8,
        SD0: 9.5,
        SD1: 10.3,
        SD2: 11.3,
        SD3: 12.3,
      },
      {
        "Length_height": 75.5,
        L: -0.3521,
        M: 9.6041,
        S: 0.08301,
        SD3neg: 7.6,
        SD2neg: 8.2,
        SD1neg: 8.8,
        SD0: 9.6,
        SD1: 10.4,
        SD2: 11.4,
        SD3: 12.5,
      },
      {
        "Length_height": 76,
        L: -0.3521,
        M: 9.7033,
        S: 0.08307,
        SD3neg: 7.6,
        SD2neg: 8.3,
        SD1neg: 8.9,
        SD0: 9.7,
        SD1: 10.6,
        SD2: 11.5,
        SD3: 12.6,
      },
      {
        "Length_height": 76.5,
        L: -0.3521,
        M: 9.8007,
        S: 0.08311,
        SD3neg: 7.7,
        SD2neg: 8.3,
        SD1neg: 9,
        SD0: 9.8,
        SD1: 10.7,
        SD2: 11.6,
        SD3: 12.7,
      },
      {
        "Length_height": 77,
        L: -0.3521,
        M: 9.8963,
        S: 0.08314,
        SD3neg: 7.8,
        SD2neg: 8.4,
        SD1neg: 9.1,
        SD0: 9.9,
        SD1: 10.8,
        SD2: 11.7,
        SD3: 12.8,
      },
      {
        "Length_height": 77.5,
        L: -0.3521,
        M: 9.9902,
        S: 0.08317,
        SD3neg: 7.9,
        SD2neg: 8.5,
        SD1neg: 9.2,
        SD0: 10,
        SD1: 10.9,
        SD2: 11.9,
        SD3: 13,
      },
      {
        "Length_height": 78,
        L: -0.3521,
        M: 10.0827,
        S: 0.08318,
        SD3neg: 7.9,
        SD2neg: 8.6,
        SD1neg: 9.3,
        SD0: 10.1,
        SD1: 11,
        SD2: 12,
        SD3: 13.1,
      },
      {
        "Length_height": 78.5,
        L: -0.3521,
        M: 10.1741,
        S: 0.08318,
        SD3neg: 8,
        SD2neg: 8.7,
        SD1neg: 9.4,
        SD0: 10.2,
        SD1: 11.1,
        SD2: 12.1,
        SD3: 13.2,
      },
      {
        "Length_height": 79,
        L: -0.3521,
        M: 10.2649,
        S: 0.08316,
        SD3neg: 8.1,
        SD2neg: 8.7,
        SD1neg: 9.5,
        SD0: 10.3,
        SD1: 11.2,
        SD2: 12.2,
        SD3: 13.3,
      },
      {
        "Length_height": 79.5,
        L: -0.3521,
        M: 10.3558,
        S: 0.08313,
        SD3neg: 8.2,
        SD2neg: 8.8,
        SD1neg: 9.5,
        SD0: 10.4,
        SD1: 11.3,
        SD2: 12.3,
        SD3: 13.4,
      },
      {
        "Length_height": 80,
        L: -0.3521,
        M: 10.4475,
        S: 0.08308,
        SD3neg: 8.2,
        SD2neg: 8.9,
        SD1neg: 9.6,
        SD0: 10.4,
        SD1: 11.4,
        SD2: 12.4,
        SD3: 13.6,
      },
      {
        "Length_height": 80.5,
        L: -0.3521,
        M: 10.5405,
        S: 0.08301,
        SD3neg: 8.3,
        SD2neg: 9,
        SD1neg: 9.7,
        SD0: 10.5,
        SD1: 11.5,
        SD2: 12.5,
        SD3: 13.7,
      },
      {
        "Length_height": 81,
        L: -0.3521,
        M: 10.6352,
        S: 0.08293,
        SD3neg: 8.4,
        SD2neg: 9.1,
        SD1neg: 9.8,
        SD0: 10.6,
        SD1: 11.6,
        SD2: 12.6,
        SD3: 13.8,
      },
      {
        "Length_height": 81.5,
        L: -0.3521,
        M: 10.7322,
        S: 0.08284,
        SD3neg: 8.5,
        SD2neg: 9.1,
        SD1neg: 9.9,
        SD0: 10.7,
        SD1: 11.7,
        SD2: 12.7,
        SD3: 13.9,
      },
      {
        "Length_height": 82,
        L: -0.3521,
        M: 10.8321,
        S: 0.08273,
        SD3neg: 8.5,
        SD2neg: 9.2,
        SD1neg: 10,
        SD0: 10.8,
        SD1: 11.8,
        SD2: 12.8,
        SD3: 14,
      },
      {
        "Length_height": 82.5,
        L: -0.3521,
        M: 10.935,
        S: 0.0826,
        SD3neg: 8.6,
        SD2neg: 9.3,
        SD1neg: 10.1,
        SD0: 10.9,
        SD1: 11.9,
        SD2: 13,
        SD3: 14.2,
      },
      {
        "Length_height": 83,
        L: -0.3521,
        M: 11.0415,
        S: 0.08246,
        SD3neg: 8.7,
        SD2neg: 9.4,
        SD1neg: 10.2,
        SD0: 11,
        SD1: 12,
        SD2: 13.1,
        SD3: 14.3,
      },
      {
        "Length_height": 83.5,
        L: -0.3521,
        M: 11.1516,
        S: 0.08231,
        SD3neg: 8.8,
        SD2neg: 9.5,
        SD1neg: 10.3,
        SD0: 11.2,
        SD1: 12.1,
        SD2: 13.2,
        SD3: 14.4,
      },
      {
        "Length_height": 84,
        L: -0.3521,
        M: 11.2651,
        S: 0.08215,
        SD3neg: 8.9,
        SD2neg: 9.6,
        SD1neg: 10.4,
        SD0: 11.3,
        SD1: 12.2,
        SD2: 13.3,
        SD3: 14.6,
      },
      {
        "Length_height": 84.5,
        L: -0.3521,
        M: 11.3817,
        S: 0.08198,
        SD3neg: 9,
        SD2neg: 9.7,
        SD1neg: 10.5,
        SD0: 11.4,
        SD1: 12.4,
        SD2: 13.5,
        SD3: 14.7,
      },
      {
        "Length_height": 85,
        L: -0.3521,
        M: 11.5007,
        S: 0.08181,
        SD3neg: 9.1,
        SD2neg: 9.8,
        SD1neg: 10.6,
        SD0: 11.5,
        SD1: 12.5,
        SD2: 13.6,
        SD3: 14.9,
      },
      {
        "Length_height": 85.5,
        L: -0.3521,
        M: 11.6218,
        S: 0.08163,
        SD3neg: 9.2,
        SD2neg: 9.9,
        SD1neg: 10.7,
        SD0: 11.6,
        SD1: 12.6,
        SD2: 13.7,
        SD3: 15,
      },
      {
        "Length_height": 86,
        L: -0.3521,
        M: 11.7444,
        S: 0.08145,
        SD3neg: 9.3,
        SD2neg: 10,
        SD1neg: 10.8,
        SD0: 11.7,
        SD1: 12.8,
        SD2: 13.9,
        SD3: 15.2,
      },
      {
        "Length_height": 86.5,
        L: -0.3521,
        M: 11.8678,
        S: 0.08128,
        SD3neg: 9.4,
        SD2neg: 10.1,
        SD1neg: 11,
        SD0: 11.9,
        SD1: 12.9,
        SD2: 14,
        SD3: 15.3,
      },
      {
        "Length_height": 87,
        L: -0.3521,
        M: 11.9916,
        S: 0.08111,
        SD3neg: 9.5,
        SD2neg: 10.2,
        SD1neg: 11.1,
        SD0: 12,
        SD1: 13,
        SD2: 14.2,
        SD3: 15.5,
      },
       {
        "Length_height": 87,
        "L": -0.3521,
        "M": 12.1645,
        "S": 0.0809,
        "SD3neg": 9.6,
        "SD2neg": 10.4,
        "SD1neg": 11.2,
        "SD0": 12.2,
        "SD1": 13.2,
        "SD2": 14.4,
        "SD3": 15.7
       },
       {
        "Length_height": 87.5,
        "L": -0.3521,
        "M": 12.2871,
        "S": 0.08076,
        "SD3neg": 9.7,
        "SD2neg": 10.5,
        "SD1neg": 11.3,
        "SD0": 12.3,
        "SD1": 13.3,
        "SD2": 14.5,
        "SD3": 15.8
       },
       {
        "Length_height": 88,
        "L": -0.3521,
        "M": 12.4089,
        "S": 0.08064,
        "SD3neg": 9.8,
        "SD2neg": 10.6,
        "SD1neg": 11.5,
        "SD0": 12.4,
        "SD1": 13.5,
        "SD2": 14.7,
        "SD3": 16
       },
       {
        "Length_height": 88.5,
        "L": -0.3521,
        "M": 12.5298,
        "S": 0.08054,
        "SD3neg": 9.9,
        "SD2neg": 10.7,
        "SD1neg": 11.6,
        "SD0": 12.5,
        "SD1": 13.6,
        "SD2": 14.8,
        "SD3": 16.1
       },
       {
        "Length_height": 89,
        "L": -0.3521,
        "M": 12.6495,
        "S": 0.08045,
        "SD3neg": 10,
        "SD2neg": 10.8,
        "SD1neg": 11.7,
        "SD0": 12.6,
        "SD1": 13.7,
        "SD2": 14.9,
        "SD3": 16.3
       },
       {
        "Length_height": 89.5,
        "L": -0.3521,
        "M": 12.7683,
        "S": 0.08038,
        "SD3neg": 10.1,
        "SD2neg": 10.9,
        "SD1neg": 11.8,
        "SD0": 12.8,
        "SD1": 13.9,
        "SD2": 15.1,
        "SD3": 16.4
       },
       {
        "Length_height": 90,
        "L": -0.3521,
        "M": 12.8864,
        "S": 0.08032,
        "SD3neg": 10.2,
        "SD2neg": 11,
        "SD1neg": 11.9,
        "SD0": 12.9,
        "SD1": 14,
        "SD2": 15.2,
        "SD3": 16.6
       },
       {
        "Length_height": 90.5,
        "L": -0.3521,
        "M": 13.0038,
        "S": 0.08028,
        "SD3neg": 10.3,
        "SD2neg": 11.1,
        "SD1neg": 12,
        "SD0": 13,
        "SD1": 14.1,
        "SD2": 15.3,
        "SD3": 16.7
       },
       {
        "Length_height": 91,
        "L": -0.3521,
        "M": 13.1209,
        "S": 0.08025,
        "SD3neg": 10.4,
        "SD2neg": 11.2,
        "SD1neg": 12.1,
        "SD0": 13.1,
        "SD1": 14.2,
        "SD2": 15.5,
        "SD3": 16.9
       },
       {
        "Length_height": 91.5,
        "L": -0.3521,
        "M": 13.2376,
        "S": 0.08024,
        "SD3neg": 10.5,
        "SD2neg": 11.3,
        "SD1neg": 12.2,
        "SD0": 13.2,
        "SD1": 14.4,
        "SD2": 15.6,
        "SD3": 17
       },
       {
        "Length_height": 92,
        "L": -0.3521,
        "M": 13.3541,
        "S": 0.08025,
        "SD3neg": 10.6,
        "SD2neg": 11.4,
        "SD1neg": 12.3,
        "SD0": 13.4,
        "SD1": 14.5,
        "SD2": 15.8,
        "SD3": 17.2
       },
       {
        "Length_height": 92.5,
        "L": -0.3521,
        "M": 13.4705,
        "S": 0.08027,
        "SD3neg": 10.7,
        "SD2neg": 11.5,
        "SD1neg": 12.4,
        "SD0": 13.5,
        "SD1": 14.6,
        "SD2": 15.9,
        "SD3": 17.3
       },
       {
        "Length_height": 93,
        "L": -0.3521,
        "M": 13.587,
        "S": 0.08031,
        "SD3neg": 10.8,
        "SD2neg": 11.6,
        "SD1neg": 12.6,
        "SD0": 13.6,
        "SD1": 14.7,
        "SD2": 16,
        "SD3": 17.5
       },
       {
        "Length_height": 93.5,
        "L": -0.3521,
        "M": 13.7041,
        "S": 0.08036,
        "SD3neg": 10.9,
        "SD2neg": 11.7,
        "SD1neg": 12.7,
        "SD0": 13.7,
        "SD1": 14.9,
        "SD2": 16.2,
        "SD3": 17.6
       },
       {
        "Length_height": 94,
        "L": -0.3521,
        "M": 13.8217,
        "S": 0.08043,
        "SD3neg": 11,
        "SD2neg": 11.8,
        "SD1neg": 12.8,
        "SD0": 13.8,
        "SD1": 15,
        "SD2": 16.3,
        "SD3": 17.8
       },
       {
        "Length_height": 94.5,
        "L": -0.3521,
        "M": 13.9403,
        "S": 0.08051,
        "SD3neg": 11.1,
        "SD2neg": 11.9,
        "SD1neg": 12.9,
        "SD0": 13.9,
        "SD1": 15.1,
        "SD2": 16.5,
        "SD3": 17.9
       },
       {
        "Length_height": 95,
        "L": -0.3521,
        "M": 14.06,
        "S": 0.0806,
        "SD3neg": 11.1,
        "SD2neg": 12,
        "SD1neg": 13,
        "SD0": 14.1,
        "SD1": 15.3,
        "SD2": 16.6,
        "SD3": 18.1
       },
       {
        "Length_height": 95.5,
        "L": -0.3521,
        "M": 14.1811,
        "S": 0.08071,
        "SD3neg": 11.2,
        "SD2neg": 12.1,
        "SD1neg": 13.1,
        "SD0": 14.2,
        "SD1": 15.4,
        "SD2": 16.7,
        "SD3": 18.3
       },
       {
        "Length_height": 96,
        "L": -0.3521,
        "M": 14.3037,
        "S": 0.08083,
        "SD3neg": 11.3,
        "SD2neg": 12.2,
        "SD1neg": 13.2,
        "SD0": 14.3,
        "SD1": 15.5,
        "SD2": 16.9,
        "SD3": 18.4
       },
       {
        "Length_height": 96.5,
        "L": -0.3521,
        "M": 14.4282,
        "S": 0.08097,
        "SD3neg": 11.4,
        "SD2neg": 12.3,
        "SD1neg": 13.3,
        "SD0": 14.4,
        "SD1": 15.7,
        "SD2": 17,
        "SD3": 18.6
       },
       {
        "Length_height": 97,
        "L": -0.3521,
        "M": 14.5547,
        "S": 0.08112,
        "SD3neg": 11.5,
        "SD2neg": 12.4,
        "SD1neg": 13.4,
        "SD0": 14.6,
        "SD1": 15.8,
        "SD2": 17.2,
        "SD3": 18.8
       },
       {
        "Length_height": 97.5,
        "L": -0.3521,
        "M": 14.6832,
        "S": 0.08129,
        "SD3neg": 11.6,
        "SD2neg": 12.5,
        "SD1neg": 13.6,
        "SD0": 14.7,
        "SD1": 15.9,
        "SD2": 17.4,
        "SD3": 18.9
       },
       {
        "Length_height": 98,
        "L": -0.3521,
        "M": 14.814,
        "S": 0.08146,
        "SD3neg": 11.7,
        "SD2neg": 12.6,
        "SD1neg": 13.7,
        "SD0": 14.8,
        "SD1": 16.1,
        "SD2": 17.5,
        "SD3": 19.1
       },
       {
        "Length_height": 98.5,
        "L": -0.3521,
        "M": 14.9468,
        "S": 0.08165,
        "SD3neg": 11.8,
        "SD2neg": 12.8,
        "SD1neg": 13.8,
        "SD0": 14.9,
        "SD1": 16.2,
        "SD2": 17.7,
        "SD3": 19.3
       },
       {
        "Length_height": 99,
        "L": -0.3521,
        "M": 15.0818,
        "S": 0.08185,
        "SD3neg": 11.9,
        "SD2neg": 12.9,
        "SD1neg": 13.9,
        "SD0": 15.1,
        "SD1": 16.4,
        "SD2": 17.9,
        "SD3": 19.5
       },
       {
        "Length_height": 99.5,
        "L": -0.3521,
        "M": 15.2187,
        "S": 0.08206,
        "SD3neg": 12,
        "SD2neg": 13,
        "SD1neg": 14,
        "SD0": 15.2,
        "SD1": 16.5,
        "SD2": 18,
        "SD3": 19.7
       },
       {
        "Length_height": 100,
        "L": -0.3521,
        "M": 15.3576,
        "S": 0.08229,
        "SD3neg": 12.1,
        "SD2neg": 13.1,
        "SD1neg": 14.2,
        "SD0": 15.4,
        "SD1": 16.7,
        "SD2": 18.2,
        "SD3": 19.9
       },
       {
        "Length_height": 100.5,
        "L": -0.3521,
        "M": 15.4985,
        "S": 0.08252,
        "SD3neg": 12.2,
        "SD2neg": 13.2,
        "SD1neg": 14.3,
        "SD0": 15.5,
        "SD1": 16.9,
        "SD2": 18.4,
        "SD3": 20.1
       },
       {
        "Length_height": 101,
        "L": -0.3521,
        "M": 15.6412,
        "S": 0.08277,
        "SD3neg": 12.3,
        "SD2neg": 13.3,
        "SD1neg": 14.4,
        "SD0": 15.6,
        "SD1": 17,
        "SD2": 18.5,
        "SD3": 20.3
       },
       {
        "Length_height": 101.5,
        "L": -0.3521,
        "M": 15.7857,
        "S": 0.08302,
        "SD3neg": 12.4,
        "SD2neg": 13.4,
        "SD1neg": 14.5,
        "SD0": 15.8,
        "SD1": 17.2,
        "SD2": 18.7,
        "SD3": 20.5
       },
       {
        "Length_height": 102,
        "L": -0.3521,
        "M": 15.932,
        "S": 0.08328,
        "SD3neg": 12.5,
        "SD2neg": 13.6,
        "SD1neg": 14.7,
        "SD0": 15.9,
        "SD1": 17.3,
        "SD2": 18.9,
        "SD3": 20.7
       },
       {
        "Length_height": 102.5,
        "L": -0.3521,
        "M": 16.0801,
        "S": 0.08354,
        "SD3neg": 12.6,
        "SD2neg": 13.7,
        "SD1neg": 14.8,
        "SD0": 16.1,
        "SD1": 17.5,
        "SD2": 19.1,
        "SD3": 20.9
       },
       {
        "Length_height": 103,
        "L": -0.3521,
        "M": 16.2298,
        "S": 0.08381,
        "SD3neg": 12.8,
        "SD2neg": 13.8,
        "SD1neg": 14.9,
        "SD0": 16.2,
        "SD1": 17.7,
        "SD2": 19.3,
        "SD3": 21.1
       },
       {
        "Length_height": 103.5,
        "L": -0.3521,
        "M": 16.3812,
        "S": 0.08408,
        "SD3neg": 12.9,
        "SD2neg": 13.9,
        "SD1neg": 15.1,
        "SD0": 16.4,
        "SD1": 17.8,
        "SD2": 19.5,
        "SD3": 21.3
       },
       {
        "Length_height": 104,
        "L": -0.3521,
        "M": 16.5342,
        "S": 0.08436,
        "SD3neg": 13,
        "SD2neg": 14,
        "SD1neg": 15.2,
        "SD0": 16.5,
        "SD1": 18,
        "SD2": 19.7,
        "SD3": 21.6
       },
       {
        "Length_height": 104.5,
        "L": -0.3521,
        "M": 16.6889,
        "S": 0.08464,
        "SD3neg": 13.1,
        "SD2neg": 14.2,
        "SD1neg": 15.4,
        "SD0": 16.7,
        "SD1": 18.2,
        "SD2": 19.9,
        "SD3": 21.8
       },
       {
        "Length_height": 105,
        "L": -0.3521,
        "M": 16.8454,
        "S": 0.08493,
        "SD3neg": 13.2,
        "SD2neg": 14.3,
        "SD1neg": 15.5,
        "SD0": 16.8,
        "SD1": 18.4,
        "SD2": 20.1,
        "SD3": 22
       },
       {
        "Length_height": 105.5,
        "L": -0.3521,
        "M": 17.0036,
        "S": 0.08521,
        "SD3neg": 13.3,
        "SD2neg": 14.4,
        "SD1neg": 15.6,
        "SD0": 17,
        "SD1": 18.5,
        "SD2": 20.3,
        "SD3": 22.2
       },
       {
        "Length_height": 106,
        "L": -0.3521,
        "M": 17.1637,
        "S": 0.08551,
        "SD3neg": 13.4,
        "SD2neg": 14.5,
        "SD1neg": 15.8,
        "SD0": 17.2,
        "SD1": 18.7,
        "SD2": 20.5,
        "SD3": 22.5
       },
       {
        "Length_height": 106.5,
        "L": -0.3521,
        "M": 17.3256,
        "S": 0.0858,
        "SD3neg": 13.5,
        "SD2neg": 14.7,
        "SD1neg": 15.9,
        "SD0": 17.3,
        "SD1": 18.9,
        "SD2": 20.7,
        "SD3": 22.7
       },
       {
        "Length_height": 107,
        "L": -0.3521,
        "M": 17.4894,
        "S": 0.08611,
        "SD3neg": 13.7,
        "SD2neg": 14.8,
        "SD1neg": 16.1,
        "SD0": 17.5,
        "SD1": 19.1,
        "SD2": 20.9,
        "SD3": 22.9
       },
       {
        "Length_height": 107.5,
        "L": -0.3521,
        "M": 17.655,
        "S": 0.08641,
        "SD3neg": 13.8,
        "SD2neg": 14.9,
        "SD1neg": 16.2,
        "SD0": 17.7,
        "SD1": 19.3,
        "SD2": 21.1,
        "SD3": 23.2
       },
       {
        "Length_height": 108,
        "L": -0.3521,
        "M": 17.8226,
        "S": 0.08673,
        "SD3neg": 13.9,
        "SD2neg": 15.1,
        "SD1neg": 16.4,
        "SD0": 17.8,
        "SD1": 19.5,
        "SD2": 21.3,
        "SD3": 23.4
       },
       {
        "Length_height": 108.5,
        "L": -0.3521,
        "M": 17.9924,
        "S": 0.08704,
        "SD3neg": 14,
        "SD2neg": 15.2,
        "SD1neg": 16.5,
        "SD0": 18,
        "SD1": 19.7,
        "SD2": 21.5,
        "SD3": 23.7
       },
       {
        "Length_height": 109,
        "L": -0.3521,
        "M": 18.1645,
        "S": 0.08736,
        "SD3neg": 14.1,
        "SD2neg": 15.3,
        "SD1neg": 16.7,
        "SD0": 18.2,
        "SD1": 19.8,
        "SD2": 21.8,
        "SD3": 23.9
       },
       {
        "Length_height": 109.5,
        "L": -0.3521,
        "M": 18.339,
        "S": 0.08768,
        "SD3neg": 14.3,
        "SD2neg": 15.5,
        "SD1neg": 16.8,
        "SD0": 18.3,
        "SD1": 20,
        "SD2": 22,
        "SD3": 24.2
       },
       {
        "Length_height": 110,
        "L": -0.3521,
        "M": 18.5158,
        "S": 0.088,
        "SD3neg": 14.4,
        "SD2neg": 15.6,
        "SD1neg": 17,
        "SD0": 18.5,
        "SD1": 20.2,
        "SD2": 22.2,
        "SD3": 24.4
       },
       {
        "Length_height": 110.5,
        "L": -0.3521,
        "M": 18.6948,
        "S": 0.08832,
        "SD3neg": 14.5,
        "SD2neg": 15.8,
        "SD1neg": 17.1,
        "SD0": 18.7,
        "SD1": 20.4,
        "SD2": 22.4,
        "SD3": 24.7
       },
       {
        "Length_height": 111,
        "L": -0.3521,
        "M": 18.8759,
        "S": 0.08864,
        "SD3neg": 14.6,
        "SD2neg": 15.9,
        "SD1neg": 17.3,
        "SD0": 18.9,
        "SD1": 20.7,
        "SD2": 22.7,
        "SD3": 25
       },
       {
        "Length_height": 111.5,
        "L": -0.3521,
        "M": 19.059,
        "S": 0.08896,
        "SD3neg": 14.8,
        "SD2neg": 16,
        "SD1neg": 17.5,
        "SD0": 19.1,
        "SD1": 20.9,
        "SD2": 22.9,
        "SD3": 25.2
       },
       {
        "Length_height": 112,
        "L": -0.3521,
        "M": 19.2439,
        "S": 0.08928,
        "SD3neg": 14.9,
        "SD2neg": 16.2,
        "SD1neg": 17.6,
        "SD0": 19.2,
        "SD1": 21.1,
        "SD2": 23.1,
        "SD3": 25.5
       },
       {
        "Length_height": 112.5,
        "L": -0.3521,
        "M": 19.4304,
        "S": 0.0896,
        "SD3neg": 15,
        "SD2neg": 16.3,
        "SD1neg": 17.8,
        "SD0": 19.4,
        "SD1": 21.3,
        "SD2": 23.4,
        "SD3": 25.8
       },
       {
        "Length_height": 113,
        "L": -0.3521,
        "M": 19.6185,
        "S": 0.08991,
        "SD3neg": 15.2,
        "SD2neg": 16.5,
        "SD1neg": 18,
        "SD0": 19.6,
        "SD1": 21.5,
        "SD2": 23.6,
        "SD3": 26
       },
       {
        "Length_height": 113.5,
        "L": -0.3521,
        "M": 19.8081,
        "S": 0.09022,
        "SD3neg": 15.3,
        "SD2neg": 16.6,
        "SD1neg": 18.1,
        "SD0": 19.8,
        "SD1": 21.7,
        "SD2": 23.9,
        "SD3": 26.3
       },
       {
        "Length_height": 114,
        "L": -0.3521,
        "M": 19.999,
        "S": 0.09054,
        "SD3neg": 15.4,
        "SD2neg": 16.8,
        "SD1neg": 18.3,
        "SD0": 20,
        "SD1": 21.9,
        "SD2": 24.1,
        "SD3": 26.6
       },
       {
        "Length_height": 114.5,
        "L": -0.3521,
        "M": 20.1912,
        "S": 0.09085,
        "SD3neg": 15.6,
        "SD2neg": 16.9,
        "SD1neg": 18.5,
        "SD0": 20.2,
        "SD1": 22.1,
        "SD2": 24.4,
        "SD3": 26.9
       },
       {
        "Length_height": 115,
        "L": -0.3521,
        "M": 20.3846,
        "S": 0.09116,
        "SD3neg": 15.7,
        "SD2neg": 17.1,
        "SD1neg": 18.6,
        "SD0": 20.4,
        "SD1": 22.4,
        "SD2": 24.6,
        "SD3": 27.2
       },
       {
        "Length_height": 115.5,
        "L": -0.3521,
        "M": 20.5789,
        "S": 0.09147,
        "SD3neg": 15.8,
        "SD2neg": 17.2,
        "SD1neg": 18.8,
        "SD0": 20.6,
        "SD1": 22.6,
        "SD2": 24.9,
        "SD3": 27.5
       },
       {
        "Length_height": 116,
        "L": -0.3521,
        "M": 20.7741,
        "S": 0.09177,
        "SD3neg": 16,
        "SD2neg": 17.4,
        "SD1neg": 19,
        "SD0": 20.8,
        "SD1": 22.8,
        "SD2": 25.1,
        "SD3": 27.8
       },
       {
        "Length_height": 116.5,
        "L": -0.3521,
        "M": 20.97,
        "S": 0.09208,
        "SD3neg": 16.1,
        "SD2neg": 17.5,
        "SD1neg": 19.2,
        "SD0": 21,
        "SD1": 23,
        "SD2": 25.4,
        "SD3": 28
       },
       {
        "Length_height": 117,
        "L": -0.3521,
        "M": 21.1666,
        "S": 0.09239,
        "SD3neg": 16.2,
        "SD2neg": 17.7,
        "SD1neg": 19.3,
        "SD0": 21.2,
        "SD1": 23.3,
        "SD2": 25.6,
        "SD3": 28.3
       },
       {
        "Length_height": 117.5,
        "L": -0.3521,
        "M": 21.3636,
        "S": 0.0927,
        "SD3neg": 16.4,
        "SD2neg": 17.9,
        "SD1neg": 19.5,
        "SD0": 21.4,
        "SD1": 23.5,
        "SD2": 25.9,
        "SD3": 28.6
       },
       {
        "Length_height": 118,
        "L": -0.3521,
        "M": 21.5611,
        "S": 0.093,
        "SD3neg": 16.5,
        "SD2neg": 18,
        "SD1neg": 19.7,
        "SD0": 21.6,
        "SD1": 23.7,
        "SD2": 26.1,
        "SD3": 28.9
       },
       {
        "Length_height": 118.5,
        "L": -0.3521,
        "M": 21.7588,
        "S": 0.09331,
        "SD3neg": 16.7,
        "SD2neg": 18.2,
        "SD1neg": 19.9,
        "SD0": 21.8,
        "SD1": 23.9,
        "SD2": 26.4,
        "SD3": 29.2
       },
       {
        "Length_height": 119,
        "L": -0.3521,
        "M": 21.9568,
        "S": 0.09362,
        "SD3neg": 16.8,
        "SD2neg": 18.3,
        "SD1neg": 20,
        "SD0": 22,
        "SD1": 24.1,
        "SD2": 26.6,
        "SD3": 29.5
       },
       {
        "Length_height": 119.5,
        "L": -0.3521,
        "M": 22.1549,
        "S": 0.09393,
        "SD3neg": 16.9,
        "SD2neg": 18.5,
        "SD1neg": 20.2,
        "SD0": 22.2,
        "SD1": 24.4,
        "SD2": 26.9,
        "SD3": 29.8
       },
       {
        "Length_height": 120,
        "L": -0.3521,
        "M": 22.353,
        "S": 0.09424,
        "SD3neg": 17.1,
        "SD2neg": 18.6,
        "SD1neg": 20.4,
        "SD0": 22.4,
        "SD1": 24.6,
        "SD2": 27.2,
        "SD3": 30.1
       }
]