// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-container {
    height: 100%;
    overflow: hidden;
}

.dashboard-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.detail-row {
    display: flex;
    justify-content: space-between;
}

.detail {
    display: flex;
    width: -webkit-fill-available;
    align-items: center;
}

.detail h4 {
    padding-right: 10px;
}

.content-section {
    flex-grow: 100;
    overflow: auto;
}

.table-row {
    background: #F2F2F2;
}

.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
    font-weight: bold !important;
}

.MuiDataGrid-cellContent {
    font-size: 0.8em !important;
    font-weight: bold !important;
}

.pink-row {
    background-color: #FAE8ED;
}

.grey-row {
    background-color: #D9D9D9;
}

.image-row {
    background-color: #f8f8f8;
}

.purple-row {
    background-color: #f0ceff;
}

.table-header {
    background-color: #FAE8ED;
    font-weight: bold;
}

.white {
    background-color: white;
}

.milestone-table-header {
    font-weight: bold !important;
    font-size: 120% !important;
}
`, "",{"version":3,"sources":["webpack://./src/routes/developmentMilestones/styles.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,cAAc;AAClB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,2BAA2B;IAC3B,4BAA4B;AAChC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,iBAAiB;AACrB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,4BAA4B;IAC5B,0BAA0B;AAC9B","sourcesContent":[".dashboard-container {\n    height: 100%;\n    overflow: hidden;\n}\n\n.dashboard-wrapper {\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    overflow: auto;\n}\n\n.detail-row {\n    display: flex;\n    justify-content: space-between;\n}\n\n.detail {\n    display: flex;\n    width: -webkit-fill-available;\n    align-items: center;\n}\n\n.detail h4 {\n    padding-right: 10px;\n}\n\n.content-section {\n    flex-grow: 100;\n    overflow: auto;\n}\n\n.table-row {\n    background: #F2F2F2;\n}\n\n.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {\n    font-weight: bold !important;\n}\n\n.MuiDataGrid-cellContent {\n    font-size: 0.8em !important;\n    font-weight: bold !important;\n}\n\n.pink-row {\n    background-color: #FAE8ED;\n}\n\n.grey-row {\n    background-color: #D9D9D9;\n}\n\n.image-row {\n    background-color: #f8f8f8;\n}\n\n.purple-row {\n    background-color: #f0ceff;\n}\n\n.table-header {\n    background-color: #FAE8ED;\n    font-weight: bold;\n}\n\n.white {\n    background-color: white;\n}\n\n.milestone-table-header {\n    font-weight: bold !important;\n    font-size: 120% !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
