export const lhfa_g_0_to_2_years_zscores = [
    {
        "Month": 0,
        "SD0": 49.1,
        "SD1": 51.0,
        "SD2": 52.9,
        "SD3": 54.7,
        "SD1neg": 47.3,
        "SD2neg": 45.4,
        "SD3neg": 43.6
    },
    {
        "Month": 1,
        "SD0": 53.7,
        "SD1": 55.6,
        "SD2": 57.6,
        "SD3": 59.5,
        "SD1neg": 51.7,
        "SD2neg": 49.8,
        "SD3neg": 47.8
    },
    {
        "Month": 2,
        "SD0": 57.1,
        "SD1": 59.1,
        "SD2": 61.1,
        "SD3": 63.2,
        "SD1neg": 55.0,
        "SD2neg": 53.0,
        "SD3neg": 51.0
    },
    {
        "Month": 3,
        "SD0": 59.8,
        "SD1": 61.9,
        "SD2": 64.0,
        "SD3": 66.1,
        "SD1neg": 57.7,
        "SD2neg": 55.6,
        "SD3neg": 53.5
    },
    {
        "Month": 4,
        "SD0": 62.1,
        "SD1": 64.3,
        "SD2": 66.4,
        "SD3": 68.6,
        "SD1neg": 59.9,
        "SD2neg": 57.8,
        "SD3neg": 55.6
    },
    {
        "Month": 5,
        "SD0": 64.0,
        "SD1": 66.2,
        "SD2": 68.5,
        "SD3": 70.7,
        "SD1neg": 61.8,
        "SD2neg": 59.6,
        "SD3neg": 57.4
    },
    {
        "Month": 6,
        "SD0": 65.7,
        "SD1": 68.0,
        "SD2": 70.3,
        "SD3": 72.5,
        "SD1neg": 63.5,
        "SD2neg": 61.2,
        "SD3neg": 58.9
    },
    {
        "Month": 7,
        "SD0": 67.3,
        "SD1": 69.6,
        "SD2": 71.9,
        "SD3": 74.2,
        "SD1neg": 65.0,
        "SD2neg": 62.7,
        "SD3neg": 60.3
    },
    {
        "Month": 8,
        "SD0": 68.7,
        "SD1": 71.1,
        "SD2": 73.5,
        "SD3": 75.8,
        "SD1neg": 66.4,
        "SD2neg": 64.0,
        "SD3neg": 61.7
    },
    {
        "Month": 9,
        "SD0": 70.1,
        "SD1": 72.6,
        "SD2": 75.0,
        "SD3": 77.4,
        "SD1neg": 67.7,
        "SD2neg": 65.3,
        "SD3neg": 62.9
    },
    {
        "Month": 10,
        "SD0": 71.5,
        "SD1": 73.9,
        "SD2": 76.4,
        "SD3": 78.9,
        "SD1neg": 69.0,
        "SD2neg": 66.5,
        "SD3neg": 64.1
    },
    {
        "Month": 11,
        "SD0": 72.8,
        "SD1": 75.3,
        "SD2": 77.8,
        "SD3": 80.3,
        "SD1neg": 70.3,
        "SD2neg": 67.7,
        "SD3neg": 65.2
    },
    {
        "Month": 12,
        "SD0": 74.0,
        "SD1": 76.6,
        "SD2": 79.2,
        "SD3": 81.7,
        "SD1neg": 71.4,
        "SD2neg": 68.9,
        "SD3neg": 66.3
    },
    {
        "Month": 13,
        "SD0": 75.2,
        "SD1": 77.8,
        "SD2": 80.5,
        "SD3": 83.1,
        "SD1neg": 72.6,
        "SD2neg": 70.0,
        "SD3neg": 67.3
    },
    {
        "Month": 14,
        "SD0": 76.4,
        "SD1": 79.1,
        "SD2": 81.7,
        "SD3": 84.4,
        "SD1neg": 73.7,
        "SD2neg": 71.0,
        "SD3neg": 68.3
    },
    {
        "Month": 15,
        "SD0": 77.5,
        "SD1": 80.2,
        "SD2": 83.0,
        "SD3": 85.7,
        "SD1neg": 74.8,
        "SD2neg": 72.0,
        "SD3neg": 69.3
    },
    {
        "Month": 16,
        "SD0": 78.6,
        "SD1": 81.4,
        "SD2": 84.2,
        "SD3": 87.0,
        "SD1neg": 75.8,
        "SD2neg": 73.0,
        "SD3neg": 70.2
    },
    {
        "Month": 17,
        "SD0": 79.7,
        "SD1": 82.5,
        "SD2": 85.4,
        "SD3": 88.2,
        "SD1neg": 76.8,
        "SD2neg": 74.0,
        "SD3neg": 71.1
    },
    {
        "Month": 18,
        "SD0": 80.7,
        "SD1": 83.6,
        "SD2": 86.5,
        "SD3": 89.4,
        "SD1neg": 77.8,
        "SD2neg": 74.9,
        "SD3neg": 72.0
    },
    {
        "Month": 19,
        "SD0": 81.7,
        "SD1": 84.7,
        "SD2": 87.6,
        "SD3": 90.6,
        "SD1neg": 78.8,
        "SD2neg": 75.8,
        "SD3neg": 72.8
    },
    {
        "Month": 20,
        "SD0": 82.7,
        "SD1": 85.7,
        "SD2": 88.7,
        "SD3": 91.7,
        "SD1neg": 79.7,
        "SD2neg": 76.7,
        "SD3neg": 73.7
    },
    {
        "Month": 21,
        "SD0": 83.7,
        "SD1": 86.7,
        "SD2": 89.8,
        "SD3": 92.9,
        "SD1neg": 80.6,
        "SD2neg": 77.5,
        "SD3neg": 74.5
    },
    {
        "Month": 22,
        "SD0": 84.6,
        "SD1": 87.7,
        "SD2": 90.8,
        "SD3": 94.0,
        "SD1neg": 81.5,
        "SD2neg": 78.4,
        "SD3neg": 75.2
    },
    {
        "Month": 23,
        "SD0": 85.5,
        "SD1": 88.7,
        "SD2": 91.9,
        "SD3": 95.0,
        "SD1neg": 82.3,
        "SD2neg": 79.2,
        "SD3neg": 76.0
    },
    {
        "Month": 24,
        "SD0": 86.4,
        "SD1": 89.6,
        "SD2": 92.9,
        "SD3": 96.1,
        "SD1neg": 83.2,
        "SD2neg": 80.0,
        "SD3neg": 76.7
    }
]