// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-appointment {
    padding: 3%;
}

.appointment-body {
    display: flex;
    flex-grow: 100;
    overflow-y: auto;
    margin: 3%;
}

.appointment-header {
    margin: 0;
    margin-bottom: 10px;
}

.appointment-rows {
    display: flex;
    flex-direction: column;
}

.appointment-row {
    background-color: #F2F2F2;
    padding: 10px 5px;
    margin: 2px 0;
    cursor: pointer;
}

.appointment-row-selected {
    background-color: #7A918D;
    color: #F2F2F2;
    padding: 10px 5px;
    margin: 2px 0;
    cursor: pointer;
}

.appointment-row:hover {
    background-color: #7A918D;
    color: #F2F2F2;
    cursor: pointer;
}

.light-font {
    font-size: 0.8em;
    padding: 0;
}

.bold-font {
    font-size: 1em;
    font-weight: bold;
    padding: 0;
}

.btn {
    background-color: #7A918D;
    color: #F2F2F2;
    width: 100%;
    padding: 0.8em;
    cursor: pointer;
    border: none;
}

.btn:hover {
    color: #7A918D;
    background-color: #F2F2F2;
}`, "",{"version":3,"sources":["webpack://./src/routes/appointments/styles.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,cAAc;IACd,gBAAgB;IAChB,UAAU;AACd;;AAEA;IACI,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;IACzB,iBAAiB;IACjB,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,iBAAiB;IACjB,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,UAAU;AACd;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,UAAU;AACd;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,WAAW;IACX,cAAc;IACd,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,yBAAyB;AAC7B","sourcesContent":[".new-appointment {\n    padding: 3%;\n}\n\n.appointment-body {\n    display: flex;\n    flex-grow: 100;\n    overflow-y: auto;\n    margin: 3%;\n}\n\n.appointment-header {\n    margin: 0;\n    margin-bottom: 10px;\n}\n\n.appointment-rows {\n    display: flex;\n    flex-direction: column;\n}\n\n.appointment-row {\n    background-color: #F2F2F2;\n    padding: 10px 5px;\n    margin: 2px 0;\n    cursor: pointer;\n}\n\n.appointment-row-selected {\n    background-color: #7A918D;\n    color: #F2F2F2;\n    padding: 10px 5px;\n    margin: 2px 0;\n    cursor: pointer;\n}\n\n.appointment-row:hover {\n    background-color: #7A918D;\n    color: #F2F2F2;\n    cursor: pointer;\n}\n\n.light-font {\n    font-size: 0.8em;\n    padding: 0;\n}\n\n.bold-font {\n    font-size: 1em;\n    font-weight: bold;\n    padding: 0;\n}\n\n.btn {\n    background-color: #7A918D;\n    color: #F2F2F2;\n    width: 100%;\n    padding: 0.8em;\n    cursor: pointer;\n    border: none;\n}\n\n.btn:hover {\n    color: #7A918D;\n    background-color: #F2F2F2;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
