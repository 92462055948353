export const wflh_g_0_to_2_years_zscores = [
    {
        "Length": 45,
        "L": -0.3833,
        "M": 2.4607,
        "S": 0.09029,
        "SD3neg": 1.9,
        "SD2neg": 2.1,
        "SD1neg": 2.3,
        "SD0": 2.5,
        "SD1": 2.7,
        "SD2": 3,
        "SD3": 3.3
       },
       {
        "Length": 45.5,
        "L": -0.3833,
        "M": 2.5457,
        "S": 0.09033,
        "SD3neg": 2,
        "SD2neg": 2.1,
        "SD1neg": 2.3,
        "SD0": 2.5,
        "SD1": 2.8,
        "SD2": 3.1,
        "SD3": 3.4
       },
       {
        "Length": 46,
        "L": -0.3833,
        "M": 2.6306,
        "S": 0.09037,
        "SD3neg": 2,
        "SD2neg": 2.2,
        "SD1neg": 2.4,
        "SD0": 2.6,
        "SD1": 2.9,
        "SD2": 3.2,
        "SD3": 3.5
       },
       {
        "Length": 46.5,
        "L": -0.3833,
        "M": 2.7155,
        "S": 0.0904,
        "SD3neg": 2.1,
        "SD2neg": 2.3,
        "SD1neg": 2.5,
        "SD0": 2.7,
        "SD1": 3,
        "SD2": 3.3,
        "SD3": 3.6
       },
       {
        "Length": 47,
        "L": -0.3833,
        "M": 2.8007,
        "S": 0.09044,
        "SD3neg": 2.2,
        "SD2neg": 2.4,
        "SD1neg": 2.6,
        "SD0": 2.8,
        "SD1": 3.1,
        "SD2": 3.4,
        "SD3": 3.7
       },
       {
        "Length": 47.5,
        "L": -0.3833,
        "M": 2.8867,
        "S": 0.09048,
        "SD3neg": 2.2,
        "SD2neg": 2.4,
        "SD1neg": 2.6,
        "SD0": 2.9,
        "SD1": 3.2,
        "SD2": 3.5,
        "SD3": 3.8
       },
       {
        "Length": 48,
        "L": -0.3833,
        "M": 2.9741,
        "S": 0.09052,
        "SD3neg": 2.3,
        "SD2neg": 2.5,
        "SD1neg": 2.7,
        "SD0": 3,
        "SD1": 3.3,
        "SD2": 3.6,
        "SD3": 4
       },
       {
        "Length": 48.5,
        "L": -0.3833,
        "M": 3.0636,
        "S": 0.09056,
        "SD3neg": 2.4,
        "SD2neg": 2.6,
        "SD1neg": 2.8,
        "SD0": 3.1,
        "SD1": 3.4,
        "SD2": 3.7,
        "SD3": 4.1
       },
       {
        "Length": 49,
        "L": -0.3833,
        "M": 3.156,
        "S": 0.0906,
        "SD3neg": 2.4,
        "SD2neg": 2.6,
        "SD1neg": 2.9,
        "SD0": 3.2,
        "SD1": 3.5,
        "SD2": 3.8,
        "SD3": 4.2
       },
       {
        "Length": 49.5,
        "L": -0.3833,
        "M": 3.252,
        "S": 0.09064,
        "SD3neg": 2.5,
        "SD2neg": 2.7,
        "SD1neg": 3,
        "SD0": 3.3,
        "SD1": 3.6,
        "SD2": 3.9,
        "SD3": 4.3
       },
       {
        "Length": 50,
        "L": -0.3833,
        "M": 3.3518,
        "S": 0.09068,
        "SD3neg": 2.6,
        "SD2neg": 2.8,
        "SD1neg": 3.1,
        "SD0": 3.4,
        "SD1": 3.7,
        "SD2": 4,
        "SD3": 4.5
       },
       {
        "Length": 50.5,
        "L": -0.3833,
        "M": 3.4557,
        "S": 0.09072,
        "SD3neg": 2.7,
        "SD2neg": 2.9,
        "SD1neg": 3.2,
        "SD0": 3.5,
        "SD1": 3.8,
        "SD2": 4.2,
        "SD3": 4.6
       },
       {
        "Length": 51,
        "L": -0.3833,
        "M": 3.5636,
        "S": 0.09076,
        "SD3neg": 2.8,
        "SD2neg": 3,
        "SD1neg": 3.3,
        "SD0": 3.6,
        "SD1": 3.9,
        "SD2": 4.3,
        "SD3": 4.8
       },
       {
        "Length": 51.5,
        "L": -0.3833,
        "M": 3.6754,
        "S": 0.0908,
        "SD3neg": 2.8,
        "SD2neg": 3.1,
        "SD1neg": 3.4,
        "SD0": 3.7,
        "SD1": 4,
        "SD2": 4.4,
        "SD3": 4.9
       },
       {
        "Length": 52,
        "L": -0.3833,
        "M": 3.7911,
        "S": 0.09085,
        "SD3neg": 2.9,
        "SD2neg": 3.2,
        "SD1neg": 3.5,
        "SD0": 3.8,
        "SD1": 4.2,
        "SD2": 4.6,
        "SD3": 5.1
       },
       {
        "Length": 52.5,
        "L": -0.3833,
        "M": 3.9105,
        "S": 0.09089,
        "SD3neg": 3,
        "SD2neg": 3.3,
        "SD1neg": 3.6,
        "SD0": 3.9,
        "SD1": 4.3,
        "SD2": 4.7,
        "SD3": 5.2
       },
       {
        "Length": 53,
        "L": -0.3833,
        "M": 4.0332,
        "S": 0.09093,
        "SD3neg": 3.1,
        "SD2neg": 3.4,
        "SD1neg": 3.7,
        "SD0": 4,
        "SD1": 4.4,
        "SD2": 4.9,
        "SD3": 5.4
       },
       {
        "Length": 53.5,
        "L": -0.3833,
        "M": 4.1591,
        "S": 0.09098,
        "SD3neg": 3.2,
        "SD2neg": 3.5,
        "SD1neg": 3.8,
        "SD0": 4.2,
        "SD1": 4.6,
        "SD2": 5,
        "SD3": 5.5
       },
       {
        "Length": 54,
        "L": -0.3833,
        "M": 4.2875,
        "S": 0.09102,
        "SD3neg": 3.3,
        "SD2neg": 3.6,
        "SD1neg": 3.9,
        "SD0": 4.3,
        "SD1": 4.7,
        "SD2": 5.2,
        "SD3": 5.7
       },
       {
        "Length": 54.5,
        "L": -0.3833,
        "M": 4.4179,
        "S": 0.09106,
        "SD3neg": 3.4,
        "SD2neg": 3.7,
        "SD1neg": 4,
        "SD0": 4.4,
        "SD1": 4.8,
        "SD2": 5.3,
        "SD3": 5.9
       },
       {
        "Length": 55,
        "L": -0.3833,
        "M": 4.5498,
        "S": 0.0911,
        "SD3neg": 3.5,
        "SD2neg": 3.8,
        "SD1neg": 4.2,
        "SD0": 4.5,
        "SD1": 5,
        "SD2": 5.5,
        "SD3": 6.1
       },
       {
        "Length": 55.5,
        "L": -0.3833,
        "M": 4.6827,
        "S": 0.09114,
        "SD3neg": 3.6,
        "SD2neg": 3.9,
        "SD1neg": 4.3,
        "SD0": 4.7,
        "SD1": 5.1,
        "SD2": 5.7,
        "SD3": 6.3
       },
       {
        "Length": 56,
        "L": -0.3833,
        "M": 4.8162,
        "S": 0.09118,
        "SD3neg": 3.7,
        "SD2neg": 4,
        "SD1neg": 4.4,
        "SD0": 4.8,
        "SD1": 5.3,
        "SD2": 5.8,
        "SD3": 6.4
       },
       {
        "Length": 56.5,
        "L": -0.3833,
        "M": 4.95,
        "S": 0.09121,
        "SD3neg": 3.8,
        "SD2neg": 4.1,
        "SD1neg": 4.5,
        "SD0": 5,
        "SD1": 5.4,
        "SD2": 6,
        "SD3": 6.6
       },
       {
        "Length": 57,
        "L": -0.3833,
        "M": 5.0837,
        "S": 0.09125,
        "SD3neg": 3.9,
        "SD2neg": 4.3,
        "SD1neg": 4.6,
        "SD0": 5.1,
        "SD1": 5.6,
        "SD2": 6.1,
        "SD3": 6.8
       },
       {
        "Length": 57.5,
        "L": -0.3833,
        "M": 5.2173,
        "S": 0.09128,
        "SD3neg": 4,
        "SD2neg": 4.4,
        "SD1neg": 4.8,
        "SD0": 5.2,
        "SD1": 5.7,
        "SD2": 6.3,
        "SD3": 7
       },
       {
        "Length": 58,
        "L": -0.3833,
        "M": 5.3507,
        "S": 0.0913,
        "SD3neg": 4.1,
        "SD2neg": 4.5,
        "SD1neg": 4.9,
        "SD0": 5.4,
        "SD1": 5.9,
        "SD2": 6.5,
        "SD3": 7.1
       },
       {
        "Length": 58.5,
        "L": -0.3833,
        "M": 5.4834,
        "S": 0.09132,
        "SD3neg": 4.2,
        "SD2neg": 4.6,
        "SD1neg": 5,
        "SD0": 5.5,
        "SD1": 6,
        "SD2": 6.6,
        "SD3": 7.3
       },
       {
        "Length": 59,
        "L": -0.3833,
        "M": 5.6151,
        "S": 0.09134,
        "SD3neg": 4.3,
        "SD2neg": 4.7,
        "SD1neg": 5.1,
        "SD0": 5.6,
        "SD1": 6.2,
        "SD2": 6.8,
        "SD3": 7.5
       },
       {
        "Length": 59.5,
        "L": -0.3833,
        "M": 5.7454,
        "S": 0.09135,
        "SD3neg": 4.4,
        "SD2neg": 4.8,
        "SD1neg": 5.3,
        "SD0": 5.7,
        "SD1": 6.3,
        "SD2": 6.9,
        "SD3": 7.7
       },
       {
        "Length": 60,
        "L": -0.3833,
        "M": 5.8742,
        "S": 0.09136,
        "SD3neg": 4.5,
        "SD2neg": 4.9,
        "SD1neg": 5.4,
        "SD0": 5.9,
        "SD1": 6.4,
        "SD2": 7.1,
        "SD3": 7.8
       },
       {
        "Length": 60.5,
        "L": -0.3833,
        "M": 6.0014,
        "S": 0.09137,
        "SD3neg": 4.6,
        "SD2neg": 5,
        "SD1neg": 5.5,
        "SD0": 6,
        "SD1": 6.6,
        "SD2": 7.3,
        "SD3": 8
       },
       {
        "Length": 61,
        "L": -0.3833,
        "M": 6.127,
        "S": 0.09137,
        "SD3neg": 4.7,
        "SD2neg": 5.1,
        "SD1neg": 5.6,
        "SD0": 6.1,
        "SD1": 6.7,
        "SD2": 7.4,
        "SD3": 8.2
       },
       {
        "Length": 61.5,
        "L": -0.3833,
        "M": 6.2511,
        "S": 0.09136,
        "SD3neg": 4.8,
        "SD2neg": 5.2,
        "SD1neg": 5.7,
        "SD0": 6.3,
        "SD1": 6.9,
        "SD2": 7.6,
        "SD3": 8.4
       },
       {
        "Length": 62,
        "L": -0.3833,
        "M": 6.3738,
        "S": 0.09135,
        "SD3neg": 4.9,
        "SD2neg": 5.3,
        "SD1neg": 5.8,
        "SD0": 6.4,
        "SD1": 7,
        "SD2": 7.7,
        "SD3": 8.5
       },
       {
        "Length": 62.5,
        "L": -0.3833,
        "M": 6.4948,
        "S": 0.09133,
        "SD3neg": 5,
        "SD2neg": 5.4,
        "SD1neg": 5.9,
        "SD0": 6.5,
        "SD1": 7.1,
        "SD2": 7.8,
        "SD3": 8.7
       },
       {
        "Length": 63,
        "L": -0.3833,
        "M": 6.6144,
        "S": 0.09131,
        "SD3neg": 5.1,
        "SD2neg": 5.5,
        "SD1neg": 6,
        "SD0": 6.6,
        "SD1": 7.3,
        "SD2": 8,
        "SD3": 8.8
       },
       {
        "Length": 63.5,
        "L": -0.3833,
        "M": 6.7328,
        "S": 0.09129,
        "SD3neg": 5.2,
        "SD2neg": 5.6,
        "SD1neg": 6.2,
        "SD0": 6.7,
        "SD1": 7.4,
        "SD2": 8.1,
        "SD3": 9
       },
       {
        "Length": 64,
        "L": -0.3833,
        "M": 6.8501,
        "S": 0.09126,
        "SD3neg": 5.3,
        "SD2neg": 5.7,
        "SD1neg": 6.3,
        "SD0": 6.9,
        "SD1": 7.5,
        "SD2": 8.3,
        "SD3": 9.1
       },
       {
        "Length": 64.5,
        "L": -0.3833,
        "M": 6.9662,
        "S": 0.09123,
        "SD3neg": 5.4,
        "SD2neg": 5.8,
        "SD1neg": 6.4,
        "SD0": 7,
        "SD1": 7.6,
        "SD2": 8.4,
        "SD3": 9.3
       },
       {
        "Length": 65,
        "L": -0.3833,
        "M": 7.0812,
        "S": 0.09119,
        "SD3neg": 5.5,
        "SD2neg": 5.9,
        "SD1neg": 6.5,
        "SD0": 7.1,
        "SD1": 7.8,
        "SD2": 8.6,
        "SD3": 9.5
       },
       {
        "Length": 65.5,
        "L": -0.3833,
        "M": 7.195,
        "S": 0.09115,
        "SD3neg": 5.5,
        "SD2neg": 6,
        "SD1neg": 6.6,
        "SD0": 7.2,
        "SD1": 7.9,
        "SD2": 8.7,
        "SD3": 9.6
       },
       {
        "Length": 66,
        "L": -0.3833,
        "M": 7.3076,
        "S": 0.0911,
        "SD3neg": 5.6,
        "SD2neg": 6.1,
        "SD1neg": 6.7,
        "SD0": 7.3,
        "SD1": 8,
        "SD2": 8.8,
        "SD3": 9.8
       },
       {
        "Length": 66.5,
        "L": -0.3833,
        "M": 7.4189,
        "S": 0.09106,
        "SD3neg": 5.7,
        "SD2neg": 6.2,
        "SD1neg": 6.8,
        "SD0": 7.4,
        "SD1": 8.1,
        "SD2": 9,
        "SD3": 9.9
       },
       {
        "Length": 67,
        "L": -0.3833,
        "M": 7.5288,
        "S": 0.09101,
        "SD3neg": 5.8,
        "SD2neg": 6.3,
        "SD1neg": 6.9,
        "SD0": 7.5,
        "SD1": 8.3,
        "SD2": 9.1,
        "SD3": 10
       },
       {
        "Length": 67.5,
        "L": -0.3833,
        "M": 7.6375,
        "S": 0.09096,
        "SD3neg": 5.9,
        "SD2neg": 6.4,
        "SD1neg": 7,
        "SD0": 7.6,
        "SD1": 8.4,
        "SD2": 9.2,
        "SD3": 10.2
       },
       {
        "Length": 68,
        "L": -0.3833,
        "M": 7.7448,
        "S": 0.0909,
        "SD3neg": 6,
        "SD2neg": 6.5,
        "SD1neg": 7.1,
        "SD0": 7.7,
        "SD1": 8.5,
        "SD2": 9.4,
        "SD3": 10.3
       },
       {
        "Length": 68.5,
        "L": -0.3833,
        "M": 7.8509,
        "S": 0.09085,
        "SD3neg": 6.1,
        "SD2neg": 6.6,
        "SD1neg": 7.2,
        "SD0": 7.9,
        "SD1": 8.6,
        "SD2": 9.5,
        "SD3": 10.5
       },
       {
        "Length": 69,
        "L": -0.3833,
        "M": 7.9559,
        "S": 0.09079,
        "SD3neg": 6.1,
        "SD2neg": 6.7,
        "SD1neg": 7.3,
        "SD0": 8,
        "SD1": 8.7,
        "SD2": 9.6,
        "SD3": 10.6
       },
       {
        "Length": 69.5,
        "L": -0.3833,
        "M": 8.0599,
        "S": 0.09074,
        "SD3neg": 6.2,
        "SD2neg": 6.8,
        "SD1neg": 7.4,
        "SD0": 8.1,
        "SD1": 8.8,
        "SD2": 9.7,
        "SD3": 10.7
       },
       {
        "Length": 70,
        "L": -0.3833,
        "M": 8.163,
        "S": 0.09068,
        "SD3neg": 6.3,
        "SD2neg": 6.9,
        "SD1neg": 7.5,
        "SD0": 8.2,
        "SD1": 9,
        "SD2": 9.9,
        "SD3": 10.9
       },
       {
        "Length": 70.5,
        "L": -0.3833,
        "M": 8.2651,
        "S": 0.09062,
        "SD3neg": 6.4,
        "SD2neg": 6.9,
        "SD1neg": 7.6,
        "SD0": 8.3,
        "SD1": 9.1,
        "SD2": 10,
        "SD3": 11
       },
       {
        "Length": 71,
        "L": -0.3833,
        "M": 8.3666,
        "S": 0.09056,
        "SD3neg": 6.5,
        "SD2neg": 7,
        "SD1neg": 7.7,
        "SD0": 8.4,
        "SD1": 9.2,
        "SD2": 10.1,
        "SD3": 11.1
       },
       {
        "Length": 71.5,
        "L": -0.3833,
        "M": 8.4676,
        "S": 0.0905,
        "SD3neg": 6.5,
        "SD2neg": 7.1,
        "SD1neg": 7.7,
        "SD0": 8.5,
        "SD1": 9.3,
        "SD2": 10.2,
        "SD3": 11.3
       },
       {
        "Length": 72,
        "L": -0.3833,
        "M": 8.5679,
        "S": 0.09043,
        "SD3neg": 6.6,
        "SD2neg": 7.2,
        "SD1neg": 7.8,
        "SD0": 8.6,
        "SD1": 9.4,
        "SD2": 10.3,
        "SD3": 11.4
       },
       {
        "Length": 72.5,
        "L": -0.3833,
        "M": 8.6674,
        "S": 0.09037,
        "SD3neg": 6.7,
        "SD2neg": 7.3,
        "SD1neg": 7.9,
        "SD0": 8.7,
        "SD1": 9.5,
        "SD2": 10.5,
        "SD3": 11.5
       },
       {
        "Length": 73,
        "L": -0.3833,
        "M": 8.7661,
        "S": 0.09031,
        "SD3neg": 6.8,
        "SD2neg": 7.4,
        "SD1neg": 8,
        "SD0": 8.8,
        "SD1": 9.6,
        "SD2": 10.6,
        "SD3": 11.7
       },
       {
        "Length": 73.5,
        "L": -0.3833,
        "M": 8.8638,
        "S": 0.09025,
        "SD3neg": 6.9,
        "SD2neg": 7.4,
        "SD1neg": 8.1,
        "SD0": 8.9,
        "SD1": 9.7,
        "SD2": 10.7,
        "SD3": 11.8
       },
       {
        "Length": 74,
        "L": -0.3833,
        "M": 8.9601,
        "S": 0.09018,
        "SD3neg": 6.9,
        "SD2neg": 7.5,
        "SD1neg": 8.2,
        "SD0": 9,
        "SD1": 9.8,
        "SD2": 10.8,
        "SD3": 11.9
       },
       {
        "Length": 74.5,
        "L": -0.3833,
        "M": 9.0552,
        "S": 0.09012,
        "SD3neg": 7,
        "SD2neg": 7.6,
        "SD1neg": 8.3,
        "SD0": 9.1,
        "SD1": 9.9,
        "SD2": 10.9,
        "SD3": 12
       },
       {
        "Length": 75,
        "L": -0.3833,
        "M": 9.149,
        "S": 0.09005,
        "SD3neg": 7.1,
        "SD2neg": 7.7,
        "SD1neg": 8.4,
        "SD0": 9.1,
        "SD1": 10,
        "SD2": 11,
        "SD3": 12.2
       },
       {
        "Length": 75.5,
        "L": -0.3833,
        "M": 9.2418,
        "S": 0.08999,
        "SD3neg": 7.1,
        "SD2neg": 7.8,
        "SD1neg": 8.5,
        "SD0": 9.2,
        "SD1": 10.1,
        "SD2": 11.1,
        "SD3": 12.3
       },
       {
        "Length": 76,
        "L": -0.3833,
        "M": 9.3337,
        "S": 0.08992,
        "SD3neg": 7.2,
        "SD2neg": 7.8,
        "SD1neg": 8.5,
        "SD0": 9.3,
        "SD1": 10.2,
        "SD2": 11.2,
        "SD3": 12.4
       },
       {
        "Length": 76.5,
        "L": -0.3833,
        "M": 9.4252,
        "S": 0.08985,
        "SD3neg": 7.3,
        "SD2neg": 7.9,
        "SD1neg": 8.6,
        "SD0": 9.4,
        "SD1": 10.3,
        "SD2": 11.4,
        "SD3": 12.5
       },
       {
        "Length": 77,
        "L": -0.3833,
        "M": 9.5166,
        "S": 0.08979,
        "SD3neg": 7.4,
        "SD2neg": 8,
        "SD1neg": 8.7,
        "SD0": 9.5,
        "SD1": 10.4,
        "SD2": 11.5,
        "SD3": 12.6
       },
       {
        "Length": 77.5,
        "L": -0.3833,
        "M": 9.6086,
        "S": 0.08972,
        "SD3neg": 7.4,
        "SD2neg": 8.1,
        "SD1neg": 8.8,
        "SD0": 9.6,
        "SD1": 10.5,
        "SD2": 11.6,
        "SD3": 12.8
       },
       {
        "Length": 78,
        "L": -0.3833,
        "M": 9.7015,
        "S": 0.08965,
        "SD3neg": 7.5,
        "SD2neg": 8.2,
        "SD1neg": 8.9,
        "SD0": 9.7,
        "SD1": 10.6,
        "SD2": 11.7,
        "SD3": 12.9
       },
       {
        "Length": 78.5,
        "L": -0.3833,
        "M": 9.7957,
        "S": 0.08959,
        "SD3neg": 7.6,
        "SD2neg": 8.2,
        "SD1neg": 9,
        "SD0": 9.8,
        "SD1": 10.7,
        "SD2": 11.8,
        "SD3": 13
       },
       {
        "Length": 79,
        "L": -0.3833,
        "M": 9.8915,
        "S": 0.08952,
        "SD3neg": 7.7,
        "SD2neg": 8.3,
        "SD1neg": 9.1,
        "SD0": 9.9,
        "SD1": 10.8,
        "SD2": 11.9,
        "SD3": 13.1
       },
       {
        "Length": 79.5,
        "L": -0.3833,
        "M": 9.9892,
        "S": 0.08946,
        "SD3neg": 7.7,
        "SD2neg": 8.4,
        "SD1neg": 9.1,
        "SD0": 10,
        "SD1": 10.9,
        "SD2": 12,
        "SD3": 13.3
       },
       {
        "Length": 80,
        "L": -0.3833,
        "M": 10.0891,
        "S": 0.0894,
        "SD3neg": 7.8,
        "SD2neg": 8.5,
        "SD1neg": 9.2,
        "SD0": 10.1,
        "SD1": 11,
        "SD2": 12.1,
        "SD3": 13.4
       },
       {
        "Length": 80.5,
        "L": -0.3833,
        "M": 10.1916,
        "S": 0.08934,
        "SD3neg": 7.9,
        "SD2neg": 8.6,
        "SD1neg": 9.3,
        "SD0": 10.2,
        "SD1": 11.2,
        "SD2": 12.3,
        "SD3": 13.5
       },
       {
        "Length": 81,
        "L": -0.3833,
        "M": 10.2965,
        "S": 0.08928,
        "SD3neg": 8,
        "SD2neg": 8.7,
        "SD1neg": 9.4,
        "SD0": 10.3,
        "SD1": 11.3,
        "SD2": 12.4,
        "SD3": 13.7
       },
       {
        "Length": 81.5,
        "L": -0.3833,
        "M": 10.4041,
        "S": 0.08923,
        "SD3neg": 8.1,
        "SD2neg": 8.8,
        "SD1neg": 9.5,
        "SD0": 10.4,
        "SD1": 11.4,
        "SD2": 12.5,
        "SD3": 13.8
       },
       {
        "Length": 82,
        "L": -0.3833,
        "M": 10.514,
        "S": 0.08918,
        "SD3neg": 8.1,
        "SD2neg": 8.8,
        "SD1neg": 9.6,
        "SD0": 10.5,
        "SD1": 11.5,
        "SD2": 12.6,
        "SD3": 13.9
       },
       {
        "Length": 82.5,
        "L": -0.3833,
        "M": 10.6263,
        "S": 0.08914,
        "SD3neg": 8.2,
        "SD2neg": 8.9,
        "SD1neg": 9.7,
        "SD0": 10.6,
        "SD1": 11.6,
        "SD2": 12.8,
        "SD3": 14.1
       },
       {
        "Length": 83,
        "L": -0.3833,
        "M": 10.741,
        "S": 0.0891,
        "SD3neg": 8.3,
        "SD2neg": 9,
        "SD1neg": 9.8,
        "SD0": 10.7,
        "SD1": 11.8,
        "SD2": 12.9,
        "SD3": 14.2
       },
       {
        "Length": 83.5,
        "L": -0.3833,
        "M": 10.8578,
        "S": 0.08906,
        "SD3neg": 8.4,
        "SD2neg": 9.1,
        "SD1neg": 9.9,
        "SD0": 10.9,
        "SD1": 11.9,
        "SD2": 13.1,
        "SD3": 14.4
       },
       {
        "Length": 84,
        "L": -0.3833,
        "M": 10.9767,
        "S": 0.08903,
        "SD3neg": 8.5,
        "SD2neg": 9.2,
        "SD1neg": 10.1,
        "SD0": 11,
        "SD1": 12,
        "SD2": 13.2,
        "SD3": 14.5
       },
       {
        "Length": 84.5,
        "L": -0.3833,
        "M": 11.0974,
        "S": 0.089,
        "SD3neg": 8.6,
        "SD2neg": 9.3,
        "SD1neg": 10.2,
        "SD0": 11.1,
        "SD1": 12.1,
        "SD2": 13.3,
        "SD3": 14.7
       },
       {
        "Length": 85,
        "L": -0.3833,
        "M": 11.2198,
        "S": 0.08898,
        "SD3neg": 8.7,
        "SD2neg": 9.4,
        "SD1neg": 10.3,
        "SD0": 11.2,
        "SD1": 12.3,
        "SD2": 13.5,
        "SD3": 14.9
       },
       {
        "Length": 85.5,
        "L": -0.3833,
        "M": 11.3435,
        "S": 0.08897,
        "SD3neg": 8.8,
        "SD2neg": 9.5,
        "SD1neg": 10.4,
        "SD0": 11.3,
        "SD1": 12.4,
        "SD2": 13.6,
        "SD3": 15
       },
       {
        "Length": 86,
        "L": -0.3833,
        "M": 11.4684,
        "S": 0.08895,
        "SD3neg": 8.9,
        "SD2neg": 9.7,
        "SD1neg": 10.5,
        "SD0": 11.5,
        "SD1": 12.6,
        "SD2": 13.8,
        "SD3": 15.2
       },
       {
        "Length": 86.5,
        "L": -0.3833,
        "M": 11.594,
        "S": 0.08895,
        "SD3neg": 9,
        "SD2neg": 9.8,
        "SD1neg": 10.6,
        "SD0": 11.6,
        "SD1": 12.7,
        "SD2": 13.9,
        "SD3": 15.4
       },
       {
        "Length": 87,
        "L": -0.3833,
        "M": 11.7201,
        "S": 0.08895,
        "SD3neg": 9.1,
        "SD2neg": 9.9,
        "SD1neg": 10.7,
        "SD0": 11.7,
        "SD1": 12.8,
        "SD2": 14.1,
        "SD3": 15.5
       }
]