// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nearest-container {
    display: flex;
    flex-flow: row;
    height: 100%;
}

.nearest-side {
    flex: 1 1;
}

.left-container {
    display: flex;
    flex-direction: column;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/routes/help/components/nearest/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,SAAO;AACX;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;AACtB","sourcesContent":[".nearest-container {\n    display: flex;\n    flex-flow: row;\n    height: 100%;\n}\n\n.nearest-side {\n    flex: 1;\n}\n\n.left-container {\n    display: flex;\n    flex-direction: column;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
