export const wflh_b_2_to_5_years_zscores = [
    {
        "Length": 45,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 45.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 46,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 46.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 47,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 47.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 48,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 48.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 49,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 49.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 50,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 50.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 51,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 51.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 52,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 52.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 53,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 53.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 54,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 54.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 55,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 55.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 56,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 56.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 57,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 57.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 58,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 58.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 59,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 59.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 60,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 60.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 61,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 61.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 62,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 62.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 63,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 63.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 64,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 64.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 65,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 65.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 66,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 66.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 67,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 67.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 68,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 68.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 69,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 69.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 70,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 70.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 71,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 71.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 72,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 72.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 73,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 73.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 74,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 74.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 75,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 75.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 76,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 76.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 77,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 77.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 78,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 78.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 79,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 79.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 80,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 80.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 81,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 81.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 82,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 82.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 83,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 83.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 84,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 84.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 85,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 85.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 86,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 86.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Height": 87,
        "L": -0.3521,
        "M": 12.1645,
        "S": 0.0809,
        "SD3neg": 9.6,
        "SD2neg": 10.4,
        "SD1neg": 11.2,
        "SD0": 12.2,
        "SD1": 13.2,
        "SD2": 14.4,
        "SD3": 15.7
       },
       {
        "Height": 87.5,
        "L": -0.3521,
        "M": 12.2871,
        "S": 0.08076,
        "SD3neg": 9.7,
        "SD2neg": 10.5,
        "SD1neg": 11.3,
        "SD0": 12.3,
        "SD1": 13.3,
        "SD2": 14.5,
        "SD3": 15.8
       },
       {
        "Height": 88,
        "L": -0.3521,
        "M": 12.4089,
        "S": 0.08064,
        "SD3neg": 9.8,
        "SD2neg": 10.6,
        "SD1neg": 11.5,
        "SD0": 12.4,
        "SD1": 13.5,
        "SD2": 14.7,
        "SD3": 16
       },
       {
        "Height": 88.5,
        "L": -0.3521,
        "M": 12.5298,
        "S": 0.08054,
        "SD3neg": 9.9,
        "SD2neg": 10.7,
        "SD1neg": 11.6,
        "SD0": 12.5,
        "SD1": 13.6,
        "SD2": 14.8,
        "SD3": 16.1
       },
       {
        "Height": 89,
        "L": -0.3521,
        "M": 12.6495,
        "S": 0.08045,
        "SD3neg": 10,
        "SD2neg": 10.8,
        "SD1neg": 11.7,
        "SD0": 12.6,
        "SD1": 13.7,
        "SD2": 14.9,
        "SD3": 16.3
       },
       {
        "Height": 89.5,
        "L": -0.3521,
        "M": 12.7683,
        "S": 0.08038,
        "SD3neg": 10.1,
        "SD2neg": 10.9,
        "SD1neg": 11.8,
        "SD0": 12.8,
        "SD1": 13.9,
        "SD2": 15.1,
        "SD3": 16.4
       },
       {
        "Height": 90,
        "L": -0.3521,
        "M": 12.8864,
        "S": 0.08032,
        "SD3neg": 10.2,
        "SD2neg": 11,
        "SD1neg": 11.9,
        "SD0": 12.9,
        "SD1": 14,
        "SD2": 15.2,
        "SD3": 16.6
       },
       {
        "Height": 90.5,
        "L": -0.3521,
        "M": 13.0038,
        "S": 0.08028,
        "SD3neg": 10.3,
        "SD2neg": 11.1,
        "SD1neg": 12,
        "SD0": 13,
        "SD1": 14.1,
        "SD2": 15.3,
        "SD3": 16.7
       },
       {
        "Height": 91,
        "L": -0.3521,
        "M": 13.1209,
        "S": 0.08025,
        "SD3neg": 10.4,
        "SD2neg": 11.2,
        "SD1neg": 12.1,
        "SD0": 13.1,
        "SD1": 14.2,
        "SD2": 15.5,
        "SD3": 16.9
       },
       {
        "Height": 91.5,
        "L": -0.3521,
        "M": 13.2376,
        "S": 0.08024,
        "SD3neg": 10.5,
        "SD2neg": 11.3,
        "SD1neg": 12.2,
        "SD0": 13.2,
        "SD1": 14.4,
        "SD2": 15.6,
        "SD3": 17
       },
       {
        "Height": 92,
        "L": -0.3521,
        "M": 13.3541,
        "S": 0.08025,
        "SD3neg": 10.6,
        "SD2neg": 11.4,
        "SD1neg": 12.3,
        "SD0": 13.4,
        "SD1": 14.5,
        "SD2": 15.8,
        "SD3": 17.2
       },
       {
        "Height": 92.5,
        "L": -0.3521,
        "M": 13.4705,
        "S": 0.08027,
        "SD3neg": 10.7,
        "SD2neg": 11.5,
        "SD1neg": 12.4,
        "SD0": 13.5,
        "SD1": 14.6,
        "SD2": 15.9,
        "SD3": 17.3
       },
       {
        "Height": 93,
        "L": -0.3521,
        "M": 13.587,
        "S": 0.08031,
        "SD3neg": 10.8,
        "SD2neg": 11.6,
        "SD1neg": 12.6,
        "SD0": 13.6,
        "SD1": 14.7,
        "SD2": 16,
        "SD3": 17.5
       },
       {
        "Height": 93.5,
        "L": -0.3521,
        "M": 13.7041,
        "S": 0.08036,
        "SD3neg": 10.9,
        "SD2neg": 11.7,
        "SD1neg": 12.7,
        "SD0": 13.7,
        "SD1": 14.9,
        "SD2": 16.2,
        "SD3": 17.6
       },
       {
        "Height": 94,
        "L": -0.3521,
        "M": 13.8217,
        "S": 0.08043,
        "SD3neg": 11,
        "SD2neg": 11.8,
        "SD1neg": 12.8,
        "SD0": 13.8,
        "SD1": 15,
        "SD2": 16.3,
        "SD3": 17.8
       },
       {
        "Height": 94.5,
        "L": -0.3521,
        "M": 13.9403,
        "S": 0.08051,
        "SD3neg": 11.1,
        "SD2neg": 11.9,
        "SD1neg": 12.9,
        "SD0": 13.9,
        "SD1": 15.1,
        "SD2": 16.5,
        "SD3": 17.9
       },
       {
        "Height": 95,
        "L": -0.3521,
        "M": 14.06,
        "S": 0.0806,
        "SD3neg": 11.1,
        "SD2neg": 12,
        "SD1neg": 13,
        "SD0": 14.1,
        "SD1": 15.3,
        "SD2": 16.6,
        "SD3": 18.1
       },
       {
        "Height": 95.5,
        "L": -0.3521,
        "M": 14.1811,
        "S": 0.08071,
        "SD3neg": 11.2,
        "SD2neg": 12.1,
        "SD1neg": 13.1,
        "SD0": 14.2,
        "SD1": 15.4,
        "SD2": 16.7,
        "SD3": 18.3
       },
       {
        "Height": 96,
        "L": -0.3521,
        "M": 14.3037,
        "S": 0.08083,
        "SD3neg": 11.3,
        "SD2neg": 12.2,
        "SD1neg": 13.2,
        "SD0": 14.3,
        "SD1": 15.5,
        "SD2": 16.9,
        "SD3": 18.4
       },
       {
        "Height": 96.5,
        "L": -0.3521,
        "M": 14.4282,
        "S": 0.08097,
        "SD3neg": 11.4,
        "SD2neg": 12.3,
        "SD1neg": 13.3,
        "SD0": 14.4,
        "SD1": 15.7,
        "SD2": 17,
        "SD3": 18.6
       },
       {
        "Height": 97,
        "L": -0.3521,
        "M": 14.5547,
        "S": 0.08112,
        "SD3neg": 11.5,
        "SD2neg": 12.4,
        "SD1neg": 13.4,
        "SD0": 14.6,
        "SD1": 15.8,
        "SD2": 17.2,
        "SD3": 18.8
       },
       {
        "Height": 97.5,
        "L": -0.3521,
        "M": 14.6832,
        "S": 0.08129,
        "SD3neg": 11.6,
        "SD2neg": 12.5,
        "SD1neg": 13.6,
        "SD0": 14.7,
        "SD1": 15.9,
        "SD2": 17.4,
        "SD3": 18.9
       },
       {
        "Height": 98,
        "L": -0.3521,
        "M": 14.814,
        "S": 0.08146,
        "SD3neg": 11.7,
        "SD2neg": 12.6,
        "SD1neg": 13.7,
        "SD0": 14.8,
        "SD1": 16.1,
        "SD2": 17.5,
        "SD3": 19.1
       },
       {
        "Height": 98.5,
        "L": -0.3521,
        "M": 14.9468,
        "S": 0.08165,
        "SD3neg": 11.8,
        "SD2neg": 12.8,
        "SD1neg": 13.8,
        "SD0": 14.9,
        "SD1": 16.2,
        "SD2": 17.7,
        "SD3": 19.3
       },
       {
        "Height": 99,
        "L": -0.3521,
        "M": 15.0818,
        "S": 0.08185,
        "SD3neg": 11.9,
        "SD2neg": 12.9,
        "SD1neg": 13.9,
        "SD0": 15.1,
        "SD1": 16.4,
        "SD2": 17.9,
        "SD3": 19.5
       },
       {
        "Height": 99.5,
        "L": -0.3521,
        "M": 15.2187,
        "S": 0.08206,
        "SD3neg": 12,
        "SD2neg": 13,
        "SD1neg": 14,
        "SD0": 15.2,
        "SD1": 16.5,
        "SD2": 18,
        "SD3": 19.7
       },
       {
        "Height": 100,
        "L": -0.3521,
        "M": 15.3576,
        "S": 0.08229,
        "SD3neg": 12.1,
        "SD2neg": 13.1,
        "SD1neg": 14.2,
        "SD0": 15.4,
        "SD1": 16.7,
        "SD2": 18.2,
        "SD3": 19.9
       },
       {
        "Height": 100.5,
        "L": -0.3521,
        "M": 15.4985,
        "S": 0.08252,
        "SD3neg": 12.2,
        "SD2neg": 13.2,
        "SD1neg": 14.3,
        "SD0": 15.5,
        "SD1": 16.9,
        "SD2": 18.4,
        "SD3": 20.1
       },
       {
        "Height": 101,
        "L": -0.3521,
        "M": 15.6412,
        "S": 0.08277,
        "SD3neg": 12.3,
        "SD2neg": 13.3,
        "SD1neg": 14.4,
        "SD0": 15.6,
        "SD1": 17,
        "SD2": 18.5,
        "SD3": 20.3
       },
       {
        "Height": 101.5,
        "L": -0.3521,
        "M": 15.7857,
        "S": 0.08302,
        "SD3neg": 12.4,
        "SD2neg": 13.4,
        "SD1neg": 14.5,
        "SD0": 15.8,
        "SD1": 17.2,
        "SD2": 18.7,
        "SD3": 20.5
       },
       {
        "Height": 102,
        "L": -0.3521,
        "M": 15.932,
        "S": 0.08328,
        "SD3neg": 12.5,
        "SD2neg": 13.6,
        "SD1neg": 14.7,
        "SD0": 15.9,
        "SD1": 17.3,
        "SD2": 18.9,
        "SD3": 20.7
       },
       {
        "Height": 102.5,
        "L": -0.3521,
        "M": 16.0801,
        "S": 0.08354,
        "SD3neg": 12.6,
        "SD2neg": 13.7,
        "SD1neg": 14.8,
        "SD0": 16.1,
        "SD1": 17.5,
        "SD2": 19.1,
        "SD3": 20.9
       },
       {
        "Height": 103,
        "L": -0.3521,
        "M": 16.2298,
        "S": 0.08381,
        "SD3neg": 12.8,
        "SD2neg": 13.8,
        "SD1neg": 14.9,
        "SD0": 16.2,
        "SD1": 17.7,
        "SD2": 19.3,
        "SD3": 21.1
       },
       {
        "Height": 103.5,
        "L": -0.3521,
        "M": 16.3812,
        "S": 0.08408,
        "SD3neg": 12.9,
        "SD2neg": 13.9,
        "SD1neg": 15.1,
        "SD0": 16.4,
        "SD1": 17.8,
        "SD2": 19.5,
        "SD3": 21.3
       },
       {
        "Height": 104,
        "L": -0.3521,
        "M": 16.5342,
        "S": 0.08436,
        "SD3neg": 13,
        "SD2neg": 14,
        "SD1neg": 15.2,
        "SD0": 16.5,
        "SD1": 18,
        "SD2": 19.7,
        "SD3": 21.6
       },
       {
        "Height": 104.5,
        "L": -0.3521,
        "M": 16.6889,
        "S": 0.08464,
        "SD3neg": 13.1,
        "SD2neg": 14.2,
        "SD1neg": 15.4,
        "SD0": 16.7,
        "SD1": 18.2,
        "SD2": 19.9,
        "SD3": 21.8
       },
       {
        "Height": 105,
        "L": -0.3521,
        "M": 16.8454,
        "S": 0.08493,
        "SD3neg": 13.2,
        "SD2neg": 14.3,
        "SD1neg": 15.5,
        "SD0": 16.8,
        "SD1": 18.4,
        "SD2": 20.1,
        "SD3": 22
       },
       {
        "Height": 105.5,
        "L": -0.3521,
        "M": 17.0036,
        "S": 0.08521,
        "SD3neg": 13.3,
        "SD2neg": 14.4,
        "SD1neg": 15.6,
        "SD0": 17,
        "SD1": 18.5,
        "SD2": 20.3,
        "SD3": 22.2
       },
       {
        "Height": 106,
        "L": -0.3521,
        "M": 17.1637,
        "S": 0.08551,
        "SD3neg": 13.4,
        "SD2neg": 14.5,
        "SD1neg": 15.8,
        "SD0": 17.2,
        "SD1": 18.7,
        "SD2": 20.5,
        "SD3": 22.5
       },
       {
        "Height": 106.5,
        "L": -0.3521,
        "M": 17.3256,
        "S": 0.0858,
        "SD3neg": 13.5,
        "SD2neg": 14.7,
        "SD1neg": 15.9,
        "SD0": 17.3,
        "SD1": 18.9,
        "SD2": 20.7,
        "SD3": 22.7
       },
       {
        "Height": 107,
        "L": -0.3521,
        "M": 17.4894,
        "S": 0.08611,
        "SD3neg": 13.7,
        "SD2neg": 14.8,
        "SD1neg": 16.1,
        "SD0": 17.5,
        "SD1": 19.1,
        "SD2": 20.9,
        "SD3": 22.9
       },
       {
        "Height": 107.5,
        "L": -0.3521,
        "M": 17.655,
        "S": 0.08641,
        "SD3neg": 13.8,
        "SD2neg": 14.9,
        "SD1neg": 16.2,
        "SD0": 17.7,
        "SD1": 19.3,
        "SD2": 21.1,
        "SD3": 23.2
       },
       {
        "Height": 108,
        "L": -0.3521,
        "M": 17.8226,
        "S": 0.08673,
        "SD3neg": 13.9,
        "SD2neg": 15.1,
        "SD1neg": 16.4,
        "SD0": 17.8,
        "SD1": 19.5,
        "SD2": 21.3,
        "SD3": 23.4
       },
       {
        "Height": 108.5,
        "L": -0.3521,
        "M": 17.9924,
        "S": 0.08704,
        "SD3neg": 14,
        "SD2neg": 15.2,
        "SD1neg": 16.5,
        "SD0": 18,
        "SD1": 19.7,
        "SD2": 21.5,
        "SD3": 23.7
       },
       {
        "Height": 109,
        "L": -0.3521,
        "M": 18.1645,
        "S": 0.08736,
        "SD3neg": 14.1,
        "SD2neg": 15.3,
        "SD1neg": 16.7,
        "SD0": 18.2,
        "SD1": 19.8,
        "SD2": 21.8,
        "SD3": 23.9
       },
       {
        "Height": 109.5,
        "L": -0.3521,
        "M": 18.339,
        "S": 0.08768,
        "SD3neg": 14.3,
        "SD2neg": 15.5,
        "SD1neg": 16.8,
        "SD0": 18.3,
        "SD1": 20,
        "SD2": 22,
        "SD3": 24.2
       },
       {
        "Height": 110,
        "L": -0.3521,
        "M": 18.5158,
        "S": 0.088,
        "SD3neg": 14.4,
        "SD2neg": 15.6,
        "SD1neg": 17,
        "SD0": 18.5,
        "SD1": 20.2,
        "SD2": 22.2,
        "SD3": 24.4
       },
       {
        "Height": 110.5,
        "L": -0.3521,
        "M": 18.6948,
        "S": 0.08832,
        "SD3neg": 14.5,
        "SD2neg": 15.8,
        "SD1neg": 17.1,
        "SD0": 18.7,
        "SD1": 20.4,
        "SD2": 22.4,
        "SD3": 24.7
       },
       {
        "Height": 111,
        "L": -0.3521,
        "M": 18.8759,
        "S": 0.08864,
        "SD3neg": 14.6,
        "SD2neg": 15.9,
        "SD1neg": 17.3,
        "SD0": 18.9,
        "SD1": 20.7,
        "SD2": 22.7,
        "SD3": 25
       },
       {
        "Height": 111.5,
        "L": -0.3521,
        "M": 19.059,
        "S": 0.08896,
        "SD3neg": 14.8,
        "SD2neg": 16,
        "SD1neg": 17.5,
        "SD0": 19.1,
        "SD1": 20.9,
        "SD2": 22.9,
        "SD3": 25.2
       },
       {
        "Height": 112,
        "L": -0.3521,
        "M": 19.2439,
        "S": 0.08928,
        "SD3neg": 14.9,
        "SD2neg": 16.2,
        "SD1neg": 17.6,
        "SD0": 19.2,
        "SD1": 21.1,
        "SD2": 23.1,
        "SD3": 25.5
       },
       {
        "Height": 112.5,
        "L": -0.3521,
        "M": 19.4304,
        "S": 0.0896,
        "SD3neg": 15,
        "SD2neg": 16.3,
        "SD1neg": 17.8,
        "SD0": 19.4,
        "SD1": 21.3,
        "SD2": 23.4,
        "SD3": 25.8
       },
       {
        "Height": 113,
        "L": -0.3521,
        "M": 19.6185,
        "S": 0.08991,
        "SD3neg": 15.2,
        "SD2neg": 16.5,
        "SD1neg": 18,
        "SD0": 19.6,
        "SD1": 21.5,
        "SD2": 23.6,
        "SD3": 26
       },
       {
        "Height": 113.5,
        "L": -0.3521,
        "M": 19.8081,
        "S": 0.09022,
        "SD3neg": 15.3,
        "SD2neg": 16.6,
        "SD1neg": 18.1,
        "SD0": 19.8,
        "SD1": 21.7,
        "SD2": 23.9,
        "SD3": 26.3
       },
       {
        "Height": 114,
        "L": -0.3521,
        "M": 19.999,
        "S": 0.09054,
        "SD3neg": 15.4,
        "SD2neg": 16.8,
        "SD1neg": 18.3,
        "SD0": 20,
        "SD1": 21.9,
        "SD2": 24.1,
        "SD3": 26.6
       },
       {
        "Height": 114.5,
        "L": -0.3521,
        "M": 20.1912,
        "S": 0.09085,
        "SD3neg": 15.6,
        "SD2neg": 16.9,
        "SD1neg": 18.5,
        "SD0": 20.2,
        "SD1": 22.1,
        "SD2": 24.4,
        "SD3": 26.9
       },
       {
        "Height": 115,
        "L": -0.3521,
        "M": 20.3846,
        "S": 0.09116,
        "SD3neg": 15.7,
        "SD2neg": 17.1,
        "SD1neg": 18.6,
        "SD0": 20.4,
        "SD1": 22.4,
        "SD2": 24.6,
        "SD3": 27.2
       },
       {
        "Height": 115.5,
        "L": -0.3521,
        "M": 20.5789,
        "S": 0.09147,
        "SD3neg": 15.8,
        "SD2neg": 17.2,
        "SD1neg": 18.8,
        "SD0": 20.6,
        "SD1": 22.6,
        "SD2": 24.9,
        "SD3": 27.5
       },
       {
        "Height": 116,
        "L": -0.3521,
        "M": 20.7741,
        "S": 0.09177,
        "SD3neg": 16,
        "SD2neg": 17.4,
        "SD1neg": 19,
        "SD0": 20.8,
        "SD1": 22.8,
        "SD2": 25.1,
        "SD3": 27.8
       },
       {
        "Height": 116.5,
        "L": -0.3521,
        "M": 20.97,
        "S": 0.09208,
        "SD3neg": 16.1,
        "SD2neg": 17.5,
        "SD1neg": 19.2,
        "SD0": 21,
        "SD1": 23,
        "SD2": 25.4,
        "SD3": 28
       },
       {
        "Height": 117,
        "L": -0.3521,
        "M": 21.1666,
        "S": 0.09239,
        "SD3neg": 16.2,
        "SD2neg": 17.7,
        "SD1neg": 19.3,
        "SD0": 21.2,
        "SD1": 23.3,
        "SD2": 25.6,
        "SD3": 28.3
       },
       {
        "Height": 117.5,
        "L": -0.3521,
        "M": 21.3636,
        "S": 0.0927,
        "SD3neg": 16.4,
        "SD2neg": 17.9,
        "SD1neg": 19.5,
        "SD0": 21.4,
        "SD1": 23.5,
        "SD2": 25.9,
        "SD3": 28.6
       },
       {
        "Height": 118,
        "L": -0.3521,
        "M": 21.5611,
        "S": 0.093,
        "SD3neg": 16.5,
        "SD2neg": 18,
        "SD1neg": 19.7,
        "SD0": 21.6,
        "SD1": 23.7,
        "SD2": 26.1,
        "SD3": 28.9
       },
       {
        "Height": 118.5,
        "L": -0.3521,
        "M": 21.7588,
        "S": 0.09331,
        "SD3neg": 16.7,
        "SD2neg": 18.2,
        "SD1neg": 19.9,
        "SD0": 21.8,
        "SD1": 23.9,
        "SD2": 26.4,
        "SD3": 29.2
       },
       {
        "Height": 119,
        "L": -0.3521,
        "M": 21.9568,
        "S": 0.09362,
        "SD3neg": 16.8,
        "SD2neg": 18.3,
        "SD1neg": 20,
        "SD0": 22,
        "SD1": 24.1,
        "SD2": 26.6,
        "SD3": 29.5
       },
       {
        "Height": 119.5,
        "L": -0.3521,
        "M": 22.1549,
        "S": 0.09393,
        "SD3neg": 16.9,
        "SD2neg": 18.5,
        "SD1neg": 20.2,
        "SD0": 22.2,
        "SD1": 24.4,
        "SD2": 26.9,
        "SD3": 29.8
       },
       {
        "Height": 120,
        "L": -0.3521,
        "M": 22.353,
        "S": 0.09424,
        "SD3neg": 17.1,
        "SD2neg": 18.6,
        "SD1neg": 20.4,
        "SD0": 22.4,
        "SD1": 24.6,
        "SD2": 27.2,
        "SD3": 30.1
       }
]