// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.highlights-box {
    border: 1px solid #568eb6;
}

.whited {
    color: aliceblue;
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/banner/styles.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,SAAS;AACb","sourcesContent":[".highlights-box {\n    border: 1px solid #568eb6;\n}\n\n.whited {\n    color: aliceblue;\n    margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
