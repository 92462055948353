export const wflh_g_2_to_5_years_zscores = [
    {
        "Length": 45,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 45.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 46,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 46.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 47,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 47.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 48,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 48.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 49,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 49.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 50,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 50.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 51,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 51.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 52,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 52.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 53,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 53.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 54,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 54.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 55,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 55.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 56,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 56.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 57,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 57.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 58,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 58.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 59,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 59.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 60,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 60.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 61,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 61.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 62,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 62.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 63,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 63.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 64,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 64.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 65,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 65.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 66,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 66.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 67,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 67.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 68,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 68.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 69,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 69.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 70,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 70.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 71,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 71.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 72,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 72.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 73,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 73.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 74,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 74.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 75,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 75.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 76,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 76.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 77,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 77.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 78,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 78.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 79,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 79.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 80,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 80.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 81,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 81.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 82,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 82.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 83,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 83.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 84,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 84.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 85,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 85.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 86,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Length": 86.5,
        "L": "null",
        "M": "null",
        "S": "null",
        "SD3neg": "null",
        "SD2neg": "null",
        "SD1neg": "null",
        "SD0": "null",
        "SD1": "null",
        "SD2": "null",
        "SD3": "null"
       },
       {
        "Height": 87,
        "L": -0.3833,
        "M": 11.8965,
        "S": 0.08896,
        "SD3neg": 9.2,
        "SD2neg": 10,
        "SD1neg": 10.9,
        "SD0": 11.9,
        "SD1": 13,
        "SD2": 14.3,
        "SD3": 15.8
       },
       {
        "Height": 87.5,
        "L": -0.3833,
        "M": 12.0223,
        "S": 0.08897,
        "SD3neg": 9.3,
        "SD2neg": 10.1,
        "SD1neg": 11,
        "SD0": 12,
        "SD1": 13.2,
        "SD2": 14.5,
        "SD3": 15.9
       },
       {
        "Height": 88,
        "L": -0.3833,
        "M": 12.1478,
        "S": 0.08899,
        "SD3neg": 9.4,
        "SD2neg": 10.2,
        "SD1neg": 11.1,
        "SD0": 12.1,
        "SD1": 13.3,
        "SD2": 14.6,
        "SD3": 16.1
       },
       {
        "Height": 88.5,
        "L": -0.3833,
        "M": 12.2729,
        "S": 0.08901,
        "SD3neg": 9.5,
        "SD2neg": 10.3,
        "SD1neg": 11.2,
        "SD0": 12.3,
        "SD1": 13.4,
        "SD2": 14.8,
        "SD3": 16.3
       },
       {
        "Height": 89,
        "L": -0.3833,
        "M": 12.3976,
        "S": 0.08904,
        "SD3neg": 9.6,
        "SD2neg": 10.4,
        "SD1neg": 11.4,
        "SD0": 12.4,
        "SD1": 13.6,
        "SD2": 14.9,
        "SD3": 16.4
       },
       {
        "Height": 89.5,
        "L": -0.3833,
        "M": 12.522,
        "S": 0.08907,
        "SD3neg": 9.7,
        "SD2neg": 10.5,
        "SD1neg": 11.5,
        "SD0": 12.5,
        "SD1": 13.7,
        "SD2": 15.1,
        "SD3": 16.6
       },
       {
        "Height": 90,
        "L": -0.3833,
        "M": 12.6461,
        "S": 0.08911,
        "SD3neg": 9.8,
        "SD2neg": 10.6,
        "SD1neg": 11.6,
        "SD0": 12.6,
        "SD1": 13.8,
        "SD2": 15.2,
        "SD3": 16.8
       },
       {
        "Height": 90.5,
        "L": -0.3833,
        "M": 12.77,
        "S": 0.08915,
        "SD3neg": 9.9,
        "SD2neg": 10.7,
        "SD1neg": 11.7,
        "SD0": 12.8,
        "SD1": 14,
        "SD2": 15.4,
        "SD3": 16.9
       },
       {
        "Height": 91,
        "L": -0.3833,
        "M": 12.8939,
        "S": 0.0892,
        "SD3neg": 10,
        "SD2neg": 10.9,
        "SD1neg": 11.8,
        "SD0": 12.9,
        "SD1": 14.1,
        "SD2": 15.5,
        "SD3": 17.1
       },
       {
        "Height": 91.5,
        "L": -0.3833,
        "M": 13.0177,
        "S": 0.08925,
        "SD3neg": 10.1,
        "SD2neg": 11,
        "SD1neg": 11.9,
        "SD0": 13,
        "SD1": 14.3,
        "SD2": 15.7,
        "SD3": 17.3
       },
       {
        "Height": 92,
        "L": -0.3833,
        "M": 13.1415,
        "S": 0.08931,
        "SD3neg": 10.2,
        "SD2neg": 11.1,
        "SD1neg": 12,
        "SD0": 13.1,
        "SD1": 14.4,
        "SD2": 15.8,
        "SD3": 17.4
       },
       {
        "Height": 92.5,
        "L": -0.3833,
        "M": 13.2654,
        "S": 0.08937,
        "SD3neg": 10.3,
        "SD2neg": 11.2,
        "SD1neg": 12.1,
        "SD0": 13.3,
        "SD1": 14.5,
        "SD2": 16,
        "SD3": 17.6
       },
       {
        "Height": 93,
        "L": -0.3833,
        "M": 13.3896,
        "S": 0.08944,
        "SD3neg": 10.4,
        "SD2neg": 11.3,
        "SD1neg": 12.3,
        "SD0": 13.4,
        "SD1": 14.7,
        "SD2": 16.1,
        "SD3": 17.8
       },
       {
        "Height": 93.5,
        "L": -0.3833,
        "M": 13.5142,
        "S": 0.08951,
        "SD3neg": 10.5,
        "SD2neg": 11.4,
        "SD1neg": 12.4,
        "SD0": 13.5,
        "SD1": 14.8,
        "SD2": 16.3,
        "SD3": 17.9
       },
       {
        "Height": 94,
        "L": -0.3833,
        "M": 13.6393,
        "S": 0.08959,
        "SD3neg": 10.6,
        "SD2neg": 11.5,
        "SD1neg": 12.5,
        "SD0": 13.6,
        "SD1": 14.9,
        "SD2": 16.4,
        "SD3": 18.1
       },
       {
        "Height": 94.5,
        "L": -0.3833,
        "M": 13.765,
        "S": 0.08967,
        "SD3neg": 10.7,
        "SD2neg": 11.6,
        "SD1neg": 12.6,
        "SD0": 13.8,
        "SD1": 15.1,
        "SD2": 16.6,
        "SD3": 18.3
       },
       {
        "Height": 95,
        "L": -0.3833,
        "M": 13.8914,
        "S": 0.08975,
        "SD3neg": 10.8,
        "SD2neg": 11.7,
        "SD1neg": 12.7,
        "SD0": 13.9,
        "SD1": 15.2,
        "SD2": 16.7,
        "SD3": 18.5
       },
       {
        "Height": 95.5,
        "L": -0.3833,
        "M": 14.0186,
        "S": 0.08984,
        "SD3neg": 10.8,
        "SD2neg": 11.8,
        "SD1neg": 12.8,
        "SD0": 14,
        "SD1": 15.4,
        "SD2": 16.9,
        "SD3": 18.6
       },
       {
        "Height": 96,
        "L": -0.3833,
        "M": 14.1466,
        "S": 0.08994,
        "SD3neg": 10.9,
        "SD2neg": 11.9,
        "SD1neg": 12.9,
        "SD0": 14.1,
        "SD1": 15.5,
        "SD2": 17,
        "SD3": 18.8
       },
       {
        "Height": 96.5,
        "L": -0.3833,
        "M": 14.2757,
        "S": 0.09004,
        "SD3neg": 11,
        "SD2neg": 12,
        "SD1neg": 13.1,
        "SD0": 14.3,
        "SD1": 15.6,
        "SD2": 17.2,
        "SD3": 19
       },
       {
        "Height": 97,
        "L": -0.3833,
        "M": 14.4059,
        "S": 0.09015,
        "SD3neg": 11.1,
        "SD2neg": 12.1,
        "SD1neg": 13.2,
        "SD0": 14.4,
        "SD1": 15.8,
        "SD2": 17.4,
        "SD3": 19.2
       },
       {
        "Height": 97.5,
        "L": -0.3833,
        "M": 14.5376,
        "S": 0.09026,
        "SD3neg": 11.2,
        "SD2neg": 12.2,
        "SD1neg": 13.3,
        "SD0": 14.5,
        "SD1": 15.9,
        "SD2": 17.5,
        "SD3": 19.3
       },
       {
        "Height": 98,
        "L": -0.3833,
        "M": 14.671,
        "S": 0.09037,
        "SD3neg": 11.3,
        "SD2neg": 12.3,
        "SD1neg": 13.4,
        "SD0": 14.7,
        "SD1": 16.1,
        "SD2": 17.7,
        "SD3": 19.5
       },
       {
        "Height": 98.5,
        "L": -0.3833,
        "M": 14.8062,
        "S": 0.09049,
        "SD3neg": 11.4,
        "SD2neg": 12.4,
        "SD1neg": 13.5,
        "SD0": 14.8,
        "SD1": 16.2,
        "SD2": 17.9,
        "SD3": 19.7
       },
       {
        "Height": 99,
        "L": -0.3833,
        "M": 14.9434,
        "S": 0.09062,
        "SD3neg": 11.5,
        "SD2neg": 12.5,
        "SD1neg": 13.7,
        "SD0": 14.9,
        "SD1": 16.4,
        "SD2": 18,
        "SD3": 19.9
       },
       {
        "Height": 99.5,
        "L": -0.3833,
        "M": 15.0828,
        "S": 0.09075,
        "SD3neg": 11.6,
        "SD2neg": 12.7,
        "SD1neg": 13.8,
        "SD0": 15.1,
        "SD1": 16.5,
        "SD2": 18.2,
        "SD3": 20.1
       },
       {
        "Height": 100,
        "L": -0.3833,
        "M": 15.2246,
        "S": 0.09088,
        "SD3neg": 11.7,
        "SD2neg": 12.8,
        "SD1neg": 13.9,
        "SD0": 15.2,
        "SD1": 16.7,
        "SD2": 18.4,
        "SD3": 20.3
       },
       {
        "Height": 100.5,
        "L": -0.3833,
        "M": 15.3687,
        "S": 0.09102,
        "SD3neg": 11.9,
        "SD2neg": 12.9,
        "SD1neg": 14.1,
        "SD0": 15.4,
        "SD1": 16.9,
        "SD2": 18.6,
        "SD3": 20.5
       },
       {
        "Height": 101,
        "L": -0.3833,
        "M": 15.5154,
        "S": 0.09116,
        "SD3neg": 12,
        "SD2neg": 13,
        "SD1neg": 14.2,
        "SD0": 15.5,
        "SD1": 17,
        "SD2": 18.7,
        "SD3": 20.7
       },
       {
        "Height": 101.5,
        "L": -0.3833,
        "M": 15.6646,
        "S": 0.09131,
        "SD3neg": 12.1,
        "SD2neg": 13.1,
        "SD1neg": 14.3,
        "SD0": 15.7,
        "SD1": 17.2,
        "SD2": 18.9,
        "SD3": 20.9
       },
       {
        "Height": 102,
        "L": -0.3833,
        "M": 15.8164,
        "S": 0.09146,
        "SD3neg": 12.2,
        "SD2neg": 13.3,
        "SD1neg": 14.5,
        "SD0": 15.8,
        "SD1": 17.4,
        "SD2": 19.1,
        "SD3": 21.1
       },
       {
        "Height": 102.5,
        "L": -0.3833,
        "M": 15.9707,
        "S": 0.09161,
        "SD3neg": 12.3,
        "SD2neg": 13.4,
        "SD1neg": 14.6,
        "SD0": 16,
        "SD1": 17.5,
        "SD2": 19.3,
        "SD3": 21.4
       },
       {
        "Height": 103,
        "L": -0.3833,
        "M": 16.1276,
        "S": 0.09177,
        "SD3neg": 12.4,
        "SD2neg": 13.5,
        "SD1neg": 14.7,
        "SD0": 16.1,
        "SD1": 17.7,
        "SD2": 19.5,
        "SD3": 21.6
       },
       {
        "Height": 103.5,
        "L": -0.3833,
        "M": 16.287,
        "S": 0.09193,
        "SD3neg": 12.5,
        "SD2neg": 13.6,
        "SD1neg": 14.9,
        "SD0": 16.3,
        "SD1": 17.9,
        "SD2": 19.7,
        "SD3": 21.8
       },
       {
        "Height": 104,
        "L": -0.3833,
        "M": 16.4488,
        "S": 0.09209,
        "SD3neg": 12.6,
        "SD2neg": 13.8,
        "SD1neg": 15,
        "SD0": 16.4,
        "SD1": 18.1,
        "SD2": 19.9,
        "SD3": 22
       },
       {
        "Height": 104.5,
        "L": -0.3833,
        "M": 16.6131,
        "S": 0.09226,
        "SD3neg": 12.8,
        "SD2neg": 13.9,
        "SD1neg": 15.2,
        "SD0": 16.6,
        "SD1": 18.2,
        "SD2": 20.1,
        "SD3": 22.3
       },
       {
        "Height": 105,
        "L": -0.3833,
        "M": 16.78,
        "S": 0.09243,
        "SD3neg": 12.9,
        "SD2neg": 14,
        "SD1neg": 15.3,
        "SD0": 16.8,
        "SD1": 18.4,
        "SD2": 20.3,
        "SD3": 22.5
       },
       {
        "Height": 105.5,
        "L": -0.3833,
        "M": 16.9496,
        "S": 0.09261,
        "SD3neg": 13,
        "SD2neg": 14.2,
        "SD1neg": 15.5,
        "SD0": 16.9,
        "SD1": 18.6,
        "SD2": 20.5,
        "SD3": 22.7
       },
       {
        "Height": 106,
        "L": -0.3833,
        "M": 17.122,
        "S": 0.09278,
        "SD3neg": 13.1,
        "SD2neg": 14.3,
        "SD1neg": 15.6,
        "SD0": 17.1,
        "SD1": 18.8,
        "SD2": 20.8,
        "SD3": 23
       },
       {
        "Height": 106.5,
        "L": -0.3833,
        "M": 17.2973,
        "S": 0.09296,
        "SD3neg": 13.3,
        "SD2neg": 14.5,
        "SD1neg": 15.8,
        "SD0": 17.3,
        "SD1": 19,
        "SD2": 21,
        "SD3": 23.2
       },
       {
        "Height": 107,
        "L": -0.3833,
        "M": 17.4755,
        "S": 0.09315,
        "SD3neg": 13.4,
        "SD2neg": 14.6,
        "SD1neg": 15.9,
        "SD0": 17.5,
        "SD1": 19.2,
        "SD2": 21.2,
        "SD3": 23.5
       },
       {
        "Height": 107.5,
        "L": -0.3833,
        "M": 17.6567,
        "S": 0.09333,
        "SD3neg": 13.5,
        "SD2neg": 14.7,
        "SD1neg": 16.1,
        "SD0": 17.7,
        "SD1": 19.4,
        "SD2": 21.4,
        "SD3": 23.7
       },
       {
        "Height": 108,
        "L": -0.3833,
        "M": 17.8407,
        "S": 0.09352,
        "SD3neg": 13.7,
        "SD2neg": 14.9,
        "SD1neg": 16.3,
        "SD0": 17.8,
        "SD1": 19.6,
        "SD2": 21.7,
        "SD3": 24
       },
       {
        "Height": 108.5,
        "L": -0.3833,
        "M": 18.0277,
        "S": 0.09371,
        "SD3neg": 13.8,
        "SD2neg": 15,
        "SD1neg": 16.4,
        "SD0": 18,
        "SD1": 19.8,
        "SD2": 21.9,
        "SD3": 24.3
       },
       {
        "Height": 109,
        "L": -0.3833,
        "M": 18.2174,
        "S": 0.0939,
        "SD3neg": 13.9,
        "SD2neg": 15.2,
        "SD1neg": 16.6,
        "SD0": 18.2,
        "SD1": 20,
        "SD2": 22.1,
        "SD3": 24.5
       },
       {
        "Height": 109.5,
        "L": -0.3833,
        "M": 18.4096,
        "S": 0.09409,
        "SD3neg": 14.1,
        "SD2neg": 15.4,
        "SD1neg": 16.8,
        "SD0": 18.4,
        "SD1": 20.3,
        "SD2": 22.4,
        "SD3": 24.8
       },
       {
        "Height": 110,
        "L": -0.3833,
        "M": 18.6043,
        "S": 0.09428,
        "SD3neg": 14.2,
        "SD2neg": 15.5,
        "SD1neg": 17,
        "SD0": 18.6,
        "SD1": 20.5,
        "SD2": 22.6,
        "SD3": 25.1
       },
       {
        "Height": 110.5,
        "L": -0.3833,
        "M": 18.8015,
        "S": 0.09448,
        "SD3neg": 14.4,
        "SD2neg": 15.7,
        "SD1neg": 17.1,
        "SD0": 18.8,
        "SD1": 20.7,
        "SD2": 22.9,
        "SD3": 25.4
       },
       {
        "Height": 111,
        "L": -0.3833,
        "M": 19.0009,
        "S": 0.09467,
        "SD3neg": 14.5,
        "SD2neg": 15.8,
        "SD1neg": 17.3,
        "SD0": 19,
        "SD1": 20.9,
        "SD2": 23.1,
        "SD3": 25.7
       },
       {
        "Height": 111.5,
        "L": -0.3833,
        "M": 19.2024,
        "S": 0.09487,
        "SD3neg": 14.7,
        "SD2neg": 16,
        "SD1neg": 17.5,
        "SD0": 19.2,
        "SD1": 21.2,
        "SD2": 23.4,
        "SD3": 26
       },
       {
        "Height": 112,
        "L": -0.3833,
        "M": 19.406,
        "S": 0.09507,
        "SD3neg": 14.8,
        "SD2neg": 16.2,
        "SD1neg": 17.7,
        "SD0": 19.4,
        "SD1": 21.4,
        "SD2": 23.6,
        "SD3": 26.2
       },
       {
        "Height": 112.5,
        "L": -0.3833,
        "M": 19.6116,
        "S": 0.09527,
        "SD3neg": 15,
        "SD2neg": 16.3,
        "SD1neg": 17.9,
        "SD0": 19.6,
        "SD1": 21.6,
        "SD2": 23.9,
        "SD3": 26.5
       },
       {
        "Height": 113,
        "L": -0.3833,
        "M": 19.819,
        "S": 0.09546,
        "SD3neg": 15.1,
        "SD2neg": 16.5,
        "SD1neg": 18,
        "SD0": 19.8,
        "SD1": 21.8,
        "SD2": 24.2,
        "SD3": 26.8
       },
       {
        "Height": 113.5,
        "L": -0.3833,
        "M": 20.028,
        "S": 0.09566,
        "SD3neg": 15.3,
        "SD2neg": 16.7,
        "SD1neg": 18.2,
        "SD0": 20,
        "SD1": 22.1,
        "SD2": 24.4,
        "SD3": 27.1
       },
       {
        "Height": 114,
        "L": -0.3833,
        "M": 20.2385,
        "S": 0.09586,
        "SD3neg": 15.4,
        "SD2neg": 16.8,
        "SD1neg": 18.4,
        "SD0": 20.2,
        "SD1": 22.3,
        "SD2": 24.7,
        "SD3": 27.4
       },
       {
        "Height": 114.5,
        "L": -0.3833,
        "M": 20.4502,
        "S": 0.09606,
        "SD3neg": 15.6,
        "SD2neg": 17,
        "SD1neg": 18.6,
        "SD0": 20.5,
        "SD1": 22.6,
        "SD2": 25,
        "SD3": 27.8
       },
       {
        "Height": 115,
        "L": -0.3833,
        "M": 20.6629,
        "S": 0.09626,
        "SD3neg": 15.7,
        "SD2neg": 17.2,
        "SD1neg": 18.8,
        "SD0": 20.7,
        "SD1": 22.8,
        "SD2": 25.2,
        "SD3": 28.1
       },
       {
        "Height": 115.5,
        "L": -0.3833,
        "M": 20.8766,
        "S": 0.09646,
        "SD3neg": 15.9,
        "SD2neg": 17.3,
        "SD1neg": 19,
        "SD0": 20.9,
        "SD1": 23,
        "SD2": 25.5,
        "SD3": 28.4
       },
       {
        "Height": 116,
        "L": -0.3833,
        "M": 21.0909,
        "S": 0.09666,
        "SD3neg": 16,
        "SD2neg": 17.5,
        "SD1neg": 19.2,
        "SD0": 21.1,
        "SD1": 23.3,
        "SD2": 25.8,
        "SD3": 28.7
       },
       {
        "Height": 116.5,
        "L": -0.3833,
        "M": 21.3059,
        "S": 0.09686,
        "SD3neg": 16.2,
        "SD2neg": 17.7,
        "SD1neg": 19.4,
        "SD0": 21.3,
        "SD1": 23.5,
        "SD2": 26.1,
        "SD3": 29
       },
       {
        "Height": 117,
        "L": -0.3833,
        "M": 21.5213,
        "S": 0.09707,
        "SD3neg": 16.3,
        "SD2neg": 17.8,
        "SD1neg": 19.6,
        "SD0": 21.5,
        "SD1": 23.8,
        "SD2": 26.3,
        "SD3": 29.3
       },
       {
        "Height": 117.5,
        "L": -0.3833,
        "M": 21.737,
        "S": 0.09727,
        "SD3neg": 16.5,
        "SD2neg": 18,
        "SD1neg": 19.8,
        "SD0": 21.7,
        "SD1": 24,
        "SD2": 26.6,
        "SD3": 29.6
       },
       {
        "Height": 118,
        "L": -0.3833,
        "M": 21.9529,
        "S": 0.09747,
        "SD3neg": 16.6,
        "SD2neg": 18.2,
        "SD1neg": 19.9,
        "SD0": 22,
        "SD1": 24.2,
        "SD2": 26.9,
        "SD3": 29.9
       },
       {
        "Height": 118.5,
        "L": -0.3833,
        "M": 22.169,
        "S": 0.09767,
        "SD3neg": 16.8,
        "SD2neg": 18.4,
        "SD1neg": 20.1,
        "SD0": 22.2,
        "SD1": 24.5,
        "SD2": 27.2,
        "SD3": 30.3
       },
       {
        "Height": 119,
        "L": -0.3833,
        "M": 22.3851,
        "S": 0.09788,
        "SD3neg": 16.9,
        "SD2neg": 18.5,
        "SD1neg": 20.3,
        "SD0": 22.4,
        "SD1": 24.7,
        "SD2": 27.4,
        "SD3": 30.6
       },
       {
        "Height": 119.5,
        "L": -0.3833,
        "M": 22.6012,
        "S": 0.09808,
        "SD3neg": 17.1,
        "SD2neg": 18.7,
        "SD1neg": 20.5,
        "SD0": 22.6,
        "SD1": 25,
        "SD2": 27.7,
        "SD3": 30.9
       },
       {
        "Height": 120,
        "L": -0.3833,
        "M": 22.8173,
        "S": 0.09828,
        "SD3neg": 17.3,
        "SD2neg": 18.9,
        "SD1neg": 20.7,
        "SD0": 22.8,
        "SD1": 25.2,
        "SD2": 28,
        "SD3": 31.2
       }
]