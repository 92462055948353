export const wfa_g_0_to_5_years_zscores = [
    {
        "Month": 0,
        "SD0": 3.2,
        "SD1": 3.7,
        "SD2": 4.2,
        "SD3": 4.8,
        "SD1neg": 2.8,
        "SD2neg": 2.4,
        "SD3neg": 2.0
    },
    {
        "Month": 1,
        "SD0": 4.2,
        "SD1": 4.8,
        "SD2": 5.5,
        "SD3": 6.2,
        "SD1neg": 3.6,
        "SD2neg": 3.2,
        "SD3neg": 2.7
    },
    {
        "Month": 2,
        "SD0": 5.1,
        "SD1": 5.8,
        "SD2": 6.6,
        "SD3": 7.5,
        "SD1neg": 4.5,
        "SD2neg": 3.9,
        "SD3neg": 3.4
    },
    {
        "Month": 3,
        "SD0": 5.8,
        "SD1": 6.6,
        "SD2": 7.5,
        "SD3": 8.5,
        "SD1neg": 5.2,
        "SD2neg": 4.5,
        "SD3neg": 4.0
    },
    {
        "Month": 4,
        "SD0": 6.4,
        "SD1": 7.3,
        "SD2": 8.2,
        "SD3": 9.3,
        "SD1neg": 5.7,
        "SD2neg": 5.0,
        "SD3neg": 4.4
    },
    {
        "Month": 5,
        "SD0": 6.9,
        "SD1": 7.8,
        "SD2": 8.8,
        "SD3": 10.0,
        "SD1neg": 6.1,
        "SD2neg": 5.4,
        "SD3neg": 4.8
    },
    {
        "Month": 6,
        "SD0": 7.3,
        "SD1": 8.2,
        "SD2": 9.3,
        "SD3": 10.6,
        "SD1neg": 6.5,
        "SD2neg": 5.7,
        "SD3neg": 5.1
    },
    {
        "Month": 7,
        "SD0": 7.6,
        "SD1": 8.6,
        "SD2": 9.8,
        "SD3": 11.1,
        "SD1neg": 6.8,
        "SD2neg": 6.0,
        "SD3neg": 5.3
    },
    {
        "Month": 8,
        "SD0": 7.9,
        "SD1": 9.0,
        "SD2": 10.2,
        "SD3": 11.6,
        "SD1neg": 7.0,
        "SD2neg": 6.3,
        "SD3neg": 5.6
    },
    {
        "Month": 9,
        "SD0": 8.2,
        "SD1": 9.3,
        "SD2": 10.5,
        "SD3": 12.0,
        "SD1neg": 7.3,
        "SD2neg": 6.5,
        "SD3neg": 5.8
    },
    {
        "Month": 10,
        "SD0": 8.5,
        "SD1": 9.6,
        "SD2": 10.9,
        "SD3": 12.4,
        "SD1neg": 7.5,
        "SD2neg": 6.7,
        "SD3neg": 5.9
    },
    {
        "Month": 11,
        "SD0": 8.7,
        "SD1": 9.9,
        "SD2": 11.2,
        "SD3": 12.8,
        "SD1neg": 7.7,
        "SD2neg": 6.9,
        "SD3neg": 6.1
    },
    {
        "Month": 12,
        "SD0": 8.9,
        "SD1": 10.1,
        "SD2": 11.5,
        "SD3": 13.1,
        "SD1neg": 7.9,
        "SD2neg": 7.0,
        "SD3neg": 6.3
    },
    {
        "Month": 13,
        "SD0": 9.2,
        "SD1": 10.4,
        "SD2": 11.8,
        "SD3": 13.5,
        "SD1neg": 8.1,
        "SD2neg": 7.2,
        "SD3neg": 6.4
    },
    {
        "Month": 14,
        "SD0": 9.4,
        "SD1": 10.6,
        "SD2": 12.1,
        "SD3": 13.8,
        "SD1neg": 8.3,
        "SD2neg": 7.4,
        "SD3neg": 6.6
    },
    {
        "Month": 15,
        "SD0": 9.6,
        "SD1": 10.9,
        "SD2": 12.4,
        "SD3": 14.1,
        "SD1neg": 8.5,
        "SD2neg": 7.6,
        "SD3neg": 6.7
    },
    {
        "Month": 16,
        "SD0": 9.8,
        "SD1": 11.1,
        "SD2": 12.6,
        "SD3": 14.5,
        "SD1neg": 8.7,
        "SD2neg": 7.7,
        "SD3neg": 6.9
    },
    {
        "Month": 17,
        "SD0": 10.0,
        "SD1": 11.4,
        "SD2": 12.9,
        "SD3": 14.8,
        "SD1neg": 8.9,
        "SD2neg": 7.9,
        "SD3neg": 7.0
    },
    {
        "Month": 18,
        "SD0": 10.2,
        "SD1": 11.6,
        "SD2": 13.2,
        "SD3": 15.1,
        "SD1neg": 9.1,
        "SD2neg": 8.1,
        "SD3neg": 7.2
    },
    {
        "Month": 19,
        "SD0": 10.4,
        "SD1": 11.8,
        "SD2": 13.5,
        "SD3": 15.4,
        "SD1neg": 9.2,
        "SD2neg": 8.2,
        "SD3neg": 7.3
    },
    {
        "Month": 20,
        "SD0": 10.6,
        "SD1": 12.1,
        "SD2": 13.7,
        "SD3": 15.7,
        "SD1neg": 9.4,
        "SD2neg": 8.4,
        "SD3neg": 7.5
    },
    {
        "Month": 21,
        "SD0": 10.9,
        "SD1": 12.3,
        "SD2": 14.0,
        "SD3": 16.0,
        "SD1neg": 9.6,
        "SD2neg": 8.6,
        "SD3neg": 7.6
    },
    {
        "Month": 22,
        "SD0": 11.1,
        "SD1": 12.5,
        "SD2": 14.3,
        "SD3": 16.4,
        "SD1neg": 9.8,
        "SD2neg": 8.7,
        "SD3neg": 7.8
    },
    {
        "Month": 23,
        "SD0": 11.3,
        "SD1": 12.8,
        "SD2": 14.6,
        "SD3": 16.7,
        "SD1neg": 10.0,
        "SD2neg": 8.9,
        "SD3neg": 7.9
    },
    {
        "Month": 24,
        "SD0": 11.5,
        "SD1": 13.0,
        "SD2": 14.8,
        "SD3": 17.0,
        "SD1neg": 10.2,
        "SD2neg": 9.0,
        "SD3neg": 8.1
    },
    {
        "Month": 25,
        "SD0": 11.7,
        "SD1": 13.3,
        "SD2": 15.1,
        "SD3": 17.3,
        "SD1neg": 10.3,
        "SD2neg": 9.2,
        "SD3neg": 8.2
    },
    {
        "Month": 26,
        "SD0": 11.9,
        "SD1": 13.5,
        "SD2": 15.4,
        "SD3": 17.7,
        "SD1neg": 10.5,
        "SD2neg": 9.4,
        "SD3neg": 8.4
    },
    {
        "Month": 27,
        "SD0": 12.1,
        "SD1": 13.7,
        "SD2": 15.7,
        "SD3": 18.0,
        "SD1neg": 10.7,
        "SD2neg": 9.5,
        "SD3neg": 8.5
    },
    {
        "Month": 28,
        "SD0": 12.3,
        "SD1": 14.0,
        "SD2": 16.0,
        "SD3": 18.3,
        "SD1neg": 10.9,
        "SD2neg": 9.7,
        "SD3neg": 8.6
    },
    {
        "Month": 29,
        "SD0": 12.5,
        "SD1": 14.2,
        "SD2": 16.2,
        "SD3": 18.7,
        "SD1neg": 11.1,
        "SD2neg": 9.8,
        "SD3neg": 8.8
    },
    {
        "Month": 30,
        "SD0": 12.7,
        "SD1": 14.4,
        "SD2": 16.5,
        "SD3": 19.0,
        "SD1neg": 11.2,
        "SD2neg": 10.0,
        "SD3neg": 8.9
    },
    {
        "Month": 31,
        "SD0": 12.9,
        "SD1": 14.7,
        "SD2": 16.8,
        "SD3": 19.3,
        "SD1neg": 11.4,
        "SD2neg": 10.1,
        "SD3neg": 9.0
    },
    {
        "Month": 32,
        "SD0": 13.1,
        "SD1": 14.9,
        "SD2": 17.1,
        "SD3": 19.6,
        "SD1neg": 11.6,
        "SD2neg": 10.3,
        "SD3neg": 9.1
    },
    {
        "Month": 33,
        "SD0": 13.3,
        "SD1": 15.1,
        "SD2": 17.3,
        "SD3": 20.0,
        "SD1neg": 11.7,
        "SD2neg": 10.4,
        "SD3neg": 9.3
    },
    {
        "Month": 34,
        "SD0": 13.5,
        "SD1": 15.4,
        "SD2": 17.6,
        "SD3": 20.3,
        "SD1neg": 11.9,
        "SD2neg": 10.5,
        "SD3neg": 9.4
    },
    {
        "Month": 35,
        "SD0": 13.7,
        "SD1": 15.6,
        "SD2": 17.9,
        "SD3": 20.6,
        "SD1neg": 12.0,
        "SD2neg": 10.7,
        "SD3neg": 9.5
    },
    {
        "Month": 36,
        "SD0": 13.9,
        "SD1": 15.8,
        "SD2": 18.1,
        "SD3": 20.9,
        "SD1neg": 12.2,
        "SD2neg": 10.8,
        "SD3neg": 9.6
    },
    {
        "Month": 37,
        "SD0": 14.0,
        "SD1": 16.0,
        "SD2": 18.4,
        "SD3": 21.3,
        "SD1neg": 12.4,
        "SD2neg": 10.9,
        "SD3neg": 9.7
    },
    {
        "Month": 38,
        "SD0": 14.2,
        "SD1": 16.3,
        "SD2": 18.7,
        "SD3": 21.6,
        "SD1neg": 12.5,
        "SD2neg": 11.1,
        "SD3neg": 9.8
    },
    {
        "Month": 39,
        "SD0": 14.4,
        "SD1": 16.5,
        "SD2": 19.0,
        "SD3": 22.0,
        "SD1neg": 12.7,
        "SD2neg": 11.2,
        "SD3neg": 9.9
    },
    {
        "Month": 40,
        "SD0": 14.6,
        "SD1": 16.7,
        "SD2": 19.2,
        "SD3": 22.3,
        "SD1neg": 12.8,
        "SD2neg": 11.3,
        "SD3neg": 10.1
    },
    {
        "Month": 41,
        "SD0": 14.8,
        "SD1": 16.9,
        "SD2": 19.5,
        "SD3": 22.7,
        "SD1neg": 13.0,
        "SD2neg": 11.5,
        "SD3neg": 10.2
    },
    {
        "Month": 42,
        "SD0": 15.0,
        "SD1": 17.2,
        "SD2": 19.8,
        "SD3": 23.0,
        "SD1neg": 13.1,
        "SD2neg": 11.6,
        "SD3neg": 10.3
    },
    {
        "Month": 43,
        "SD0": 15.2,
        "SD1": 17.4,
        "SD2": 20.1,
        "SD3": 23.4,
        "SD1neg": 13.3,
        "SD2neg": 11.7,
        "SD3neg": 10.4
    },
    {
        "Month": 44,
        "SD0": 15.3,
        "SD1": 17.6,
        "SD2": 20.4,
        "SD3": 23.7,
        "SD1neg": 13.4,
        "SD2neg": 11.8,
        "SD3neg": 10.5
    },
    {
        "Month": 45,
        "SD0": 15.5,
        "SD1": 17.8,
        "SD2": 20.7,
        "SD3": 24.1,
        "SD1neg": 13.6,
        "SD2neg": 12.0,
        "SD3neg": 10.6
    },
    {
        "Month": 46,
        "SD0": 15.7,
        "SD1": 18.1,
        "SD2": 20.9,
        "SD3": 24.5,
        "SD1neg": 13.7,
        "SD2neg": 12.1,
        "SD3neg": 10.7
    },
    {
        "Month": 47,
        "SD0": 15.9,
        "SD1": 18.3,
        "SD2": 21.2,
        "SD3": 24.8,
        "SD1neg": 13.9,
        "SD2neg": 12.2,
        "SD3neg": 10.8
    },
    {
        "Month": 48,
        "SD0": 16.1,
        "SD1": 18.5,
        "SD2": 21.5,
        "SD3": 25.2,
        "SD1neg": 14.0,
        "SD2neg": 12.3,
        "SD3neg": 10.9
    },
    {
        "Month": 49,
        "SD0": 16.3,
        "SD1": 18.8,
        "SD2": 21.8,
        "SD3": 25.5,
        "SD1neg": 14.2,
        "SD2neg": 12.4,
        "SD3neg": 11.0
    },
    {
        "Month": 50,
        "SD0": 16.4,
        "SD1": 19.0,
        "SD2": 22.1,
        "SD3": 25.9,
        "SD1neg": 14.3,
        "SD2neg": 12.6,
        "SD3neg": 11.1
    },
    {
        "Month": 51,
        "SD0": 16.6,
        "SD1": 19.2,
        "SD2": 22.4,
        "SD3": 26.3,
        "SD1neg": 14.5,
        "SD2neg": 12.7,
        "SD3neg": 11.2
    },
    {
        "Month": 52,
        "SD0": 16.8,
        "SD1": 19.4,
        "SD2": 22.6,
        "SD3": 26.6,
        "SD1neg": 14.6,
        "SD2neg": 12.8,
        "SD3neg": 11.3
    },
    {
        "Month": 53,
        "SD0": 17.0,
        "SD1": 19.7,
        "SD2": 22.9,
        "SD3": 27.0,
        "SD1neg": 14.8,
        "SD2neg": 12.9,
        "SD3neg": 11.4
    },
    {
        "Month": 54,
        "SD0": 17.2,
        "SD1": 19.9,
        "SD2": 23.2,
        "SD3": 27.4,
        "SD1neg": 14.9,
        "SD2neg": 13.0,
        "SD3neg": 11.5
    },
    {
        "Month": 55,
        "SD0": 17.3,
        "SD1": 20.1,
        "SD2": 23.5,
        "SD3": 27.7,
        "SD1neg": 15.1,
        "SD2neg": 13.2,
        "SD3neg": 11.6
    },
    {
        "Month": 56,
        "SD0": 17.5,
        "SD1": 20.3,
        "SD2": 23.8,
        "SD3": 28.1,
        "SD1neg": 15.2,
        "SD2neg": 13.3,
        "SD3neg": 11.7
    },
    {
        "Month": 57,
        "SD0": 17.7,
        "SD1": 20.6,
        "SD2": 24.1,
        "SD3": 28.5,
        "SD1neg": 15.3,
        "SD2neg": 13.4,
        "SD3neg": 11.8
    },
    {
        "Month": 58,
        "SD0": 17.9,
        "SD1": 20.8,
        "SD2": 24.4,
        "SD3": 28.8,
        "SD1neg": 15.5,
        "SD2neg": 13.5,
        "SD3neg": 11.9
    },
    {
        "Month": 59,
        "SD0": 18.0,
        "SD1": 21.0,
        "SD2": 24.6,
        "SD3": 29.2,
        "SD1neg": 15.6,
        "SD2neg": 13.6,
        "SD3neg": 12.0
    },
    {
        "Month": 60,
        "SD0": 18.2,
        "SD1": 21.2,
        "SD2": 24.9,
        "SD3": 29.5,
        "SD1neg": 15.8,
        "SD2neg": 13.7,
        "SD3neg": 12.1
    }
]