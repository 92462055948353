// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-container {
    height: 100%;
}

.dashboard-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.detail-row {
    display: flex;
    justify-content: space-between;
}

.detail {
    display: flex;
    width: -webkit-fill-available;
    align-items: center;
}

.detail h4 {
    padding-right: 10px;
}

.content-section {
    flex-grow: 100;
    overflow: auto;
}

.highlights-box {
    border: 1px solid #568eb6;
}`, "",{"version":3,"sources":["webpack://./src/routes/vaccination/styles.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,cAAc;AAClB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".dashboard-container {\n    height: 100%;\n}\n\n.dashboard-wrapper {\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n}\n\n.detail-row {\n    display: flex;\n    justify-content: space-between;\n}\n\n.detail {\n    display: flex;\n    width: -webkit-fill-available;\n    align-items: center;\n}\n\n.detail h4 {\n    padding-right: 10px;\n}\n\n.content-section {\n    flex-grow: 100;\n    overflow: auto;\n}\n\n.highlights-box {\n    border: 1px solid #568eb6;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
